import React, { Fragment } from 'react'
import { StyledField } from './styles'
import { StyledCouponHeading } from 'src/ds4/components/price-kind/styles'

const Field = ({ title, value, noMargin = false }) => {
  const renderList = () => {
    return (
      <Fragment>
        {value.map((list) => (
          <div class='view-value padding8'>
            <StyledCouponHeading>{list}</StyledCouponHeading>
          </div>
        ))}
      </Fragment>
    )
  }
  return (
    <StyledField noMargin={noMargin}>
      <div class='view-title'>
        <StyledCouponHeading>{title}</StyledCouponHeading>
      </div>
      {Array.isArray(value) && value.length > 0 ? (
        renderList()
      ) : (
        <div class='view-value'>
          <StyledCouponHeading>{value || 'N/A'}</StyledCouponHeading>
        </div>
      )}
    </StyledField>
  )
}

export default Field
