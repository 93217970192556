import uniq from 'lodash/uniq'
import {
  PromotionRadioTypeNames,
  SkusPromotionType,
  PROMO_TYPE,
  PROMO_TYPE_PRIORITY_MAPPING,
} from './constants'
import { BuyGetSpendNames } from 'src/modules/promo/constants'
import { getBuyGetTargetTypes } from './utils'
import { checkIsBuyGetSelected } from './lib/helper'
import { isFeatureActive } from 'src/ds4/api/featureFlag'
import { FEATURE_FLAGS } from 'src/ds4/config/constants'
import { isShippingDiscount } from './form/promotion-type/multi-discount/constants'

export const getState = (state) => state.promoReducer
export const getCompleteState = (state) => getState(state)

export const getTargetType = (state) => getState(state).targetType
export const getUserKind = (state) => getState(state).userKind
export const getAllState = (state) => getState(state)
export const getAllProductsCount = (state, targetType, operator = '') => {
  return getState(state)[`${targetType}${operator}FinalProductsCount`]
}
export const getCategoriesPath = (state) => getState(state).categoriesPath
export const getCollectionsPath = (state) => getState(state).collectionsPath
export const getCheckedCategories = (state, type, isAh, operator) => {
  let categoryList = isAh
    ? getTempSelectedCollections(state, type, operator)
    : getTempSelectedCategories(state, type, operator)
  if (
    !categoryList ||
    (categoryList && Object.keys(categoryList).length === 0)
  ) {
    return []
  }
  return uniq(getOptedCategories(categoryList, []))
}
export const getFinalSelectedSkusTree = (state, targetType) => {
  return getState(state)[`${targetType}FinalSelectedSkusTree`]
}
export const getProducts = (state, targetType, operator = '') => {
  return getState(state)[`${targetType}${operator}Products`]
}
export const isBuyGetSelected = (state) =>
  checkIsBuyGetSelected(getState(state))
export const getIsFetchingItems = (state) => getState(state).isLoading
export const getPromotionLimit = (state) => getState(state).promotionLimit
export const getPromo = (state) => getState(state).promo
export const getExplicitOption = (state) => getState(state).explicitOption
export const getPriceListOptions = (state) =>
  getState(state).selectedPriceListOptions
export const getSelectedStackingOption = (state) =>
  getState(state).selectedStackingOption
export const getEligiblePriceList = (state) =>
  getState(state).selectedEligiblePriceList
export const getUserSegment = (state) => getState(state).selectedUserSegment
export const getIncludeCustomerSegmentOperator = (state) =>
  getState(state).includeCustomerSegmentsOperator

export const getExcludeCustomerSegmentOperator = (state) =>
  getState(state).excludeCustomerSegmentsOperator
export const getPromoConfig = (state) => getState(state).promoConfig
export const getPromoType = (state, targetType) =>
  getState(state)[`${targetType}PromoType`]
export const getSelectedTargetTypes = (state, targetType, operator) =>
  getState(state)[`${targetType}${operator}SelectedRules`]
export const getPromotion = (state) => getState(state).promotion
export const getSelectedStartDate = (state) => getState(state).startDate
export const getPromoIsExclusive = (state) => getState(state).isExclusive
export const getRules = (state) => getState(state).rules
export const getCustomerSegment = (state) => getState(state).customerSegment
export const getExcludeCustomerSegment = (state) =>
  getState(state).excludeCustomerSegment
export const getPromotionMessages = (state) => getState(state).promotionMessages
export const getPromotionMessagesFormValid = (state) =>
  getState(state).promotionMessagesFormValid
export const getPromotionTriggerValidation = (state) =>
  getState(state).triggerValidation
export const getProximityMessages = (state) => getState(state).proximityMessages
export const getAdditionalAttributes = (state) =>
  getState(state).additionalAttributes
export const getRestrictions = (state) => getState(state).restrictions
export const getSelectedSkus = (state, targetType, operator = '') => {
  return getState(state)[`${targetType}${operator}SelectedSkus`]
}

export const getTempSelectedSkusCount = (state, targetType, operator = '') => {
  return getState(state)[`${targetType}${operator}TempSelectedSkusCount`]
}
export const getIncludeCondition = (state, targetType) => {
  return getState(state)[`${targetType}IncludeCondition`]
}
export const getExcludeCondition = (state, targetType) => {
  return getState(state)[`${targetType}ExcludeCondition`]
}
export const getFinalSelectedSkusCount = (state, targetType, operator = '') => {
  return getState(state)[`${targetType}${operator}FinalSelectedSkusCount`]
}
export const getSelectedCategories = (state, targetType, operator = '') => {
  return getState(state)[`${targetType}${operator}FinalSelectedCategories`]
}
export const getSelectedCollections = (state, targetType) => {
  return getState(state)[`${targetType}FinalSelectedCollections`]
}
export const getTempSelectedCategories = (state, targetType, operator = '') => {
  return getState(state)[`${targetType}${operator}TempSelectedCategories`] || []
}
export const getTempSelectedCollections = (
  state,
  targetType,
  operator = ''
) => {
  return (
    getState(state)[`${targetType}${operator}TempSelectedCollections`] || []
  )
}
export const getSelectedSkusCount = (state, targetType) => {
  return getState(state)[`${targetType}SelectedSkus`].length
}
export const getStatus = (state) => getState(state).statusState

export const getTempSelectedSkusTree = (state, targetType, operator = '') => {
  return getState(state)[`${targetType}${operator}TempSelectedSkusTree`]
}
export const getTempSelectedAttributesTree = (
  state,
  targetType,
  operator = ''
) => {
  return getState(state)[`${targetType}${operator}TempSelectedAttributesTree`]
}
export const getFinalSelectedAttributesTree = (
  state,
  targetType,
  operator = ''
) => {
  return getState(state)[`${targetType}${operator}FinalSelectedAttributesTree`]
}
export const getSelectAll = (state) => getState(state).allSkusSelected
export const getPromotionRadioOptions = (state) =>
  getState(state).promotionRadioOptions
export const getSelectedPromoTypeRadio = (state) =>
  getState(state).selectedPromoTypeRadio
export const getPromoTiers = (state) => getState(state).promoTiers
export const getAttributeItemsDetails = (state, targetType, operator = '') => {
  return getState(state)[`${targetType}${operator}AttributeItemsDetails`]
}
export const getFinalAttributeItemsDetails = (
  state,
  targetType,
  operator = ''
) => {
  return (
    getState(state)[`${targetType}${operator}FinalAttributeItemsDetails`] || {}
  )
}
export const getSelectedAttributes = (state, targetType, operator = '') => {
  return getState(state)[`${targetType}${operator}SelectedAttributes`]
}
export const getSelectedPriceRadio = (state) =>
  getState(state).selectedPriceRadio
export const getCategoriesSkusCount = (state, targetType, operator = '') => {
  return getState(state)[`${targetType}${operator}TempCategoriesSkusCount`]
}
export const getCollectionsSkusCount = (state, targetType, operator = '') => {
  return getState(state)[`${targetType}${operator}TempCollectionsSkusCount`]
}
export const getFinalCategoriesSkusCount = (
  state,
  targetType,
  operator = ''
) => {
  return getState(state)[`${targetType}${operator}FinalCategoriesSkusCount`]
}
export const getFinalAttributesSkusCount = (
  state,
  targetType,
  operator = ''
) => {
  return getState(state)[`${targetType}${operator}FinalAttributesSkusCount`]
}
export const getFinalCollectionsSkusCount = (
  state,
  targetType,
  operator = ''
) => {
  return getState(state)[`${targetType}${operator}FinalCollectionsSkusCount`]
}
export const getPromoUsage = (state) => getState(state).promoUsage
export const getBuyXSameSku = (state) => getState(state).buyXSameSku
export const getYSameSkuSelector = (state) => getState(state).getYSameSku

const getOptedCategories = (category, selectedCats) => {
  category.constructor === Object &&
    Object.keys(category).forEach((key) => {
      if (key === 'isChecked' && category[key]) {
        selectedCats.push(category._id)
      }
      if (category[key] && category[key].constructor === Object) {
        getOptedCategories(category[key], selectedCats)
      }
    })
  return selectedCats
}

export const getSelectedAttributesAndValues = (
  state,
  targetType,
  operator = ''
) => {
  let attributes = []
  let selectedAttributes =
    getSelectedAttributes(state, targetType, operator) || []
  attributes = selectedAttributes.map((attribute) => {
    return {
      name: attribute.parent,
      value: attribute.value,
    }
  })
  return attributes
}

export const getPromotionActive = (state) => {
  let promoState = getState(state)

  if (
    promoState?.promotion?.buyOperator === '' &&
    promoState?.selectedPromoTypeRadio === PromotionRadioTypeNames.BUY_GET
  ) {
    return false
  } else if (
    promoState?.selectedPromoTypeRadio === PromotionRadioTypeNames.QUANTITY
  ) {
    return false
  } else if (
    promoState?.promoTiers
      .map((item) => item?.typeWithValues)
      ?.filter(
        (item) => item?.selectedPromotionType === SkusPromotionType.shipping
      ).length > 0
  ) {
    return false
  } else {
    return true
  }
}

export const mapStateToPropsAttributes = (state) => {
  let typeState = {}
  let types = ['targetX', 'targetY', 'exclusion']
  const operators = ['Include', 'Exclude', '']
  if (
    state.promoReducer.selectedPromoTypeRadio ===
    PromotionRadioTypeNames.BUY_GET
  ) {
    types = types.concat(
      getBuyGetTargetTypes({
        buyGetType: BuyGetSpendNames.BUY,
        props: state.promoReducer,
      })
    )
    types = types.concat(
      getBuyGetTargetTypes({
        buyGetType: BuyGetSpendNames.GET,
        props: state.promoReducer,
      })
    )
    types = types.concat(
      getBuyGetTargetTypes({
        buyGetType: BuyGetSpendNames.SPEND,
        props: state.promoReducer,
      })
    )
  }
  types.forEach((type) => {
    operators.forEach((operator) => {
      typeState = {
        ...typeState,
        [`${type}${operator}SelectedAttributes`]: getTempSelectedAttributesTree(
          state,
          type,
          operator
        ),
        [`${type}AttributeItemsDetails`]: getAttributeItemsDetails(state, type),
      }
    })
  })
  return {
    ...typeState,
    allData: getAllState(state),
  }
}

const isSKUDiscount = (discounts) => discounts?.find((discount) => discount?.discountOn === 'SKU');

export const getPromoTypeForPriority = (state) => {
  const promoState = getState(state)
  const { promoTiers, selectedPromoTypeRadio } = promoState
  const isSkuOrQuantityRadio =
    selectedPromoTypeRadio === PromotionRadioTypeNames.SKU ||
    selectedPromoTypeRadio === PromotionRadioTypeNames.QUANTITY

  if (isFeatureActive({ flagName: FEATURE_FLAGS.UI_MULTI_DISCOUNT_SKU })) {
    const isSKUDiscountAvailable = promoTiers?.find((tier) => isSKUDiscount(tier.tierDiscounts));
    const isShippingDiscountAvailable = promoTiers[0]?.tierDiscounts?.find((discount) => isShippingDiscount(discount?.discountOn))
    if(isSKUDiscountAvailable && isSkuOrQuantityRadio) {
      return PROMO_TYPE[selectedPromoTypeRadio];
    } else if(isShippingDiscountAvailable && isSkuOrQuantityRadio) {
      return 'SHIPPING'
    }
    return PROMO_TYPE[selectedPromoTypeRadio]
  }
  if (
    promoTiers[0].typeWithValues.selectedPromotionType ===
      SkusPromotionType.shipping &&
    isSkuOrQuantityRadio
  ) {
    return 'SHIPPING'
  }
  return PROMO_TYPE[selectedPromoTypeRadio]
}

export const getTypeExclusivePromoPriority = (state) => {
  const promoState = getState(state)
  const { promotion } = promoState
  let promoType = getPromoTypeForPriority(state)
  if (
    isFeatureActive({ flagName: FEATURE_FLAGS.UI_BUY_GET_V2 }) &&
    promoState?.selectedPromoTypeRadio?.toLowerCase() === 'buy-get'
  ) {
    return getTypeExclusivePriorityForBuyGetV2(promotion)
  }

  if (
    promoType === 'BUYGET' &&
    promotion.buyOperator === '' &&
    promotion.getType === 'SKU'
  ) {
    return 2
  }
  if (promoType === 'BUYGET' && promotion.getType === 'CART') {
    return 3
  }
  if (promoType === 'BUYGET' && promotion.getType === 'SHIPPING') {
    return 5
  }
  return PROMO_TYPE_PRIORITY_MAPPING[promoType]
}

const getTypeExclusivePriorityForBuyGetV2 = (promotion) => {
  if (promotion?.buyOperator) {
    return getTypeExclusivePriorityForBuySkuBundle(promotion)
  } else {
    return getTypeExclusivePriorityForBuyCart(promotion)
  }
}

const getTypeExclusivePriorityForBuyCart = (promotion) => {
  if (promotion?.getWithMultiDiscount?.length === 1) {
    if (
      promotion.getWithMultiDiscount[0].getDiscountOn?.id?.toLowerCase() ===
      'sku'
    ) {
      return 2
    }
    if (
      promotion.getWithMultiDiscount[0].getDiscountOn?.id?.toLowerCase() ===
      'cart'
    ) {
      return 3
    }

    //else it is shipping
    return 5
  }

  if (promotion?.getWithMultiDiscount?.length === 2) {
    if (
      promotion?.getWithMultiDiscount?.some(
        (discount) => discount?.getDiscountOn?.id.toLowerCase() === 'sku'
      )
    )
      return 2

    //else it is cart+shipping combo
    return 3
  }

  //else there are 3 discounts => sku + cart + shipping
  return 2
}

const getTypeExclusivePriorityForBuySkuBundle = (promotion) => {
  if (promotion?.getWithMultiDiscount?.length === 1) {
    if (
      promotion.getWithMultiDiscount[0].getDiscountOn?.id?.toLowerCase() ===
      'sku'
    ) {
      return 1
    }
    if (
      promotion.getWithMultiDiscount[0].getDiscountOn?.id?.toLowerCase() ===
      'cart'
    ) {
      return 3
    }

    //else it is shipping
    return 5
  }

  if (promotion?.getWithMultiDiscount?.length === 2) {
    if (
      promotion?.getWithMultiDiscount?.some(
        (discount) => discount?.getDiscountOn?.id.toLowerCase() === 'sku'
      )
    )
      return 1

    //else it is cart+shipping combo
    return 3
  }

  //else there are 3 discounts => sku + cart + shipping
  return 1
}
