import styled from 'styled-components'

export const StyledCriteria = styled.div`
  background: #f1f2f4;
  padding: 40px 20px 40px 40px;
  .dropdown {
    margin: 16px 8px;
  }
`

export const StyledSkuSelection = styled.div`
  min-height: 580px;
  border-radius: 4px;
  overflow: hidden;
  .header {
    ${props => props.theme.typography.h4};
  }
  .horizontal.secondary {
    margin: 40px 0;
    padding: 0;
    ul {
      li {
        cursor: pointer;
      }
    }
  }
  .selection_content {
    display: flex;
  }
`

export const StyledActionBox = styled.div`
  background: ${props => props.theme.palette.common.white};
  .space_between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 8px;
    .right_actions {
      text-transform: capitalize;
      display: flex;
      justify-content: space-between;
      button:first-child {
        margin-right: 16px;
        color: ${props => props.theme.palette.text.charcoal};
      }
      button:last-child {
        text-transform: capitalize;
      }
    }
  }
  .items_selected_heading {
    color: ${props => props.theme.palette.text.charcoal};
    ${props => props.theme.typography.h4};
  }
  .items_selected {
    color: ${props => props.theme.palette.text.main};
    ${props => props.theme.typography.small};
  }
`
