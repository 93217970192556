export default ({ fill = '#8994A3' }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='-7 0 24 8'
  >
    <g fill='none' fillRule='evenodd'>
      <path fillRule='nonzero' d='M-7-9h24v24H-7z' />
      <path
        fill={fill}
        d='M12.71 9.294l3.996 3.995A1.002 1.002 0 0 1 15.997 15H8.003a1.003 1.003 0 0 1-.71-1.711l3.998-3.995a1.003 1.003 0 0 1 1.418 0z'
        transform='translate(-7 -9)'
      />
    </g>
  </svg>
)
