import { datadogRum } from '@datadog/browser-rum'

function getUser() {
  return {
    email: sessionStorage.getItem('email'),
    accountId: sessionStorage.getItem('accountId'),
    accountName: sessionStorage.getItem('accountName'),
    userId: sessionStorage.getItem('user'),
    name: `${sessionStorage.getItem('firstName')} ${sessionStorage.getItem(
      'lastName'
    )}`,
  }
}

function initDatadog() {
  const user = getUser()
  try {
    datadogRum.init({
      applicationId: process.env.DATADOG_APP_ID,
      clientToken: process.env.DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'copilot-web-offers',
      env: process.env.SLS_STAGE,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: '1.26.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
    })
    datadogRum.setUser({ ...user })
    datadogRum.startSessionReplayRecording()
  } catch (e) {
    console.log('Unable to initialize datadog for offers')
  }
}

export default initDatadog
