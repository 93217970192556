import API, { API_URL } from '../index'

export const fetchPromoList = (data, isCoupon) => {
  if (isCoupon) {
    return API.offers.post(API_URL.COUPON_LIST(), data)
  } else {
    return API.offers.post(API_URL.PROMO_LIST(), data)
  }
}

export const getPromoById = ({ id, isCoupon }) => {
  if (isCoupon) {
    return API.offers.get(API_URL.COUPON_BY_ID(id))
  } else {
    return API.offers.get(API_URL.PROMO_BY_ID(id))
  }
}

export const createPromo = (promo, isCoupon) => {
  if (isCoupon) {
    return API.offers.post(API_URL.COUPON_CREATE(), promo)
  } else {
    return API.offers.post(API_URL.PROMO_CREATE(), promo)
  }
}

export const couponCodeUpload = (data) => {
  return API.offers.post(API_URL.GET_UPLOAD_URL(), data)
}

export const getCouponImportList = ({ limit, offset }) => {
  return API.offers.get(API_URL.COUPON_IMPORT_HISTORY(limit, offset))
}

export const updatePromo = ({ id }, promo, isCoupon) => {
  if (isCoupon) {
    return API.offers.put(API_URL.COUPON_UPDATE(id), promo)
  } else {
    return API.offers.put(API_URL.PROMO_UPDATE(id), promo)
  }
}

export const enableDisablePromo = ({ id }, enable, isCoupon) => {
  if (isCoupon) {
    return API.offers.put(API_URL.COUPON_ENABLE_DISABLE(id), enable)
  } else {
    return API.offers.put(API_URL.PROMO_ENABLE_DISABLE(id), enable)
  }
}

export const deletePromo = ({ id }) => {
  return API.offers.delete(API_URL.PROMO_DELETE(id))
}

export const killPromo = ({ id }) => {
  return API.offers.post(API_URL.PROMO_KILL(id))
}

export const generatePromoCodes = (data) => {
  return API.offers.post(API_URL.COUPON_GENERATE_CODES(), data)
}

export const getSkuList = (limit, offset) => {
  return API.offers.get(API_URL.GET_SKU_LIST(limit, offset))
}

export const createSkuList = (data) => {
  return API.offers.post(API_URL.CREATE_SKU_LIST(), data)
}

export const skuListGetById = ({ id }) => {
  return API.offers.get(API_URL.SKU_LIST_GET_BY_ID(id))
}

export const updateSkuList = ({ id }, data) => {
  return API.offers.put(API_URL.UPDATE_SKU_LIST(id), data)
}

export const deleteSkuLists = ({ id }) => {
  return API.offers.delete(API_URL.DELETE_SKU_LIST(id))
}

export const getAttributesFromPimConnector = ({ limit, offset }) =>
  API.offersConnector.post(API_URL.ATTRIBUTE_CONNECTOR, {
    limit: limit,
    offset: offset,
  })

export const searchedAttributesFromPimConnector = ({ body }) => {
  return API.offersConnector.post(API_URL.PRODUCT_SEARCH, body)
}
