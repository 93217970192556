"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasPermission = void 0;
var constants_1 = require("src/ds4/config/constants");
var copilot_utilities_1 = require("@teamfabric/copilot-utilities");
var featureFlag_1 = require("src/ds4/api/featureFlag");
var hasPermission = function (permission) {
    var rbacFlag = (0, featureFlag_1.isFeatureActive)({ flagName: constants_1.FEATURE_FLAGS === null || constants_1.FEATURE_FLAGS === void 0 ? void 0 : constants_1.FEATURE_FLAGS.RBAC_V2 });
    var isIdv2Login = localStorage.getItem('isLoggedInWithIdV2');
    if (isIdv2Login === 'true' && rbacFlag) {
        var hasAccess = copilot_utilities_1.rbac.checkPermission({
            allowedPermissions: [permission],
        }).hasAccess;
        return hasAccess;
    }
    else {
        return true;
    }
};
exports.hasPermission = hasPermission;
