import React from 'react'
import { StyledPromoUsage } from '../styles'
import Field from 'src/components/view/field'

const PromoLimit = ({ defaultPromotionLimit }) => {
  return (
    <div className='content_wrapper_25'>
      <StyledPromoUsage>
        <div className='title_heading'>Promotion limits</div>
        <div className='margin_24'>
          <Field
            title={`Maximum number of uses per order`}
            value={defaultPromotionLimit?.value}
          />
        </div>
      </StyledPromoUsage>
    </div>
  )
}

export default PromoLimit
