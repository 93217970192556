import styled from 'styled-components'

export const StyledOffersCalender = styled.div`
  input {
    background: transparent;
  }

  .input__label {
    top: 2px !important;
    color: #64707d !important;
  }
  .react-datepicker-popper {
    z-index: 100;
  }
  width: 75px;
`
