import { Calendar, Input } from '@teamfabric/copilot-ui'
import { StyledOffersCalender } from './styles'
import { format } from 'date-fns'
import { capitalizeFirstLetter } from 'lib/utils/helper'

const CalendarComp = ({
  startDate,
  setFieldValues,
  disabled,
  error,
  label,
  minDate,
}) => {
  return (
    <Calendar
      initialValue={startDate}
      minDate={minDate ? minDate : new Date()}
      disabled={disabled}
      onDateChange={setFieldValues}
      popperPlacement='bottom-start'
      dateFormat='MM/dd/yyyy'
      yearsArray={[2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027]}
      customInput={({ value }) => {
        return (
          <Input
            isFloatedLabel
            disabled={disabled}
            className='custom-input'
            label={`${capitalizeFirstLetter(label)} date`}
            inputProps={{
              disabled,
              value: value ? format(value, 'MM/dd/yyyy') : '',
              ['data-testid']: `${label}-custom-input`,
            }}
            error={error}
          />
        )
      }}
    />
  )
}

const OffersCalender = ({
  startDate,
  setFieldValues,
  label,
  disabled,
  error = false,
  fromPromo = false,
  minDate,
}) => {
  return (
    <>
      {fromPromo ? (
        <div data-testid={label}>
          <CalendarComp
            startDate={startDate}
            setFieldValues={setFieldValues}
            disabled={disabled}
            error={error}
            label={label}
            minDate={minDate}
          />
        </div>
      ) : (
        <StyledOffersCalender data-testid={label}>
          <CalendarComp
            startDate={startDate}
            setFieldValues={setFieldValues}
            disabled={disabled}
            error={error}
            label={label}
            minDate={minDate}
          />
        </StyledOffersCalender>
      )}
    </>
  )
}

export default OffersCalender
