import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ProductsModal from './products-modal'
import UploadCsvFile from './upload-csv-file'
import { Button, Modal, ErrorMessage, Empty } from '@teamfabric/copilot-ui'
import {
  BULK_SKU_CHECK,
  ON_EXCLUDE_INCLUDE_UPDATE_COUNT,
  RESET_PRODUCTS,
  SET_TARGET_TYPE,
  UPDATE_COUNT,
  UPDATE_ADVANCED_SKUS,
} from 'modules/promo/actions'
import {
  getSelectedSkus,
  getCheckedCategories,
  getProducts,
  getAllProductsCount,
  getTargetType,
} from 'modules/promo/selectors'
import ProductChips from './product-chips'
import { StyledExclusionProducts } from './styles'

export class ExclusionProductSelection extends Component {
  state = {
    showProductsModal: false,
    showUploadCsvModal: false,
    type: this.props.type,
    errors: this.props.errors,
    targetType: 'exclusion',
    isUploadCsvModalCancelable: true,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errors !== this.props.errors) {
      this.setState({ errors: this.props.errors })
    }
  }

  handleErrors() {
    this.setState({ errors: '' })
  }

  handleModal = (value, type) => {
    this.setState({
      showProductsModal: value,
      type: type,
      isShowMore: type ? true : false,
      errors: '',
    })
  }
  uploadCsvModal = (value) => {
    this.setState({
      showUploadCsvModal: value,
    })
  }
  hideUploadCsvModal = () => {
    this.setState({
      showUploadCsvModal: false,
    })
  }
  setUploadCsvModalCancelable = (value) => {
    this.setState({
      isUploadCsvModalCancelable: value,
    })
  }
  render() {
    const { showProductsModal, targetType } = this.state
    return (
      <StyledExclusionProducts>
        <div data-testid='exclusion_products' className='exclusion_products'>
          {this.renderContent()}
        </div>
        {showProductsModal && (
          <ProductsModal
            onDismiss={() => this.handleModal(false)}
            type={this.state.type}
            isShowMore={this.state.isShowMore}
            fromAdvancedOptions={false}
            targetType={targetType}
            viewMode={this.props.viewMode}
          />
        )}
        {this.state.showUploadCsvModal && (
          <Modal
            headerText=''
            showCloseButton={true}
            footerButtons={[]}
            headerButtons={[]}
            hideIcon={!this.state.isUploadCsvModalCancelable}
            onClose={this.hideUploadCsvModal}
            id='sku-bulk-upload'
            onBackdropClick={() => {
              if (this.state.isUploadCsvModalCancelable)
                this.hideUploadCsvModal()
            }}
            render={() => (
              <UploadCsvFile
                targetType={targetType}
                onAddProducts={this.props.onAddProducts}
                bulkSkuCheck={this.props.bulkSkuCheck}
                selectedSkus={this.props.targetXSelectedSkuList}
                onClose={this.hideUploadCsvModal}
                showHideCloseIcon={(value) =>
                  this.setUploadCsvModalCancelable(value)
                }
                headersData={[['SKU ID']]}
                errorData={[['SKU ID', 'ERROR']]}
              />
            )}
          />
        )}
      </StyledExclusionProducts>
    )
  }
  renderContent() {
    const { errors, targetType } = this.state
    const { viewMode } = this.props
    const totalProductsCount = this.props[`${targetType}TotalProductsCount`]
    const list = this.props[`${targetType}ProductsList`]
    return (
      <>
        <div className='section_heading'>Excluded SKUs</div>
        {!viewMode && (
          <div className='btn-wrapper'>
            <Button
              isPrimary={false}
              onClick={() => {
                this.handleErrors()
                this.uploadCsvModal(true)
              }}
              size='small'
              data-testid={'bulk-upload-skus'}
              text='Bulk Upload SKUs'
            />
            <Button
              className='browse_button'
              isPrimary={true}
              size='small'
              data-testid='browse-skus'
              onClick={() => {
                this.handleErrors()
                this.handleModal(true, 'All SKUs')
              }}
              text='Browse SKUs'
            />
          </div>
        )}
        {list && list.length > 0 && (
          <ProductChips
            targetType={targetType}
            showMore={(type) => this.handleModal(true, type)}
            list={list}
            viewMode={viewMode}
          />
        )}
        {errors[`${targetType}ProductSelection`] && (
          <ErrorMessage
            id='error-product-selection'
            data-testid='error-product-selection'
            text={errors[`${targetType}ProductSelection`]}
          />
        )}
        {list && list.length > 0 ? (
          <div
            data-testid='view-added-products-count'
            className='products_link'
            onClick={() => {
              const type = list[list.length - 1].key
                ? list[list.length - 1].key === 'SKUs'
                  ? 'All SKUs'
                  : list[list.length - 1].key
                : 'All SKUs'
              this.handleModal(true, type)
            }}
          >
            View added products({totalProductsCount})
          </div>
        ) : (
          <div data-testid='empty'>
            <Empty
              className='empty'
              primaryText='You have no SKUS EXCLUDED'
              secondaryText={`${
                !viewMode
                  ? 'Start adding SKUs you want to \n exclude globally.'
                  : ''
              }`}
            />
          </div>
        )}
      </>
    )
  }
}

export const mapStateToPropsExclusion = (state) => {
  let typeState = {}
  const types = ['exclusion']
  types.forEach((type) => {
    typeState = {
      ...typeState,
      [`${type}SelectedSkuList`]: getSelectedSkus(state, type),
      [`${type}FinalSelectedSkusTree`]: getSelectedSkus(state, type),
      [`${type}CheckedCategories`]: getCheckedCategories(state, type),
      [`${type}CheckedCollections`]: getCheckedCategories(state, type),
      [`${type}ProductsList`]: getProducts(state, type),
      [`${type}TotalProductsCount`]: getAllProductsCount(state, type),
    }
  })
  return {
    targetType: getTargetType(state),
    ...typeState,
  }
}

export default connect(mapStateToPropsExclusion, (dispatch) =>
  bindActionCreators(
    {
      bulkSkuCheck: BULK_SKU_CHECK,
      onAddProducts: ON_EXCLUDE_INCLUDE_UPDATE_COUNT,
      updateAdvancedValues: UPDATE_ADVANCED_SKUS,
      resetProducts: RESET_PRODUCTS,
      setTargetType: SET_TARGET_TYPE,
      updateCount: UPDATE_COUNT,
    },
    dispatch
  )
)(ExclusionProductSelection)
