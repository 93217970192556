import styled from 'styled-components'

export const StyledPromoTitle = styled.div`
  border: 1px solid rgb(115, 127, 143, 0.2);
  padding: 32px;
  border-radius: 4px;
  .section_heading {
    ${(props) => props.theme.typography.h4};
    color: ${(props) => props.theme.palette.text.charcoal};
    margin-bottom: 18px;
    ${(props) =>
      props.disabled && { color: props.theme.palette.text.disabled }};
  }
`
export const StyledGenerateCodes = styled.div`
  margin-top: 24px;
  .span_title {
    color: #58687a;
    width: 100%;
    font-size: 13px;
    font-weight: normal;
  }
  .promos_container {
    border: 1px solid ${(props) => props.theme.palette.text.separation};
    padding: 8px;
    margin-top: 12px;
    width: 365px;
    .row_space_between {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .input_container {
    width: 60px;
    margin-right: 8px;
  }
  .helper {
    margin-top: 4px;
    margin-left: 2px;
    ${(props) => props.theme.typography.small.css};
    color: ${(props) => props.theme.palette.text.label};
  }
`

export const StyledFromToDate = styled.div`
  .promo_active_dates {
    margin-top: 24px;
    display: flex;
    .dates_col {
      flex-direction: column;
      .date-time {
        flex-direction: row;
        align-items: baseline;
        display: flex;
        margin-bottom: 5px;
        .time {
          margin-left: 10px;
        }
      }
    }
    .dates_row {
      display: flex;
      width: 50%;
      justify-content: space-between;
    }
  }
`
