import styled from 'styled-components'
import Input from 'components/input'

export const StyledSearchInput = styled(Input)`
  display: flex;
  align-items: center;
  width: ${props => (props.width ? props.width : '100%')};
  height: 40px;
  div {
    border: none;
    background-color: ${props => props.theme.palette.common.white};
  }
  input {
    border: none;
    ${props => props.theme.typography.body};
    background-color: ${props => props.theme.palette.common.white};
    ::placeholder {
      color: ${props => props.theme.palette.text.enable};
    }
  }
`

export const StyledSearch = styled.div`
  display: flex;
  border: 1px solid ${props => props.theme.palette.text.separation};
  background-color: ${props => props.theme.palette.common.white};
  margin-right: 16px;
  .icon {
    margin-top: 4px;
    margin-left: 12px;
  }
  .close-icon {
    cursor: pointer;
    padding-right: 12px;
    height: 100%;
    display: flex;
    align-items: center;
  }
  ${props =>
    props.border && `border: solid 1px ${props.theme.palette.text.separation}`};

  box-shadow: rgba(115, 127, 143, 0.1) 0px 5px 20px;
  border-radius: 50px;
  box-sizing: border-box;
  padding: 5px 25px;
  width: 100%;
  align-items: center;
  position: relative;
  height: 50px;
`
