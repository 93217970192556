import React, { useState } from 'react'
import differenceWith from 'lodash/differenceWith'
import uniq from 'lodash/uniq'
import chunk from 'lodash/chunk'
import isEqual from 'lodash/isEqual'
import findIndex from 'lodash/findIndex'
import remove from 'lodash/remove'
import Dropzone from 'react-dropzone'
import { CSVLink } from 'react-csv'
import { Button } from '@teamfabric/copilot-ui'
import { getItemIdsBySku } from 'src/api/product'
import { StyledUploadWrapper } from './styles'
import Spinner from 'components/spinner'
import { csvReader } from 'src/lib/utils/helper'
import RenderError from 'components/error'
const UploadCsvFile = ({
  onClose,
  headersData,
  errorData,
  bulkSkuCheck,
  onAddProducts,
  targetType,
  selectedSkus,
  showHideCloseIcon,
  otherTargetTypesSelectedSKus = [],
}) => {
  const [isLoading, setIsLoading] = useState(false),
    [files, setFiles] = useState([]),
    [isResponce, setIsResponce] = useState(false),
    [error, setError] = useState(false),
    [errorMessage, setErrorMessage] = useState(''),
    [csvFileSkus, setCsvFileSkus] = useState([]),
    [csvFileParsedDetailsData, setCsvFileParsedDetailsData] = useState({}),
    [alreadyNotSelected, setAlreadyNotSelected] = useState({}),
    [duplicates, setDuplicates] = useState({}),
    [parentData, setParentData] = useState({}),
    [wrongHeader, setWrongHeader] = useState(false),
    getParseData = async (parseData) => {
      const skuList = []
      parseData.data.forEach((sku) => {
        if (sku['SKU ID'] && sku['SKU ID'] !== undefined) {
          skuList.push(sku['SKU ID'])
        } else {
          setWrongHeader(true)
        }
      })
      doApiCallInChunks(skuList)
    },
    doApiCallInChunks = (skuList) => {
      const duplicates = skuList.filter(
        (sku, index) => skuList.indexOf(sku) !== index
      )
      setDuplicates(duplicates)
      let diffList = differenceWith(skuList, selectedSkus, (reqSku, resSku) => {
        return reqSku === resSku.sku
      })
      diffList = uniq(skuList)
      diffList = differenceWith(skuList, otherTargetTypesSelectedSKus)
      const skuChunkList = chunk(diffList, 500)
      setIsLoading(true)
      showHideCloseIcon(false)
      setCsvFileSkus(skuList)
      setAlreadyNotSelected(diffList)
      Promise.all(
        skuChunkList.map((chunkList) => {
          let reqData = {
            skus: chunkList,
          }
          return getItemIdsBySku(reqData)
        })
      )
        .then((responses) => {
          let allData = []
          responses.forEach((arr) => {
            allData = [...allData, ...arr.data]
          })
          let childData = removeParent([...allData])
          let parent = differenceWith(allData, childData, isEqual)
          setParentData(parent)
          bulkSkuCheck({ skus: childData, targetType: targetType })
          onAddProducts(targetType)
          setIsLoading(false)
          showHideCloseIcon(true)
          setCsvFileParsedDetailsData(childData)
          if (childData.errorType === 'Error') {
            setError(true)
            setErrorMessage(childData.errorMessage)
          }
          setIsResponce(true)
        })
        .catch((error) => {
          setIsLoading(false)
          if (error && error.response && error.response.data) {
            setError(true)
            setErrorMessage(error.response.data.message)
          }
        })
    },
    removeParent = (data) => {
      let parentList = data
        .map((item) => item.parent)
        .filter(
          (parentId, index, arr) => parentId && arr.indexOf(parentId) === index
        )
      parentList.forEach((_id) => {
        remove(data, {
          _id: _id,
        })
      })
      return data
    },
    renderPromptMessage = () => {
      const headersDataWith = [...errorData]
      if (wrongHeader) {
        headersDataWith.push([
          '',
          'Header is not matching with the default template. Correct header is "SKU ID" ',
        ])
      } else {
        const errorNotSelectedSku = differenceWith(
          alreadyNotSelected,
          csvFileParsedDetailsData,
          (reqSku, resSku) => {
            return reqSku === resSku.sku
          }
        )
        const errorSelectedSku = differenceWith(
          csvFileSkus,
          alreadyNotSelected,
          (reqSku, resSku) => {
            return reqSku === resSku
          }
        )
        errorSelectedSku.forEach((sku) => {
          headersDataWith.push([
            sku,
            `SKU is already selected in other sku set`,
          ])
        })
        errorNotSelectedSku.forEach((sku) => {
          headersDataWith.push([sku, 'SKU ID is wrong'])
        })
        duplicates.forEach((sku) => {
          headersDataWith.push([sku, 'It is a duplicate SKU'])
        })
        parentData.forEach((item) => {
          var index = findIndex(headersDataWith, (error) => {
            return error[0] === item.sku
          })
          if (index > -1) {
            headersDataWith.splice(index, 1, [
              item.sku,
              'Parent SKU ID is not allowed',
            ])
          } else {
            headersDataWith.push([item.sku, 'Parent SKU ID is not allowed'])
          }
        })
      }
      return (
        <StyledUploadWrapper>
          <div className='header' data-testid='post-request-modal-header'>
            Bulk upload SKUs
          </div>
          <section className='sku-details-section'>
            <p data-testid='total-number'>
              Total number of SKUs in CSV file :{' '}
              <span>{csvFileSkus.length}</span>
            </p>
            <p data-testid='sku-number'>
              Number of SKUs uploaded :{' '}
              <span>{csvFileParsedDetailsData.length}</span>
            </p>
            <p data-testid='errors-number'>
              Number of SKUs with errors :{' '}
              <span>{headersDataWith.length - 1}</span>
            </p>
          </section>
          <p>
            Download the CSV file with errors from
            <span className='template_here'>
              <CSVLink data={headersDataWith} filename='bulk_upload_SKUs_Error'>
                {' '}
                <span>here. </span>
              </CSVLink>
            </span>
          </p>
          <div className='button-container'>
            <Button
              data-testid='ok-button'
              color='primary'
              size='small'
              onClick={onClose}
              text='OK'
            />
          </div>
        </StyledUploadWrapper>
      )
    }

  return (
    <>
      {!isResponce && (
        <StyledUploadWrapper>
          <div className='header' data-testid='modal-header'>
            Import CSV file
          </div>
          <div className='dropzone'>
            <Dropzone
              onDrop={(files) => {
                setFiles(files)
                setError(false)
              }}
              maxSize={20000000}
              accept='text/csv, application/vnd.ms-excel'
            >
              {({ getRootProps, getInputProps, rejectedFiles }) => {
                return (
                  <section>
                    <div className='button_wrapper' data-testid='drop-zone'>
                      <input {...getInputProps()} data-testid='file-drop' />
                      <Button
                        {...getRootProps()}
                        color='secondary'
                        isPrimary={false}
                        text='Choose file'
                        size='small'
                      />

                      {rejectedFiles.length > 0 ? (
                        <span className='file_error'>
                          {' '}
                          File choosen is greater than 2MB{' '}
                        </span>
                      ) : files.length > 0 ? (
                        <span className='file_name'> {files[0].name} </span>
                      ) : (
                        <span> No file choosen</span>
                      )}
                    </div>
                  </section>
                )
              }}
            </Dropzone>
            <p>
              Download a
              <span className='template_here'>
                <CSVLink
                  data={headersData}
                  filename='bulk_upload_SKUs_template'
                >
                  {' '}
                  <span>template here </span>
                </CSVLink>
              </span>
              to view an example of a csv file.
            </p>
            {error && <RenderError id='error-message' message={errorMessage} />}
          </div>
          <div className='button-container'>
            {!isLoading && (
              <Button
                data-testid='upload-sku-cancel-button'
                className='cancel_btn'
                size='small'
                onClick={onClose}
                text='Cancel'
                isPrimary={false}
              />
            )}
            <Button
              data-testid='upload-file'
              size='small'
              text='Upload file'
              disabled={!files.length || error}
              onClick={() => csvReader({ files: files }, getParseData)}
            />
          </div>
          {files.length > 0 && isLoading && (
            <div className='loading'>
              <Spinner
                variant='fullScreen'
                data-testid='fs-spinner'
                type='logo'
              />
            </div>
          )}
        </StyledUploadWrapper>
      )}
      {isResponce ? renderPromptMessage() : ''}
    </>
  )
}
export default UploadCsvFile
