import { createActionPrefix } from 'redux-nano'
import {
  getFinalCategoriesSkusCount,
  getCheckedCategories,
  getFinalAttributeItemsDetails,
  getFinalSelectedSkusCount,
  getFinalCollectionsSkusCount,
} from './selectors'
import { joinSelection, transformPimConnector } from './utils'
import {
  searchedAttributesFromPimConnector,
  getItems,
} from '../promo/form/components/products-selection-v2/api'

const Action = createActionPrefix('PROMO')

export const RESET_PROMO_USAGE = Action('RESET_PROMO_USAGE')
export const SET_PROMOTION_ADDITIONAL_ATTRIBUTES = Action(
  'SET_PROMOTION_ADDITIONAL_ATTRIBUTES'
)
export const SET_PROMOTION = Action('SET_PROMOTION')
export const RESET_PROMOTION = Action('RESET_PROMOTION')
export const SET_EXCLUSION = Action('SET_EXCLUSION')
export const ADD_TO_TEMP_SELECTED_CATEGORIES = Action(
  'ADD_TO_TEMP_SELECTED_CATEGORIES'
)
export const ADD_TO_TEMP_SELECTED_COLLECTIONS = Action(
  'ADD_TO_TEMP_SELECTED_COLLECTIONS'
)
export const ON_ATTRIBUTE_RESET = Action('ON_ATTRIBUTE_RESET')
export const SET_IS_DS4 = Action('SET_IS_DS4')
export const SET_TARGET_TYPE = Action('SET_TARGET_TYPE')
export const SET_ANOTHER_TARGET_TYPE = Action('SET_ANOTHER_TARGET_TYPE')
export const DELETE_TARGET_TYPE = Action('DELETE_TARGET_TYPE')
export const IS_FETCHING_ITEMS = Action('IS_FETCHING_ITEMS')
export const RESET_PRODUCTS = Action('RESET_PRODUCTS')
export const SET_PROMO_FORM_VALUES = Action('SET_PROMO_FORM_VALUES')
export const SET_FORM_VALUES = Action('SET_FORM_VALUES')
export const ON_EXCLUDE_INCLUDE = Action('ON_EXCLUDE_INCLUDE')
export const SET_INCLUDE_OPERATOR = Action('SET_INCLUDE_OPERATOR')
export const SET_EXCLUDE_OPERATOR = Action('SET_EXCLUDE_OPERATOR')
export const SET_INCLUDE_RULES = Action('SET_INCLUDE_RULES')
export const SET_EXCLUDE_RULES = Action('SET_EXCLUDE_RULES')
export const ON_CHECK_SKU = Action('ON_CHECK_SKU')
export const BULK_SKU_CHECK = Action('BULK_SKU_CHECK')
export const ON_SKU_CHECK = Action('ON_SKU_CHECK')
export const ON_SKU_DELETE = Action('ON_SKU_DELETE')
export const ON_SKUS_SELECT_ALL = Action('ON_SKUS_SELECT_ALL')
export const UPDATE_ON_PRODUCTS = Action('UPDATE_ON_PRODUCTS')
export const ON_CHECK_CAGTEGORY = Action('ON_CHECK_CAGTEGORY')
export const ON_CHECK_COLLECTION = Action('ON_CHECK_COLLECTION')
export const SET_CATEGORIES_PATH = Action('SET_CATEGORIES_PATH')
export const SET_COLLECTIONS_PATH = Action('SET_COLLECTIONS_PATH')
export const ON_CAGTEGORY_DELETE = Action('ON_CAGTEGORY_DELETE')
export const ON_COLLECTION_DELETE = Action('ON_COLLECTION_DELETE')
export const ON_ATTRIBUTE_CHECK = Action('ON_ATTRIBUTE_CHECK')
export const ON_ATTRIBUTE_DELETE = Action('ON_ATTRIBUTE_DELETE')
export const CLICK_ON_PROMOTION_TYPE = Action('CLICK_ON_PROMOTION_TYPE')
export const UPDATE_PROMO_TIERS = Action('UPDATE_PROMO_TIERS')
export const SET_ATTRIBUTE_ITEM_DETAILS = Action('SET_ATTRIBUTE_ITEM_DETAILS')
export const ON_CANCEL = Action('ON_CANCEL')
export const SET_CATEGORIES_SKUS_COUNT = Action('SET_CATEGORIES_SKUS_COUNT')
export const SET_COLLECTIONS_SKUS_COUNT = Action('SET_COLLECTIONS_SKUS_COUNT')
export const SET_CUSTOMER_SEGMENT = Action('SET_CUSTOMER_SEGMENT')
export const SET_EXCLUDE_CUSTOMER_SEGMENT = Action(
  'SET_EXCLUDE_CUSTOMER_SEGMENT'
)
export const SET_INCLUDE_CUSTOMER_SEGMENT_OPERATOR = Action(
  'SET_INCLUDE_CUSTOMER_SEGMENT_OPERATOR'
)
export const SET_EXCLUDE_CUSTOMER_SEGMENT_OPERATOR = Action(
  'SET_EXCLUDE_CUSTOMER_SEGMENT_OPERATOR'
)
export const SET_USER_SEGMENT = Action('SET_USER_SEGMENT')
export const SET_FINAL_CATEGORIES_SKUS_COUNT = Action(
  'SET_FINAL_CATEGORIES_SKUS_COUNT'
)
export const SET_FINAL_COLLECTIONS_SKUS_COUNT = Action(
  'SET_FINAL_COLLECTIONS_SKUS_COUNT'
)
export const SET_FINAL_ATTRIBUTES_SKUS_COUNT = Action(
  'SET_FINAL_ATTRIBUTES_SKUS_COUNT'
)
export const SET_PROMO_USAGE = Action('SET_PROMO_USAGE')
export const SET_PROMOTION_MESSAGES = Action('SET_PROMOTION_MESSAGES')
export const SET_PROMOTION_MESSAGES_FORM_VALID = Action(
  'SET_PROMOTION_MESSAGES_FORM_VALID'
)
export const SET_PROXIMITY_MESSAGES = Action('SET_PROXIMITY_MESSAGES')
export const SET_FINAL_ATTRIBUTE_ITEM_DETAILS = Action(
  'SET_FINAL_ATTRIBUTE_ITEM_DETAILS'
)
export const SET_ALL_PRODUCTS_COUNT = Action('SET_ALL_PRODUCTS_COUNT')
export const SET_FINAL_PRODUCTS_COUNT = Action('SET_FINAL_PRODUCTS_COUNT')
export const SET_PROMOTION_LIMIT = Action('SET_PROMOTION_LIMIT')
export const SET_TRIGGER_VALIDATION = Action('SET_TRIGGER_VALIDATION')

export const UPDATE_PRODUCTS_AND_COUNT = (details) => {
  const { targetType } = details
  return (dispatch) => {
    Promise.resolve(dispatch(UPDATE_ON_PRODUCTS(details))).then(() => {
      Promise.resolve(dispatch(UPDATE_COUNT({ targetType })))
      dispatch(SET_FINAL_PRODUCTS_COUNT({ targetType }))
    })
  }
}
export const ON_EXCLUDE_INCLUDE_UPDATE_COUNT = (targetType, operator = '') => {
  return (dispatch) => {
    dispatch(ON_EXCLUDE_INCLUDE({ type: targetType, operator }))
    dispatch(UPDATE_COUNT({ targetType, operator }))
    dispatch(SET_FINAL_PRODUCTS_COUNT({ targetType, operator }))
  }
}

export const UPDATE_COUNT = ({ targetType, operator = '' }) => {
  return (dispatch, getState) => {
    const state = getState()
    let skusCount = getFinalSelectedSkusCount(state, targetType, operator) || 0
    let attributeSkus = getFinalAttributeItemsDetails(
      state,
      targetType,
      operator
    )
    let checkedCategories =
      getCheckedCategories(state, targetType, false, operator) || []
    let checkedCollections =
      getCheckedCategories(state, targetType, true, operator) || []
    let categorySkus =
      getFinalCategoriesSkusCount(state, targetType, operator) || 0
    let collectionSkus =
      getFinalCollectionsSkusCount(state, targetType, operator) || 0
    let attrSkusCount =
      attributeSkus && Object.keys(attributeSkus)?.length > 0
        ? attributeSkus.count || attributeSkus.totalProducts
        : 0
    let checkedCategorySkus = checkedCategories.length > 0 ? categorySkus : 0
    let checkedCollectonSkus =
      checkedCollections.length > 0 ? collectionSkus : 0
    let count =
      skusCount +
      checkedCategorySkus +
      checkedCollectonSkus +
      (attrSkusCount || 0)
    dispatch(SET_ALL_PRODUCTS_COUNT({ count, targetType, operator }))
  }
}

const getProductSearch = async ({
  queries,
  targetType,
  dispatch,
  isDelete,
  page,
  previousState,
  operator = '',
}) => {
  dispatch(IS_FETCHING_ITEMS(true))
  if (queries.length == 0) {
    dispatch(
      SET_ATTRIBUTE_ITEM_DETAILS({
        itemDetails: { items: [] },
        targetType,
        operator,
      })
    )
    dispatch(IS_FETCHING_ITEMS(false))
    return
  }

  let result = {}
  const payload = {
    query: {
      bool: {
        must: [
          {
            match: {
              tenantId:
                localStorage.getItem('isLoggedInWithIdV2') === 'true'
                  ? sessionStorage.getItem('accountId')
                  : sessionStorage.getItem('account'),
            },
          },
          {
            bool: {
              should: queries,
            },
          },
        ],
      },
    },
    limit: 7,
    offset: 7 * page,
  }

  result = await searchedAttributesFromPimConnector({
    body: payload,
  })
  let itemDetails = transformPimConnector({ result, page, previousState })

  dispatch(SET_ATTRIBUTE_ITEM_DETAILS({ itemDetails, targetType, operator }))
  dispatch(IS_FETCHING_ITEMS(false))
  if (isDelete) {
    dispatch(SET_FINAL_ATTRIBUTE_ITEM_DETAILS({ targetType, operator }))
    dispatch(SET_FINAL_ATTRIBUTES_SKUS_COUNT({ targetType, operator }))
    dispatch(UPDATE_COUNT({ targetType, operator }))
    dispatch(SET_FINAL_PRODUCTS_COUNT({ targetType, operator }))
  }
}

export const GET_ATTRIBUTE_ITEMS = ({
  page,
  targetType,
  isDelete = false,
  operator = '',
}) => {
  return async (dispatch, getState) => {
    dispatch(IS_FETCHING_ITEMS(true))
    const state = getState().promoReducer
    const {
      [`${targetType}${operator}TempSelectedAttributesTree`]: selectedAttributesTree
    } = state
    const queries = joinSelection({ selection: selectedAttributesTree })
    await getProductSearch({
      queries: queries,
      targetType,
      dispatch,
      isDelete,
      page,
      previousState: state?.[`targetX${operator}AttributeItemsDetails`]?.items,
      operator,
    })
  }
}

export const GET_ITEMS = ({
  targetType,
  isDelete = false,
  type,
  operator = '',
}) => {
  return (dispatch, getState) => {
    const state = getState()
    const isAh = type === 'collection'
    const checkCategoried = getCheckedCategories(
      state,
      targetType,
      isAh,
      operator
    )
    dispatch(IS_FETCHING_ITEMS(true))
    const reqData = {
      limit: 10,
      offset: 0,
      keyword: '',
      group: checkCategoried,
    }
    checkCategoried.length > 0 &&
      Promise.resolve(getItems(reqData, type))
        .then((response) => {
          const { data } = response
          if (type === 'collection') {
            dispatch(
              SET_COLLECTIONS_SKUS_COUNT({
                count: data?.count,
                targetType,
                operator,
              })
            )
          } else {
            dispatch(
              SET_CATEGORIES_SKUS_COUNT({
                count: data?.count,
                targetType,
                operator,
              })
            )
          }
          if (isDelete) {
            type === 'collection'
              ? dispatch(
                  SET_FINAL_COLLECTIONS_SKUS_COUNT({ targetType, operator })
                )
              : dispatch(
                  SET_FINAL_CATEGORIES_SKUS_COUNT({ targetType, operator })
                )
          }
          dispatch(UPDATE_COUNT({ targetType, operator }))
          isDelete && dispatch(SET_FINAL_PRODUCTS_COUNT({ targetType }))
          dispatch(IS_FETCHING_ITEMS(false))
        })
        .catch(() => {
          dispatch(IS_FETCHING_ITEMS(false))
        })
  }
}

export const ON_SKU_SELECT = (skuDetails) => {
  return (dispatch) => {
    dispatch(ON_CHECK_SKU(skuDetails))
  }
}

export const CHECK_CATEGORY_AND_FETCH_CATEGORY_ITEMS = (categoryDetails) => {
  const { targetType, operator = '' } = categoryDetails
  return (dispatch) => {
    dispatch(ON_CHECK_CAGTEGORY(categoryDetails))
    dispatch(GET_ITEMS({ targetType, type: 'category', operator }))
  }
}

export const CHECK_CATEGORY_AND_FETCH_NEW_ITEMS = (categoryDetails) => {
  const { targetType, operator = '' } = categoryDetails
  return (dispatch) => {
    dispatch(ON_CHECK_CAGTEGORY(categoryDetails))
    dispatch(GET_ITEMS({ targetType, type: 'category', operator }))
  }
}

export const CHECK_COLLECTION_AND_FETCH_NEW_ITEMS = (categoryDetails) => {
  const { targetType, operator = '' } = categoryDetails
  return (dispatch) => {
    dispatch(ON_CHECK_COLLECTION(categoryDetails))
    dispatch(GET_ITEMS({ targetType, type: 'collection', operator }))
  }
}

export const CHECK_COLLECTION_AND_FETCH_COLLECTION_ITEMS = (
  categoryDetails
) => {
  const { targetType, operator = '' } = categoryDetails
  return (dispatch) => {
    dispatch(ON_CHECK_COLLECTION(categoryDetails))
    dispatch(GET_ITEMS({ targetType, type: 'collection', operator }))
  }
}

export const DELETE_CATEGORIES_AND_FETCH_NEW_ITEMS = (categoryDetails) => {
  const { targetType, operator = '' } = categoryDetails
  return (dispatch) => {
    dispatch(ON_CAGTEGORY_DELETE(categoryDetails))
    dispatch(
      GET_ITEMS({ targetType, isDelete: true, type: 'category', operator })
    )
  }
}

export const DELETE_COLLECTIONS_AND_FETCH_NEW_ITEMS = (categoryDetails) => {
  const { targetType, operator = '' } = categoryDetails
  return (dispatch) => {
    dispatch(ON_COLLECTION_DELETE(categoryDetails))
    dispatch(
      GET_ITEMS({ targetType, isDelete: true, type: 'collection', operator })
    )
  }
}

export const DELETE_ATTRIBUTE_AND_FETCH_NEW_ITEMS = (attibuteDetails) => {
  const { targetType, operator = '' } = attibuteDetails
  return (dispatch) => {
    dispatch(ON_ATTRIBUTE_DELETE(attibuteDetails))
    dispatch(
      GET_ATTRIBUTE_ITEMS({ page: 0, targetType, isDelete: true, operator })
    )
  }
}

export const DELETE_SKUS_AND_FETCH_NEW_ITEMS = (skuDetails) => {
  const { targetType, operator = '' } = skuDetails
  return (dispatch) => {
    dispatch(ON_SKU_DELETE(skuDetails))
    dispatch(UPDATE_COUNT({ targetType, operator }))
    dispatch(SET_FINAL_PRODUCTS_COUNT({ targetType, operator }))
  }
}
