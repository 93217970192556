import React from 'react'
import Field from 'src/components/view/field'
import { StyledPromoUsage } from '../styles'

const TermsAndConditions = ({ termsAndConditions }) => {
  return (
    <div className='content_wrapper_25'>
      <StyledPromoUsage>
        <div className='title_heading'>Terms and conditions (optional)</div>
        {termsAndConditions.map((item) => (
          <div className='margin_24'>
            <Field
              title={item.title}
              value={item.description ? item.description : 'N/A'}
            />
          </div>
        ))}
      </StyledPromoUsage>
    </div>
  )
}

export default TermsAndConditions
