export default {
  breakpoints: {
    keys: ['md', 'lg'],
    values: {
      md: 1024,
      lg: 1440,
    },
  },
  direction: 'ltr',
  palette: {
    common: {
      black: '#0C053D', // For body paragraph text, headers and subtitles
      white: '#fff',
    },
    blue: {
      main: '#0D62FF',
      cerculean: '#CDD2ED',
      electric: '#58687A',
      disabled: '#CDDEFD',
    },
    text: {
      main: '#0C053D', // For body paragraph text, headers and subtitles
      label: '#58687A', // Text Field Labels”, “Table header text
      enable: '#8994A3', // Side navigation links that are enabled, disbaled state as well
      outline: '#C4C9D1', // Text Field outlines
      separation: '#E6E8EB',
      separator: '#E6E8EB',
      container: '#F3F3F5',
      chip: '#F1F2F4',
      chipText: '#FAFBFD',
      containerUpdated: '#E3E5E9',
      disabled: 'rgba(0, 0, 0, 0.38)',
      charcoal: '#121213',
    },
    backdrop: {
      main: 'rgba(0, 0, 0, 0.35)',
    },
    accent: {
      main: '#e74563', // pink
      dark: '#e6e6eb', // border-line
    },
    success: {
      light: '#e0f7e9',
      lighter: '#d1f3df',
      main: '#3BC77A',
      dark: '#39b86f',
    },
    error: {
      light: '#ffdbd8',
      lighter: '#ffe7e5',
      main: '#F24659',
      dark: '#e56154',
      darker: '#b10000',
    },
    info: {
      lighter: '#CCE9FF',
      main: '#0091FF',
    },
    warn: {
      light: '#fff0d5',
      lighter: '#fff4e3',
      main: '#E5CA11',
      dark: '#ce821b',
    },
    grey: {
      light: '#e9e9ea',
      lighter: '#f6f6f6',
      main: '#bfbfbf',
      dark: '#929292',
      brand: '#737F8F',
      border: 'rgb(115, 127, 143, 0.2)',
    },
    silver: {
      main: '#c1bebe',
    },
    black: {
      main: '#0C053D',
      mainUpdated: '#121213',
      contrastText: '#fff',
    },
    white: {
      main: '#fff',
      contrastText: '#0C053D',
    },
    background: {
      main: '#E5E5E5',
    },
    tab: {
      active: '#0D62FF',
      hover: '#384454',
    },
  },
  shadows: [],
  typography: {
    fontFamily: 'Gilroy',
    fontSize: 14,
    h1: {
      fontSize: '48px',
      fontWeight: '800',
      letterSpacing: 'normal',
      lineHeight: '56px',
      fontStyle: 'normal',
    },
    h2: {
      fontSize: '36px',
      fontWeight: '600',
      letterSpacing: 'normal',
      lineHeight: '42px',
      fontStyle: 'normal',
    },
    h3: {
      fontSize: '24px',
      fontWeight: '500',
      letterSpacing: 'normal',
      lineHeight: '32px',
      fontStyle: 'normal',
    },
    h4: {
      fontSize: '18px',
      fontWeight: '600',
      letterSpacing: 'normal',
      lineHeight: '24px',
      fontStyle: 'normal',
    },
    h5: {
      fontSize: '14px',
      fontWeight: '500',
      letterSpacing: 'normal',
      lineHeight: '20px',
      fontStyle: 'normal',
    },
    h6: {
      fontSize: '12px',
      fontWeight: '500',
      letterSpacing: '0.5px',
      lineHeight: '16px',
      fontStyle: 'normal',
    },
    bodyLarge: {
      fontSize: '16px',
      fontWeight: '500',
      letterSpacing: 'normal',
      lineHeight: '24px',
      fontStyle: 'normal',
      lineSpacing: '12px',
    },
    body: {
      fontSize: '14px',
      fontWeight: '500',
      letterSpacing: 'normal',
      lineHeight: '20px',
      fontStyle: 'normal',
      lineSpacing: '12px',
    },
    link: {
      fontSize: '14px',
      fontWeight: '600',
      letterSpacing: 'normal',
      lineHeight: '20px',
      fontStyle: 'normal',
    },
    buyGetLink: {
      fontSize: '14px',
      fontWeight: '500',
      letterSpacing: 'normal',
      lineHeight: '20px',
      fontStyle: 'normal',
    },
    label: {
      fontSize: '12px',
      fontWeight: '600',
      letterSpacing: 'normal',
      lineHeight: '16px',
      fontStyle: 'normal',
    },
    label1: {
      fontSize: '16px',
      fontWeight: '600',
      letterSpacing: 'normal',
      lineHeight: '18px',
      fontStyle: 'normal',
    },
    small: {
      fontSize: '12px',
      fontWeight: '500',
      letterSpacing: 'normal',
      lineHeight: '16px',
      fontStyle: 'normal',
    },
    validation: {
      fontSize: '11px',
      fontWeight: '700',
      letterSpacing: 'normal',
      lineHeight: '16px',
      fontStyle: 'italic',
    },
    custom: {
      fontSize: '13px',
      fontWeight: '500',
      letterSpacing: '0.26px',
      lineHeight: '16px',
      fontStyle: 'normal',
    },
    customInfo: {
      fontSize: '12px',
      fontWeight: '400',
      letterSpacing: '0.26px',
      lineHeight: '14px',
      fontStyle: 'normal',
    },
    kicker: {
      fontSize: '12px',
      fontFamily: 'Gilroy-SemiBold',
      letterSpacing: '0.9px',
      lineHeight: '14px',
      fontStyle: 'normal',
      textTransform: 'uppercase',
    },
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  zIndex: {
    hoverOver: 1000,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
}
