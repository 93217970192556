import styled from 'styled-components'
import theme from 'lib/theme/theme'
import { hexToRgba } from 'lib/utils/helper'

const StyledBuyRightBorderRgba = hexToRgba(theme.palette.grey.brand, 0.2)

export const StyledBuyGetInfo = styled.div`
  padding-top: 24px;
  .text {
    ${(props) => props.theme.typography.customInfo};
    color: ${(props) => props.theme.palette.text.charcoal};
    box-shadow: 0px 6px 16px rgba(115, 127, 143, 0.16);
    width: 375px;
    padding: 8px 0px 8px 8px;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  z-index: 100;
  flex-direction: row;
  padding-top: 24px;
  align-items: flex-end;
  .segment-name {
    height: 56px;
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-between;
  }
`

export const StyledBuy = styled.div`
  border-right: 1px solid
    rgba(
      ${StyledBuyRightBorderRgba.red},
      ${StyledBuyRightBorderRgba.green},
      ${StyledBuyRightBorderRgba.blue},
      ${StyledBuyRightBorderRgba.opacity}
    );
  width: 424px;
  .buy-heading {
    ${theme.typography.kicker};
    color: ${(props) => props.theme.palette.grey.brand};
  }
  .collapse {
    padding-top: 25px;
    display: flex;
    .set-name {
      ${theme.typography.kicker};
      color: ${(props) => props.theme.palette.text.charcoal};
      ${(props) =>
        props.disabled && { color: props.theme.palette.text.disabled }}
      padding-top: 4px;
    }
  }
`
export const StyledGet = styled.div`
  .min-max-inputs {
    padding-top: 8px;
    width: 391px;
    display: flex;
    .secondary {
      padding-left: 16px;
    }
  }
  .discount-fields {
    justify-content: space-between;
    width: 70%;
    display: flex;
    .discount-amount {
      padding-left: 24px;
      padding-top: 1px;
    }
  }
  padding-left: 22px;
  width: 425px;
  .discount-number {
    ${theme.typography.kicker};
    color: ${(props) => props.theme.palette.text.charcoal};
    padding-top: 24px;
    padding-bottom: 10px;
    ${(props) => props.disabled && { color: props.theme.palette.text.disabled }}
  }
  .get-heading {
    ${theme.typography.kicker};
    color: ${(props) => props.theme.palette.grey.brand};
  }
  .input-fields {
    padding-top: 10px;
  }
  .shipping-fields {
    padding-top: 15px;
  }
`

export const StyledBuyGet = styled.div`
  display: flex;
`

export const StyledModal = styled.div`
  padding: 20px;
  .header {
    ${(props) => props.theme.typography.h4.css};
    margin-bottom: 24px;
  }
  .info {
    ${(props) => props.theme.typography.body};
    margin-bottom: 40px;
  }
  .button_actions {
    display: flex;
    justify-content: flex-end;
  }
`

export const StyledForm = styled.div`
  overflow: hidden;
  overflow-y: scroll;
  padding-bottom: 150px;
  background: #fff;
  width: 930px;

  // Hide scroll bar
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`
export const StyledFormWrapper = styled.div`
  display: flex;
  background: #fff;
`
export const StyledPromoInfo = styled.div`
  display: flex;
  padding: 0 24px;
  justify-content: flex-end;
  background: ${(props) => props.theme.palette.common.white};
  border-top: 1px solid ${(props) => props.theme.palette.text.separation};
  margin-top: 32px;
  .info-head {
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    .title-wrapper {
      padding: 40px 0;
      span {
        display: inline-block;
        height: 20px;
        color: #121213;
        font-weight: 600;
        font-size: 21px;
      }
    }
    .name-toggle {
      display: flex;
      .toggle {
        display: inline-flex;
        padding-left: 25px;
      }
    }
  }
`
export const StyledPromoTiers = styled.div`
  margin-top: 24px;
  .tier_seperator {
    height: 1px;
    background-color: ${(props) => props.theme.palette.text.containerUpdated};
    margin-bottom: 24px;
    display: ${(props) => (props.showSeperator ? 'block' : 'none')};
  }
  .get_section {
    width: 50%;
  }
  .tier_title {
    ${(props) => props.theme.typography.label};
    margin: 0;
    ${(props) =>
      props.disabled && { color: props.theme.palette.text.disabled }};
  }
  .uppercase {
    text-transform: uppercase;
  }
  .section_title {
    ${(props) => props.theme.typography.label.css};
    color: ${(props) => props.theme.palette.blue.electric};
    ${(props) =>
      props.disabled && { color: props.theme.palette.text.disabled }};
  }
  .section_row {
    align-items: center;
    justify-content: space-between;
    align-items: baseline;
    .type_and_value {
      &.flex {
        align-items: baseline;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .section_col {
    padding-bottom: 32px;
  }
`
export const StyledPromoUsage = styled.div`
  border: 1px solid rgb(115, 127, 143, 0.2);
  padding: 32px;
  border-radius: 4px;
  .title_heading {
    ${(props) => props.theme.typography.h4};
    color: ${(props) => props.theme.palette.text.charcoal};
    margin-bottom: 18px;
    ${(props) =>
      props.disabled && { color: props.theme.palette.text.disabled }};
  }
`
