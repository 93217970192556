import { createReducer } from 'store/utils'
import {
  SET_EXCLUSION_FORM_VALUES,
  RESET_EXCLUSION,
  SET_EXCLUSION_ALL_FORM_VALUES,
} from './actions'

const initialState = {
  exclusion: {
    title: '',
    startDate: '',
    endDate: '',
    applicableOn: '',
  },
}

const exclusionReducer = createReducer(initialState, {
  [RESET_EXCLUSION]: () => {
    return {
      ...initialState,
      exclusion: {
        ...initialState.exclusion,
      },
    }
  },
  [SET_EXCLUSION_FORM_VALUES]: (state, action) => {
    return {
      ...state,
      exclusion: {
        ...state.exclusion,
        [action.payload.type]: action.payload.value,
      },
    }
  },
  [SET_EXCLUSION_ALL_FORM_VALUES]: (state, action) => {
    return {
      ...state,
      exclusion: {
        ...state.exclusion,
        title: action.payload.title,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        updatedAt: action.payload.updatedAt,
        applicableOn: action.payload.applicableOn,
      },
    }
  },
})
export default exclusionReducer
