import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ProductsModal from './products-modal'
import Modal from 'components/modal'
import UploadCsvFile from './upload-csv-file'
import { Radio, ErrorMessage, Button } from '@teamfabric/copilot-ui'
import {
  BULK_SKU_CHECK,
  ON_EXCLUDE_INCLUDE_UPDATE_COUNT,
  RESET_PRODUCTS,
  SET_TARGET_TYPE,
  UPDATE_COUNT,
  UPDATE_ADVANCED_SKUS,
} from 'modules/promo/actions'
import {
  getPromotion,
  getSelectedSkus,
  getRadioId,
  getCheckedCategories,
  getProducts,
  getAllProductsCount,
  getTargetType,
} from 'modules/promo/selectors'
import ProductChips from './product-chips'
import { StyledPromoProducts } from './styles'
import Field from '../view/field'
import { getProductSelectionLabel } from 'src/modules/promo/view/helper'

export class PromoProductSelection extends Component {
  state = {
    showProductsModal: false,
    showUploadCsvModal: false,
    type: this.props.type,
    errors: this.props.errors,
    targetType: 'targetX',
    isUploadCsvModalCancelable: true,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errors !== this.props.errors) {
      this.setState({ errors: this.props.errors })
    }
  }

  componentDidMount() {
    this.props.setTargetType(
      this.props.fromAdvancedOptions ? 'targetY' : 'targetX'
    )
    this.setState({
      targetType: this.props.fromAdvancedOptions ? 'targetY' : 'targetX',
    })
  }

  handleErrors() {
    this.setState({ errors: '' })
  }

  handleModal = (value, type) => {
    this.setState({
      showProductsModal: value,
      type: type,
      isShowMore: type ? true : false,
      errors: '',
    })
  }
  uploadCsvModal = (value) => {
    this.setState({
      showUploadCsvModal: value,
    })
  }
  hideUploadCsvModal = () => {
    this.setState({
      showUploadCsvModal: false,
    })
  }
  setUploadCsvModalCancelable = (value) => {
    this.setState({
      isUploadCsvModalCancelable: value,
    })
  }
  render() {
    const { showProductsModal, targetType } = this.state
    const { fromAdvancedOptions, viewMode } = this.props
    return (
      <>
        <div
          className='content_wrapper_25'
          data-testid='promo_product_selection'
        >
          {this.renderContent()}
        </div>
        {!fromAdvancedOptions && <div className='section_divider' />}
        {showProductsModal && (
          <ProductsModal
            isPromo
            viewMode={viewMode}
            onDismiss={() => this.handleModal(false)}
            type={this.state.type}
            isShowMore={this.state.isShowMore}
            fromAdvancedOptions={fromAdvancedOptions}
            targetType={targetType}
          />
        )}
        {this.state.showUploadCsvModal && (
          <Modal
            hideIcon={!this.state.isUploadCsvModalCancelable}
            onClose={this.hideUploadCsvModal}
            id='sku-bulk-upload'
            onBackdropClick={() => {
              if (this.state.isUploadCsvModalCancelable)
                this.hideUploadCsvModal()
            }}
            headerText=''
            render={() => (
              <UploadCsvFile
                targetType={this.state.targetType}
                onAddProducts={this.props.onAddProducts}
                bulkSkuCheck={this.props.bulkSkuCheck}
                selectedSkus={
                  targetType === 'targetX'
                    ? this.props.targetYSelectedSkuList
                    : this.props.targetXSelectedSkuList
                }
                onClose={this.hideUploadCsvModal}
                showHideCloseIcon={(value) =>
                  this.setUploadCsvModalCancelable(value)
                }
                headersData={[['SKU ID']]}
                errorData={[['SKU ID', 'ERROR']]}
              />
            )}
          />
        )}
      </>
    )
  }

  renderContent() {
    const { fromAdvancedOptions, subHeading, disabled, viewMode } = this.props
    const { errors, targetType } = this.state
    const radioId = this.props[`${targetType}RadioId`]
    const isCoupon = window.location.pathname.includes('coupon')
    return (
      <StyledPromoProducts disabled={disabled}>
        {!fromAdvancedOptions && (
          <div className='section_heading'>
            <span>{`${!isCoupon ? 'Promotion' : 'Coupon'} application`}</span>
            {radioId !== 'ALL_PRODUCTS' && !viewMode && (
              <div className='buttons'>
                <Button
                  className='btn'
                  onClick={() => this.uploadCsvModal(true)}
                  disabled={disabled}
                  color='secondary'
                  data-testid={
                    this.state.targetType == 'targetX'
                      ? 'bulk-upload-skus'
                      : 'targetY-bulk-upload-skus'
                  }
                  size='small'
                  isPrimary={false}
                  text='Bulk Upload SKUs'
                />
                <Button
                  className='btn browse'
                  onClick={() => this.handleModal(true, 'SKUs')}
                  disabled={disabled}
                  data-testid={
                    this.state.targetType == 'targetX'
                      ? 'browse-skus'
                      : 'targetY-browse-skus'
                  }
                  size='small'
                  isPrimary={true}
                  text='Browse SKUs'
                />
              </div>
            )}
          </div>
        )}
        {!viewMode ? (
          <>
            <div className='sub_heading'>{subHeading}</div>
            <div className='options'>
              {this.renderRadioOptions()}
              {errors[`${targetType}ProductSelection`] && (
                <ErrorMessage
                  data-testid='error-product-selection'
                  id='error-product-selection'
                  text={errors[`${targetType}ProductSelection`]}
                />
              )}
            </div>
          </>
        ) : (
          <Field title={subHeading} value={getProductSelectionLabel(radioId)} />
        )}
        {radioId !== 'ALL_PRODUCTS' && this.renderBrowseProducts()}
      </StyledPromoProducts>
    )
  }

  renderRadioOptions = () => {
    const { targetType } = this.state
    const radioId = this.props[`${targetType}RadioId`]
    return (
      <div className='radio_option_row'>
        <div className='option' id='INCLUDE_PRODUCTS'>
          <Radio
            className='promo-radio-button'
            tabIndex='0'
            disabled={this.props.disabled}
            onClick={() => {
              this.handleErrors()
              this.props.resetProducts({
                radioId: 'INCLUDE_PRODUCTS',
                promoType: 'include',
                targetType,
              })
            }}
            checked={radioId === 'INCLUDE_PRODUCTS'}
            id={
              this.state.targetType == 'targetX'
                ? 'RADIO-INCLUDE_PRODUCTS'
                : 'RADIO-INCLUDE_PRODUCTS_TARGETY'
            }
            data-testid={
              this.state.targetType == 'targetX'
                ? 'RADIO-INCLUDE_PRODUCTS'
                : 'RADIO-INCLUDE_PRODUCTS_TARGETY'
            }
            label='Include SKUs'
          />
        </div>
        <div className='option'>
          <Radio
            tabIndex='0'
            disabled={this.props.disabled}
            onClick={() => {
              this.handleErrors()
              this.props.resetProducts({
                radioId: 'EXCLUDE_PRODUCTS',
                promoType: 'exclude',
                targetType,
              })
            }}
            checked={radioId === 'EXCLUDE_PRODUCTS'}
            id={
              this.state.targetType == 'targetX'
                ? 'RADIO-EXCLUDE_PRODUCTS'
                : 'RADIO-EXCLUDE_PRODUCTS_TARGETY'
            }
            data-testid={
              this.state.targetType == 'targetX'
                ? 'RADIO-EXCLUDE_PRODUCTS'
                : 'RADIO-EXCLUDE_PRODUCTS_TARGETY'
            }
            label='Exclude SKUs'
          />
        </div>
        {targetType === 'targetX' && (
          <div className='option' id='ALL_PRODUCTS'>
            <Radio
              onClick={() => {
                this.handleErrors()
                this.props.resetProducts({
                  radioId: 'ALL_PRODUCTS',
                  promoType: '',
                  targetType,
                })
                if (targetType === 'targetX') {
                  this.props.resetProducts({
                    radioId: 'EXCLUDE_PRODUCTS',
                    promoType: 'exclude',
                    targetType: 'targetY',
                  })
                  // this.props.updateAdvancedValues({
                  //   key: 'selectedAdvancedOption',
                  //   value: 'SAME_SKUS'
                  // })
                }
              }}
              tabIndex='0'
              disabled={this.props.disabled}
              checked={radioId === 'ALL_PRODUCTS'}
              id={
                this.state.targetType == 'targetX'
                  ? 'RADIO-ALL_PRODUCTS'
                  : 'RADIO-ALL_PRODUCTS_TARGETY'
              }
              data-testid={
                this.state.targetType == 'targetX'
                  ? 'RADIO-ALL_PRODUCTS'
                  : 'RADIO-ALL_PRODUCTS_TARGETY'
              }
              label='All SKUs'
            />
          </div>
        )}
      </div>
    )
  }

  renderBrowseProducts() {
    const { targetType } = this.state
    const totalProductsCount = this.props[`${targetType}TotalProductsCount`]
    const list = this.props[`${targetType}ProductsList`]

    const hasKey = () => {
      return list[list.length - 1].key === 'SKUs'
        ? 'SKUs'
        : list[list.length - 1].key
    }

    return (
      <>
        {list && list.length > 0 && (
          <ProductChips
            targetType={targetType}
            showMore={(type) => this.handleModal(true, type)}
            list={list}
            disabled={this.props.disabled}
            viewMode={this.props.viewMode}
          />
        )}
        {list && list.length > 0 && (
          <div
            data-testid='view-added-products-count'
            className='products_link'
            onClick={() => {
              const type = list[list.length - 1].key ? hasKey() : 'SKUs'
              this.handleModal(true, type)
            }}
          >
            View added products({totalProductsCount})
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  let typeState = {}
  const types = ['targetX', 'targetY', state.promoReducer.targetType]
  types.forEach((type) => {
    typeState = {
      ...typeState,
      [`${type}RadioId`]: getRadioId(state, type),
      [`${type}SelectedSkuList`]: getSelectedSkus(state, type),
      [`${type}FinalSelectedSkusTree`]: getSelectedSkus(state, type),
      [`${type}CheckedCategories`]: getCheckedCategories(state, type),
      [`${type}ProductsList`]: getProducts(state, type),
      [`${type}TotalProductsCount`]: getAllProductsCount(state, type),
    }
  })
  return {
    promotion: getPromotion(state),
    targetType: getTargetType(state),
    ...typeState,
  }
}

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      bulkSkuCheck: BULK_SKU_CHECK,
      onAddProducts: ON_EXCLUDE_INCLUDE_UPDATE_COUNT,
      resetProducts: RESET_PRODUCTS,
      setTargetType: SET_TARGET_TYPE,
      updateAdvancedValues: UPDATE_ADVANCED_SKUS,
      updateCount: UPDATE_COUNT,
    },
    dispatch
  )
)(PromoProductSelection)
