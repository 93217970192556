import difference from 'lodash/difference'

/** 
 * default state
 * {
      attribute: []
   }
 * 
 * */
const onCheckAttributes = ({
  selectedAttributes,
  attribute,
  value,
  isValue = false,
}) => {
  //checking and unchecking child
  if (isValue) {
    if (selectedAttributes[attribute.id]) {
      // unchecking
      const isPresent = selectedAttributes[attribute.id].values.includes(value)
      if (isPresent) {
        const presentValues = selectedAttributes[attribute.id].values
        const updatedValues = difference(presentValues, [value])
        selectedAttributes[attribute.id] = {
          name: attribute.name,
          values: updatedValues,
        }
        if (updatedValues.length === 0) {
          delete selectedAttributes[attribute.id]
        }
        return selectedAttributes
      }
      //checking
      let values = selectedAttributes[attribute.id].values
      selectedAttributes[attribute.id] = {
        name: attribute.name,
        values: values.concat(value),
      }
      return selectedAttributes
    }
    selectedAttributes[attribute.id] = {
      name: attribute.name,
      values: [value],
    }
    return selectedAttributes
  }

  //unchecking parent
  if (selectedAttributes[attribute.id]) {
    delete selectedAttributes[attribute.id]
    return selectedAttributes
  }
  return {
    ...selectedAttributes,
    [attribute.id]: {
      name: attribute.name,
      values: attribute.value,
    },
  }
}

const getSelectedAttributes = ({ attributes }) => {
  let selectedAttributes = []
  for (const attribute in attributes) {
    const selectedAttribute = attributes[attribute]
    let selectedValues = selectedAttribute.values.map((value) => {
      return {
        id:
          value === true || value === false
            ? `${selectedAttribute.name}/${value}`
            : value,
        title: `${selectedAttribute.name}/${value}`,
        value: value,
        parent: attribute,
      }
    })
    selectedAttributes = selectedAttributes.concat(selectedValues)
  }
  return selectedAttributes
}

export { onCheckAttributes, getSelectedAttributes }
