"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isFeatureActive = exports.unleashClient = exports.config = void 0;
var unleash_proxy_client_1 = require("unleash-proxy-client");
var constants_1 = require("src/lib/data/constants");
var proxyEnv = process.env.SLS_STAGE || 'dev02';
exports.config = {
    url: "https://".concat(constants_1.featureFlagDomainMapping[proxyEnv], ".foundation.feature.fabric.inc/proxy"),
    clientKey: process.env.FEATURE_FLAG_CLIENT_KEY || 'copilot-foundations-dev',
    appName: 'offers',
    refreshInterval: 120,
    environment: constants_1.featureFlagEnvMapping[proxyEnv],
};
var client = new unleash_proxy_client_1.UnleashClient(exports.config);
exports.unleashClient = client;
var getTestingFeature = function (_a) {
    var flagName = _a.flagName;
    if (localStorage.getItem('newProductSelection') === 'true') {
        return constants_1.featuresWithNewProductSelection[flagName];
    }
    return constants_1.allDetailedFeatures[flagName];
};
var isFeatureActive = function (_a) {
    var flagName = _a.flagName, _b = _a.setFlag, setFlag = _b === void 0 ? null : _b;
    if (localStorage.getItem('environment') === 'testing') {
        if (!localStorage.getItem('pimConnectorFeatureFlag')) {
            return false;
        }
        else {
            return getTestingFeature({ flagName: flagName });
        }
    }
    var flag = client.isEnabled(flagName);
    // For local environment - uncomment below line if you want to verify the FF state
    // isLocal && console.log(`${flagName} - ${flag}`)
    if (setFlag) {
        setFlag(flag);
    }
    return flag;
};
exports.isFeatureActive = isFeatureActive;
