import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Modal, Navigation, Button } from '@teamfabric/copilot-ui'
import Skus from './sku'
import Categories from './categories'
import Attributes from './attributes'
import Collections from './collections'
import { StyledCriteria, StyledSkuSelection, StyledActionBox } from './styles'
import {
  getTempSelectedSkusCount,
  getPromoType,
  getTempSelectedAttributesTree,
  getAttributeItemsDetails,
  getProducts,
  getCategoriesSkusCount,
  getCheckedCategories,
  getSelectedSkus,
  getCompleteState,
  getIsFetchingItems,
  isBuyGetSelected,
} from 'modules/promo/selectors'
import {
  ON_EXCLUDE_INCLUDE_UPDATE_COUNT,
  ON_CANCEL,
  GET_ATTRIBUTE_ITEMS,
} from 'modules/promo/actions'
import { BROWSE_SKUs_TABS } from 'src/modules/promo/constants'
import { isFeatureActive } from 'src/api/featureFlag'
import { FEATURE_FLAGS } from 'src/general-config'

class ProductsModal extends Component {
  state = {
    selectedCriteria: BROWSE_SKUs_TABS.Categories,
    targetType: this.props.targetType || 'targetX',
    links: [
      {
        id: 2,
        label: BROWSE_SKUs_TABS.Categories,
        active: true,
      },
      ...(isFeatureActive({ flagName: FEATURE_FLAGS.ALTERNATE_HIERARCHY })
        ? [
            {
              id: 3,
              label: BROWSE_SKUs_TABS.Collections,
              active: false,
            },
          ]
        : []),
      {
        id: 4,
        label: BROWSE_SKUs_TABS.Attributes,
        active: false,
      },
      {
        id: 1,
        label: BROWSE_SKUs_TABS.SKUs,
        active: false,
      },
    ],
  }

  clickHandler = (e) => {
    e.preventDefault()
    this.setState({ selectedCriteria: e.target.textContent.trim() })
    this.setActiveTab(e.target.textContent)
  }

  setActiveTab = (criteria) => {
    let updatedLinks = [...this.state.links]

    for (let index = 0; index < updatedLinks.length; index++) {
      if (updatedLinks[index].label === criteria.trim()) {
        updatedLinks[index].active = true
      } else {
        updatedLinks[index].active = false
      }
    }
    this.setState({ updatedLinks })
  }

  render() {
    const { targetType } = this.state
    return (
      <Modal
        footerButtons={[]}
        headerButtons={[]}
        onBackdropClick={() => {
          this.props.onCancel({ targetType })
          this.props.onDismiss()
        }}
        padding='0px'
        render={() => this.renderSkuSelectionViaCriteria()}
        headerText=''
        size='large'
      />
    )
  }

  renderSkuSelectionViaCriteria = () => {
    return <StyledSkuSelection>{this.renderContent()}</StyledSkuSelection>
  }

  renderHeader = () => {
    const { targetType } = this.state
    return (
      <div className='header' data-testid='modal-header'>
        {targetType === 'exclusion' ? 'Exclude SKUs globally' : `Select SKUs`}
      </div>
    )
  }
  renderContent = () => {
    const { selectedCriteria, targetType } = this.state
    const { viewMode } = this.props
    return (
      <div className='selection_content' data-testid='selection-listing'>
        {this.renderCriteriaSelection()}
        <Skus
          selectedCriteria={selectedCriteria}
          fetchAttributeItems={this.props.getAttributesItems}
          targetType={targetType}
          renderFooter={this.renderFooter}
          viewMode={viewMode}
        />
      </div>
    )
  }

  renderCriteriaSelection = () => {
    return (
      <StyledCriteria>
        {this.renderHeader()}
        {this.renderCriteriaSelectionNavigation()}
        {this.renderSelectedCriteria()}
      </StyledCriteria>
    )
  }

  renderCriteriaSelectionNavigation = () => {
    return (
      <div>
        <Navigation
          data-testid='select-container-criteria'
          className='secondary'
          links={this.state.links.filter((item) => {
            if (!this.props.isBuyGetActive) {
              return true
            } else {
              return item.label !== BROWSE_SKUs_TABS.Attributes
            }
          })}
          onClick={(e) => this.clickHandler(e)}
          orientation='horizontal'
        />
      </div>
    )
  }

  renderSelectedCriteria = () => {
    const { selectedCriteria, targetType } = this.state
    switch (selectedCriteria) {
      case 'Categories':
        return (
          <Categories
            isListingModal
            onClose={this.props.onDismiss}
            type={this.props.type}
            targetType={targetType}
            renderFooter={this.renderFooter}
            viewMode={this.props.viewMode}
          />
        )
      case 'Collections':
        return (
          <Collections
            isListingModal
            onClose={this.props.onDismiss}
            type={this.props.type}
            targetType={targetType}
            renderFooter={this.renderFooter}
            viewMode={this.props.viewMode}
          />
        )
      case 'Attributes':
        return !this.props?.isBuyGetActive ? (
          <Attributes
            onClose={this.props.onDismiss}
            fetchItems={this.props.getAttributesItems}
            targetType={targetType}
            renderFooter={this.renderFooter}
          />
        ) : null
      default:
        return null
    }
  }

  renderFooter = () => {
    const { onDismiss, onCancel, isLoading } = this.props
    const { selectedCriteria, targetType } = this.state
    const isCoupon = window.location.pathname.includes('coupon')
    return (
      <StyledActionBox>
        <div className='space_between' data-testid='modal-footer'>
          <p className='items_selected_heading'>
            {targetType !== 'exclusion' &&
              `Add ${
                selectedCriteria.trim() === 'Categories'
                  ? 'Category'
                  : selectedCriteria
              } to the ${isCoupon ? 'coupon' : 'promotion'}`}
          </p>
          <div className='right_actions'>
            <Button
              onClick={() => {
                onDismiss()
                onCancel(targetType)
              }}
              color='text'
              variant='block'
              size='small'
              text='Cancel'
              isPrimary={false}
            />
            <Button
              variant='block'
              disabled={isLoading}
              onClick={() => {
                this.props.onAddProducts(targetType)
                onDismiss()
              }}
              isPrimary={true}
              id='items'
              data-testid={`add-items`}
              text='Save'
              size='small'
            />
          </div>
        </div>
      </StyledActionBox>
    )
  }
}

const mapStateToProps = (state) => {
  let typeState = {}
  const types = [
    'targetX',
    'targetY',
    'exclusion',
    state.promoReducer.targetType,
  ]
  types.forEach((type) => {
    typeState = {
      ...typeState,
      [`${type}PromoType`]: getPromoType(state, type),
      [`${type}SelectedSkuList`]: getSelectedSkus(state, type),
      [`${type}SelectedAttributesTree`]: getTempSelectedAttributesTree(
        state,
        type
      ),
      [`${type}AttributeItemsDetails`]: getAttributeItemsDetails(state, type),
      [`${type}ProductsList`]: getProducts(state, type),
      [`${type}CategoriesSkusCount`]: getCategoriesSkusCount(state, type),
      [`${type}CheckedCategories`]: getCheckedCategories(state, type),
      [`${type}TempSelectedSkusCount`]: getTempSelectedSkusCount(state, type),
    }
  })
  return {
    promoState: getCompleteState(state),
    isLoading: getIsFetchingItems(state),
    isBuyGetActive: isBuyGetSelected(state),
    ...typeState,
  }
}

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      onAddProducts: ON_EXCLUDE_INCLUDE_UPDATE_COUNT,
      onCancel: ON_CANCEL,
      getAttributesItems: GET_ATTRIBUTE_ITEMS,
    },
    dispatch
  )
)(ProductsModal)
