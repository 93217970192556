import { PRODUCT_TARGET_OPTIONS } from '../form/components/products-selection-v2/constants'
import { createAttributeTree } from './helper'
import { getSelectedSkus } from 'src/modules/promo/helper/sku'
import { exclusionConditionMapping } from '../utils'

const getIncludeExcludeProductsList = ({ products, targetRules }) => {
  const createdProducts = []
  const catgeoryRules = getIncludeExcludeCategories({
    rules: targetRules,
    categories: products?.categories,
    type: 'category',
  })
  const collectionRules = getIncludeExcludeCategories({
    rules: targetRules,
    categories: products?.collections,
    type: 'collection',
  })
  const attributeRules = getIncludeExcludeAttributes(
    targetRules,
    products?.attributes
  )
  const skuRules = getIncludeExcludeSkus(targetRules, products?.skus)

  if (skuRules?.length) {
    createdProducts.push({
      key: 'SKUs',
      values: skuRules.map((sku) => {
        return {
          ...sku,
          sku: sku?.productSku,
        }
      }),
      count: skuRules.length,
    })
  }
  if (catgeoryRules?.length) {
    createdProducts.push({
      key: 'Categories',
      values: catgeoryRules,
      count: catgeoryRules.length,
    })
  }
  if (collectionRules?.length) {
    createdProducts.push({
      key: 'Collections',
      values: collectionRules,
      count: collectionRules.length,
    })
  }
  if (attributeRules?.length) {
    createdProducts.push({
      key: 'Attributes',
      values: attributeRules,
      count: attributeRules.length,
    })
  }

  return createdProducts
}

const getSelectedIncludeCategories = (targetProducts, kind, isBuySku) => {
  let includedCategories
  const targetKey = isBuySku ? 'key' : 'kind'
  targetProducts?.forEach((target) => {
    if (target[targetKey].toUpperCase() === kind && target.operator === 'IN') {
      includedCategories = target
    }
  })
  return includedCategories
}

const getSelectedExcludeCategories = (targetProducts, kind, isBuySku) => {
  let includedCategories
  const targetKey = isBuySku ? 'key' : 'kind'
  targetProducts?.forEach((target) => {
    if (
      target[targetKey].toUpperCase() === kind &&
      target.operator === 'NOT_IN'
    ) {
      includedCategories = target
    }
  })
  return includedCategories
}

const KINDS = ['CATEGORY', 'SKU', 'COLLECTION', 'ATTRIBUTE']

const getAllIncludeRules = (targetProducts, isBuySku) => {
  let includedRules = []
  const targetKey = isBuySku ? 'key' : 'kind'
  targetProducts?.forEach((target) => {
    if (KINDS.includes(target[targetKey]) && target.operator === 'IN') {
      if (target.value === '*') {
        includedRules.push({ ...PRODUCT_TARGET_OPTIONS()[0] })
      } else {
        const condition = PRODUCT_TARGET_OPTIONS().find((option) => {
          return option.id.toUpperCase() === target[targetKey]
        })

        if (condition) {
          includedRules.push({ ...condition, values: target.value })
        }
      }
    }
  })
  return includedRules
}

const isBooleanAttribute = (attrValue) =>
  attrValue === 'true' || attrValue === 'false'

const getBooleanValue = (attrValue) => (attrValue === 'true' ? true : false)

const getIncludeExcludeAttributes = (rules, attributes = []) => {
  const selectedRuleAttribute = rules.find((rule) => rule.id === 'attribute')
  return attributes
    .filter((attr) =>
      selectedRuleAttribute?.values.find((option) => {
        return (
          String(option?.value) === String(attr?.id) &&
          String(option?.name) === String(attr?.parent)
        )
      })
    )
    .map((attr) => {
      return {
        ...attr,
        id: isBooleanAttribute(attr.id) ? attr.title : attr.id,
        value: isBooleanAttribute(attr.id) ? getBooleanValue(attr.id) : attr.id,
      }
    })
}

const getIncludeExcludeCategories = ({ rules, categories = [], type }) => {
  const selectedRuleCatgories = rules.find((rule) => rule.id === type)
  return categories.filter((option) =>
    selectedRuleCatgories?.values.includes(option.id)
  )
}

const getIncludeExcludeSkus = (rules, skus = []) => {
  const selectedRuleSkus = rules.find((rule) => rule.id === 'SKU')
  //Not checking merchant identifier sku flag here since
  //this function is used inside a reducer and reducers must be pure
  return skus.filter(
    (option) =>
      selectedRuleSkus?.values.includes(option.productSku) ||
      selectedRuleSkus?.values.includes(option.itemId)
  )
}

const createIncludeExcludeSKUsTree = ({ values, rules }) => {
  const tree = {}
  const SkusList = rules.find((rule) => rule.id === 'SKU')
  values?.forEach((value) => {
    //Not checking merchant identifier sku flag here since
    //this function is used inside a reducer and reducers must be pure
    if (
      SkusList?.values.includes(value.productSku) ||
      SkusList?.values.includes(value.itemId)
    ) {
      tree[value?.productId] = {
        ...value,
        sku: value?.productSku,
        parent: value?.parentSku,
        _id: value?.productId,
        images: [],
      }
    }
  })
  return tree
}

const getIncludeCategoriesCount = ({
  categories,
  selectedCategories,
  type,
}) => {
  let count = 0
  if (selectedCategories?.values) {
    selectedCategories.values.forEach((category) => {
      categories.forEach((cat) => {
        if (cat.id === category) {
          count +=
            type === 'category' ? cat.categorySkuCount : cat.collectionSkuCount
        }
      })
    })
  }
  return count
}

const getAttributesDetailsCount = ({
  attributes,
  attributeItemDetails,
  targetType = 'targetX',
}) => {
  let attributesItemDetails = {
    currentPage: 0,
    hasMore: true,
    maxPage: 1,
    searchParameter: '',
  }
  let totalProducts = 0
  let items = []
  attributes.forEach((value) => {
    if (targetType === 'targetX' || targetType === 'spend') {
      attributeItemDetails?.forEach((details) => {
        if (
          value.value === details?.attribute?.value ||
          (isBooleanAttribute(details?.attribute?.value) &&
            `${details?.attribute?.key}/${details?.attribute?.value}` ===
              value.id)
        ) {
          items = items.concat(details?.items)
          totalProducts = totalProducts + details?.itemCount
        }
      })
    }
    attributesItemDetails.items = items
    attributesItemDetails.totalProducts = totalProducts
  })
  return attributesItemDetails
}

const getAllExcludeRules = (targetProducts, isBuySku) => {
  let excludedRules = []
  const targetKey = isBuySku ? 'key' : 'kind'
  targetProducts?.forEach((target) => {
    if (KINDS.includes(target[targetKey]) && target.operator === 'NOT_IN') {
      const condition = PRODUCT_TARGET_OPTIONS().find(
        (option) => option.id.toUpperCase() === target[targetKey]
      )
      if (condition) {
        excludedRules.push({ ...condition, values: target.value })
      }
    }
  })
  return excludedRules
}

const createExcludeCategoryTreeV2 = ({
  targetProducts,
  values,
  conditions,
  targetType = 'targetX',
  type,
}) => {
  const isBuySpendCatgeory =
    targetType.includes('set') || targetType === 'spend'
  const excludedCatgeories = isBuySpendCatgeory
    ? getSelectedExcludeCategories(conditions, type, true)
    : getSelectedExcludeCategories(targetProducts, type)
  let tree = {}
  const productKey = type === 'CATEGORY' ? 'Categories' : 'Collections'
  const selectedValues = values.find((value) => value.key === productKey)
  if (excludedCatgeories?.value?.length) {
    selectedValues?.values?.forEach((value) => {
      let { title } = value
      const categoryMap =
        type === 'CATEGORY' ? value?.categoryMap : value?.collectionMap || {}
      const categoryPath = categoryMap[title?.split('/')[0]]
      if (!tree[categoryPath]) {
        tree[categoryPath] = getCategoryBranch({
          value,
          title,
          type,
          categories: excludedCatgeories,
        })
      } else {
        getCategoryBranch({
          value,
          title,
          catgeoryBranch: tree[categoryPath],
          type,
          categories: excludedCatgeories,
        })
      }
    })
  }
  return tree
}

const createIncludeCategoryTreeV2 = ({
  targetProducts,
  values,
  conditions,
  targetType = 'targetX',
  type,
}) => {
  const isBuySpendCatgeory =
    targetType.includes('set') || targetType === 'spend'
  const includedCatgeories = isBuySpendCatgeory
    ? getSelectedIncludeCategories(conditions, type, true)
    : getSelectedIncludeCategories(targetProducts, type)
  let tree = {}
  const productKey = type === 'CATEGORY' ? 'Categories' : 'Collections'
  const selectedValues = values.find((value) => value.key === productKey)
  if (includedCatgeories?.value?.length) {
    selectedValues?.values?.forEach((value) => {
      let { title } = value
      const categoryTitle = title
      const categoryMap =
        type === 'CATEGORY' ? value?.categoryMap : value?.collectionMap || {}
      const categoryPath = categoryMap[categoryTitle?.split('/')[0]]
      if (!tree[categoryPath]) {
        tree[categoryPath] = getCategoryBranch({
          value,
          title,
          type,
          categories: includedCatgeories,
          pathTitle: title,
        })
      } else {
        getCategoryBranch({
          value,
          title,
          catgeoryBranch: tree[categoryPath],
          type,
          categories: includedCatgeories,
          pathTitle: title,
        })
      }
    })
  }
  return tree
}

const getItemPath = ({ items, itemTitle, getParent, categoryMap = {} }) => {
  let path = ''
  for (let i = 0; i <= items.length; i++) {
    if (items[i] === itemTitle) {
      path += getParent ? `${categoryMap[items[i]]}.` : `${items[i]}/`
      break
    } else {
      path += getParent ? `${categoryMap[items[i]]}.` : `${items[i]}/`
    }
  }
  return path.substring(0, path.length - 1)
}

const getCategoryBranch = ({
  title,
  value,
  catgeoryBranch,
  type,
  categories,
  pathTitle,
}) => {
  let hasChild = title?.includes('/')
  const categoryTitle = title
  let parentTitle = hasChild ? categoryTitle.split('/')[0] : title
  const categoryMap =
    type === 'CATEGORY' ? value?.categoryMap : value?.collectionMap || {}
  let isPartialChecked = hasChild && value?.id !== categoryMap[parentTitle]
  let branch = catgeoryBranch
    ? catgeoryBranch
    : {
        _id: categoryMap[parentTitle],
        name: parentTitle,
        parentId: '',
        firstParent: '',
        isChild: true,
        count: 0,
        totalCount: 0,
        path: pathTitle
          ? getItemPath({
              items: pathTitle.split('/'),
              itemTitle: parentTitle,
              categoryMap,
            })
          : '',
        isPartialChecked,
        isChecked: categories?.value?.includes(categoryMap[parentTitle]),
      }

  if (hasChild) {
    let childTitle = categoryTitle.replace(`${parentTitle}/`, '')
    const childBranch = categoryMap[categoryTitle.split('/')[1]]
    if (!branch[childBranch]) {
      branch[childBranch] = getCategoryBranch({
        title: childTitle,
        value,
        type,
        categories,
        pathTitle: pathTitle,
      })
    } else {
      getCategoryBranch({
        title: childTitle,
        value,
        catgeoryBranch: branch[childBranch],
        type,
        categories,
        pathTitle: pathTitle,
      })
    }
  }
  return branch
}

export const getProductsDataForOtherTargetTypeV2 = ({
  promotion,
  targetType,
}) => {
  let {
    extra,
    extra: { products },
    promo,
  } = promotion
  let targetProducts = promo[0].targetProducts
  const isBuyTarget = targetType.includes('set') || targetType === 'spend'

  const condition = promo[0]?.condition.find(
    (option) => targetType.includes(option?.set) || targetType.includes('spend')
  )
  const conditions = condition ? condition.conditions : []
  if (targetType.includes('discount')) {
    targetProducts = targetProducts?.filter((targetProduct) => {
      if (
        promotion.isBuyGetV2Enabled &&
        extra.selectedPromoTypeRadio?.toLowerCase() === 'buy-get'
      ) {
        let newTargetType = targetType.replace('discount', '')
        return String(newTargetType) === String(targetProduct?.discountId)
      }
      //buygetv2 => buyget compatibility code
      if (
        targetProduct?.discountId?.includes('-') &&
        extra.selectedPromoTypeRadio?.toLowerCase() === 'buy-get'
      ) {
        let newDiscountId = targetProduct?.discountId.split('-')[1]
        return targetType.includes(newDiscountId)
      }
      return targetType.includes(targetProduct?.discountId)
    })
  }
  const includeRules =
    targetType.includes('set') || targetType === 'spend'
      ? getAllIncludeRules(conditions, true)
      : getAllIncludeRules(targetProducts)
  const excludeRules =
    targetType.includes('set') || targetType === 'spend'
      ? getAllExcludeRules(conditions, true)
      : getAllExcludeRules(targetProducts)

  const includedCategories = includeRules.find((rule) => rule.id === 'category')
  const excludedCategories = excludeRules.find((rule) => rule.id === 'category')
  const includedCollections = includeRules.find(
    (rule) => rule.id === 'collection'
  )
  const excludedCollections = excludeRules.find(
    (rule) => rule.id === 'collection'
  )
  const includeCategoryCount = getIncludeCategoriesCount({
    categories: products?.categories,
    selectedCategories: includedCategories,
    type: 'category',
  })
  const excludeCategoryCount = getIncludeCategoriesCount({
    categories: products?.categories,
    selectedCategories: excludedCategories,
    type: 'category',
  })
  const includeCollectionCount = getIncludeCategoriesCount({
    categories: products?.collections,
    selectedCategories: includedCollections,
  })
  const excludeCollectionCount = getIncludeCategoriesCount({
    categories: products?.collections,
    selectedCategories: excludedCollections,
  })
  const includeSkuTree = createIncludeExcludeSKUsTree({
    rules: includeRules,
    promotion,
    values: products?.skus,
    targetType,
  })
  const excludeSkuTree = createIncludeExcludeSKUsTree({
    rules: excludeRules,
    promotion,
    values: products?.skus,
    targetType,
  })
  const includeOperator = isBuyTarget
    ? condition.includeOperator
    : promo[0].discount[0]?.ON?.includeOperator
  const excludeOperator = isBuyTarget
    ? exclusionConditionMapping[condition.excludeOperator]
    : exclusionConditionMapping[promo[0]?.discount[0]?.ON?.excludeOperator]
  const includeProducts = getIncludeExcludeProductsList({
    promotion,
    products,
    targetRules: includeRules,
    targetType,
  })
  const excludeProducts = getIncludeExcludeProductsList({
    promotion,
    products,
    targetRules: excludeRules,
    targetType,
  })
  const includeAttributes = getIncludeExcludeAttributes(
    includeRules,
    products?.attributes
  )
  const excludeAttributes = getIncludeExcludeAttributes(
    excludeRules,
    products?.attributes
  )
  const includeAttributesItemDetails = getAttributesDetailsCount({
    attributes: includeAttributes,
    attributeItemDetails: extra?.attributesItemDetails,
  })
  const excludeAttributesItemDetails = getAttributesDetailsCount({
    attributes: excludeAttributes,
    attributeItemDetails: extra?.attributesItemDetails,
  })

  return {
    selectedIncludeRules: includeRules,
    selectedExcludeRules: excludeRules,
    includeProducts: includeProducts,
    excludeProducts: excludeProducts,
    selectedIncludeCategories: createIncludeCategoryTreeV2({
      targetProducts,
      conditions,
      targetType,
      values: includeProducts,
      type: 'CATEGORY',
    }),
    selectedExcludeCategories: createExcludeCategoryTreeV2({
      targetProducts,
      conditions,
      targetType,
      values: excludeProducts,
      type: 'CATEGORY',
    }),
    selectedIncludeCollections: createIncludeCategoryTreeV2({
      targetProducts,
      conditions,
      targetType,
      values: includeProducts,
      type: 'COLLECTION',
    }),
    selectedExcludeCollections: createExcludeCategoryTreeV2({
      targetProducts,
      conditions,
      targetType,
      values: excludeProducts,
      type: 'COLLECTION',
    }),
    selectedIncludeSkus: includeSkuTree,
    selectedExcludeSkus: excludeSkuTree,
    includeOperator: includeOperator || 'AND',
    excludeOperator: excludeOperator || 'AND',
    includeCategoriesSkusCount: includeCategoryCount,
    excludeCategoriesSkusCount: excludeCategoryCount,
    includeCollectionsSkusCount: includeCollectionCount,
    excludeCollectionsSkusCount: excludeCollectionCount,
    includeSkuCount: Object.keys(includeSkuTree).length,
    excludeSkuCount: Object.keys(excludeSkuTree).length,
    includeAttributesItemDetails: includeAttributesItemDetails,
    excludeAttributesItemDetails: excludeAttributesItemDetails,
    includeAttributesSkuCount: includeAttributesItemDetails?.totalProducts || 0,
    excludeAttributesSkuCount: excludeAttributesItemDetails?.totalProducts || 0,
    selectedIncludeAttributes: includeAttributes,
    selectedExcludeAttributes: excludeAttributes,
    selectedIncludeAttributesTree: createAttributeTree({
      attributes: includeAttributes,
    }),
    selectedExcludeAttributesTree: createAttributeTree({
      attributes: excludeAttributes,
    }),
  }
}

export const getProductsDataWithTargetXTypeV2 = ({ promotion }) => {
  let {
    extra,
    extra: { products },
    promo,
  } = promotion
  const targetProducts = promo?.[0]?.targetProducts
  const includeRules = getAllIncludeRules(targetProducts)
  const excludeRules = getAllExcludeRules(targetProducts)
  const includeProductList = getIncludeExcludeProductsList({
    products,
    targetRules: includeRules,
  })
  const excludeProductList = getIncludeExcludeProductsList({
    products,
    targetRules: excludeRules,
  })
  const includeAttributes = getIncludeExcludeAttributes(
    includeRules,
    products?.attributes
  )
  const excludeAttributes = getIncludeExcludeAttributes(
    excludeRules,
    products?.attributes
  )
  const includedCategories = includeRules.find((rule) => rule.id === 'category')
  const excludedCategories = excludeRules.find((rule) => rule.id === 'category')
  const includedCollections = includeRules.find(
    (rule) => rule.id === 'collection'
  )
  const excludedCollections = excludeRules.find(
    (rule) => rule.id === 'collection'
  )
  const includeCategoryCount = getIncludeCategoriesCount({
    categories: products?.categories,
    selectedCategories: includedCategories,
    type: 'category',
  })
  const excludeCategoryCount = getIncludeCategoriesCount({
    categories: products?.categories,
    selectedCategories: excludedCategories,
    type: 'category',
  })
  const includeCollectionCount = getIncludeCategoriesCount({
    categories: products?.collections,
    selectedCategories: includedCollections,
  })
  const excludeCollectionCount = getIncludeCategoriesCount({
    categories: products?.collections,
    selectedCategories: excludedCollections,
  })
  const includeAttributesItemDetails = getAttributesDetailsCount({
    attributes: includeAttributes,
    attributeItemDetails: extra?.attributesItemDetails,
  })
  const excludeAttributesItemDetails = getAttributesDetailsCount({
    attributes: excludeAttributes,
    attributeItemDetails: extra?.attributesItemDetails,
  })
  const includeSkuTree = createIncludeExcludeSKUsTree({
    rules: includeRules,
    promotion,
    values: products?.skus,
  })
  const excludeSkuTree = createIncludeExcludeSKUsTree({
    rules: excludeRules,
    promotion,
    values: products?.skus,
  })
  return {
    targetX: {
      includeAttributesItemDetails: includeAttributesItemDetails,
      excludeAttributesItemDetails: excludeAttributesItemDetails,
      includeAttributesSkuCount:
        includeAttributesItemDetails?.totalProducts || 0,
      excludeAttributesSkuCount:
        excludeAttributesItemDetails?.totalProducts || 0,
      includeOperator:
        promotion?.promo?.[0]?.discount?.[0]?.ON?.includeOperator || 'AND',
      excludeOperator:
        exclusionConditionMapping[
          promotion?.promo?.[0]?.discount?.[0]?.ON?.excludeOperator || 'AND'
        ],
      includeCategoriesSkusCount: includeCategoryCount,
      excludeCategoriesSkusCount: excludeCategoryCount,
      includeCollectionsSkusCount: includeCollectionCount,
      excludeCollectionsSkusCount: excludeCollectionCount,
      selectedIncludeRules: includeRules,
      selectedExcludeRules: excludeRules,
      selectedIncludeAttributes: includeAttributes,
      selectedExcludeAttributes: excludeAttributes,
      selectedIncludeAttributesTree: createAttributeTree({
        attributes: includeAttributes,
      }),
      selectedExcludeAttributesTree: createAttributeTree({
        attributes: excludeAttributes,
      }),
      selectedIncludeCategories: createIncludeCategoryTreeV2({
        targetProducts,
        values: includeProductList,
        type: 'CATEGORY',
      }),
      selectedExcludeCategories: createExcludeCategoryTreeV2({
        targetProducts,
        values: excludeProductList,
        type: 'CATEGORY',
      }),
      selectedIncludeCollections: createIncludeCategoryTreeV2({
        targetProducts,
        values: includeProductList,
        type: 'COLLECTION',
      }),
      selectedExcludeCollections: createExcludeCategoryTreeV2({
        targetProducts,
        values: excludeProductList,
        type: 'COLLECTION',
      }),
      selectedIncludeSkus: includeSkuTree,
      selectedExcludeSkus: excludeSkuTree,
      includeSkuCount: Object.keys(includeSkuTree).length,
      excludeSkuCount: Object.keys(excludeSkuTree).length,
      includeProducts: includeProductList,
      excludeProducts: excludeProductList,
    },
  }
}

export const setTargetXInitialStateV2 = ({ extra, type }) => {
  let updatedState = {}
  const {
    target: { types },
  } = extra
  const {
    selectedIncludeSkus = {},
    selectedExcludeSkus = {},
    selectedIncludeCategories = {},
    selectedExcludeCategories = {},
    selectedIncludeRules = [],
    selectedExcludeRules = [],
    selectedIncludeAttributesTree = {},
    selectedExcludeAttributesTree = {},
    selectedIncludeAttributes = [],
    selectedExcludeAttributes = [],
    includeCategoriesSkusCount = 0,
    excludeCategoriesSkusCount = 0,
    includeOperator,
    excludeOperator,
    includeProducts = [],
    excludeProducts = [],
    includeAttributesItemDetails = {},
    excludeAttributesItemDetails = {},
    includeCollectionsSkusCount = 0,
    excludeCollectionsSkusCount = 0,
    selectedIncludeCollections = {},
    selectedExcludeCollections = {},
    includeAttributesSkuCount = 0,
    excludeAttributesSkuCount = 0,
    includeSkuCount = 0,
    excludeSkuCount = 0,
  } = types[type]
  let includeRules = [...selectedIncludeRules]
  let excludeRules = [...selectedExcludeRules]
  updatedState = {
    ...updatedState,
    [`${type}IncludeSelectedRules`]: [...includeRules],
    [`${type}ExcludeSelectedRules`]: [...excludeRules],
    [`${type}IncludeTempSelectedSkusTree`]: { ...selectedIncludeSkus },
    [`${type}IncludeFinalSelectedSkusTree`]: { ...selectedIncludeSkus },
    [`${type}ExcludeTempSelectedSkusTree`]: { ...selectedExcludeSkus },
    [`${type}ExcludeFinalSelectedSkusTree`]: { ...selectedExcludeSkus },
    [`${type}IncludeTempSelectedCategories`]: { ...selectedIncludeCategories },
    [`${type}IncludeFinalSelectedCategories`]: { ...selectedIncludeCategories },
    [`${type}ExcludeTempSelectedCategories`]: { ...selectedExcludeCategories },
    [`${type}ExcludeFinalSelectedCategories`]: { ...selectedExcludeCategories },
    [`${type}IncludeTempSelectedCollections`]: {
      ...selectedIncludeCollections,
    },
    [`${type}IncludeFinalSelectedCollections`]: {
      ...selectedIncludeCollections,
    },
    [`${type}ExcludeTempSelectedCollections`]: {
      ...selectedExcludeCollections,
    },
    [`${type}ExcludeFinalSelectedCollections`]: {
      ...selectedExcludeCollections,
    },
    [`${type}IncludeTempSelectedAttributesTree`]: {
      ...selectedIncludeAttributesTree,
    },
    [`${type}IncludeFinalSelectedAttributesTree`]: {
      ...selectedIncludeAttributesTree,
    },
    [`${type}ExcludeTempSelectedAttributesTree`]: {
      ...selectedExcludeAttributesTree,
    },
    [`${type}ExcludeFinalSelectedAttributesTree`]: {
      ...selectedExcludeAttributesTree,
    },
    [`${type}IncludeTempCategoriesSkusCount`]: includeCategoriesSkusCount,
    [`${type}IncludeFinalCategoriesSkusCount`]: includeCategoriesSkusCount,
    [`${type}ExcludeTempCategoriesSkusCount`]: excludeCategoriesSkusCount,
    [`${type}ExcludeFinalCategoriesSkusCount`]: excludeCategoriesSkusCount,
    [`${type}IncludeTempCollectionsSkusCount`]: includeCollectionsSkusCount,
    [`${type}IncludeFinalCollectionsSkusCount`]: includeCollectionsSkusCount,
    [`${type}ExcludeTempCollectionsSkusCount`]: excludeCollectionsSkusCount,
    [`${type}ExcludeFinalCollectionsSkusCount`]: excludeCollectionsSkusCount,
    [`${type}IncludeSelectedSkus`]: getSelectedSkus({
      skus: { ...selectedIncludeSkus },
    }),
    [`${type}ExcludeSelectedSkus`]: getSelectedSkus({
      skus: { ...selectedExcludeSkus },
    }),
    [`${type}IncludeFinalSelectedSkusCount`]: includeSkuCount,
    [`${type}ExcludeFinalSelectedSkusCount`]: excludeSkuCount,
    [`${type}IncludeTempSelectedSkusCount`]: includeSkuCount,
    [`${type}ExcludeTempSelectedSkusCount`]: excludeSkuCount,
    [`${type}IncludeSelectedAttributes`]: selectedIncludeAttributes,
    [`${type}ExcludeSelectedAttributes`]: selectedExcludeAttributes,
    [`${type}IncludeCondition`]: includeOperator,
    [`${type}ExcludeCondition`]: excludeOperator,
    [`${type}IncludeProducts`]: includeProducts,
    [`${type}ExcludeProducts`]: excludeProducts,
    [`${type}IncludeAttributeItemsDetails`]: includeAttributesItemDetails,
    [`${type}ExcludeAttributeItemsDetails`]: excludeAttributesItemDetails,
    [`${type}IncludeFinalAttributesSkusCount`]: includeAttributesSkuCount,
    [`${type}ExcludeFinalAttributesSkusCount`]: excludeAttributesSkuCount,
  }
  return updatedState
}
