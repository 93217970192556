import styled from 'styled-components'

export const StyledUploadWrapper = styled.div`
  .header {
    ${(props) => props.theme.typography.h4.css};
    display: flex;
    align-items: center;
    svg {
      margin-right: 18px;
    }
  }
  .dropzone {
    .loading {
      margin-bottom: 5px;
      margin-top: -5px;
    }
    align-items: center;
    section {
      margin-top: 30px;
      .button_wrapper {
        .loading {
          margin-bottom: 10px;
          margin-top: -10px;
        }
        display: flex;
        align-items: center;
        color: ${(props) => props.theme.palette.text.label};
        button {
          margin-right: 30px;
        }
      }
      .file_error {
        ${(props) => props.theme.typography.h5};
        color: ${(props) => props.theme.palette.error.main};
      }
      .file_name {
        word-break: break-all;
      }
    }
  }
  .template-guid {
    font-size: 13px;
  }
  .template_here {
    a {
      color: ${(props) => props.theme.palette.blue.main};
    }
  }
  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .cancel {
      margin-right: 10px;
      color: ${(props) => props.theme.palette.common.black};
    }
  }
`
