import React, { Component } from 'react'
import { isBefore, parseISO, isValid } from 'date-fns'
import withRouter from 'src/components/hoc/router-wrapper'
import { Table, Empty, Snackbar, theme } from '@teamfabric/copilot-ui'
import { HEADERS } from './headers'
import PageHeading from 'components/page-heading'
import { getItemById, getPriceListCurrency } from 'src/api/product'
import CreatePrice from '../product/create-price'
import { noImage } from '../../../lib/utils/helper'
import {
  StyledWrapper,
  StyledContent,
  StyledVariants,
  StyledImages,
} from './styles'
import {
  checkIfAnyPriceIsUpcomingOrActive,
  displayPriceAndRange,
} from 'lib/utils/helper'
import SkeletonLoader from 'components/skeleton-loader'
import {
  CREATE_FAILURE,
  EMPTY_VARIANT_DETAIL_HEADING,
  EMPTY_VARIANT_DETAIL_TEXT,
} from './constants'
import { decodeId } from 'src/lib/utils/helper'
import { isFeatureActive } from 'src/api/featureFlag'
import { FEATURE_FLAGS } from 'src/general-config'
import { hasPermission } from 'src/api/rolePermissions'
import { CREATE_PERMISSIONS } from 'src/lib/constants/constants'
import { OFFER_PRICE_PRODUCT_LIST_ROUTE } from 'src/lib/constants/page-links'
import RenderError from 'components/error'

const PaginationOnProductPrice = isFeatureActive({
  flagName: FEATURE_FLAGS?.PAGINATION_ON_PRODUCT_PRICE,
})

const RenderCreatePrice = ({
  priceListId,
  renderToaster,
  variant,
  fetchProduct,
  isLoading,
  setIsLoading,
  variantIsExpired,
  currency,
  anyUpcomingPrice,
}) => {
  const hasCreatePricePermission = hasPermission(
    CREATE_PERMISSIONS.CREATE_PRICES
  )
  if (!hasCreatePricePermission) {
    return null
  }
  return (
    <div>
      <div className='create-price' data-testid='add-price'>
        <div className='create_price_title'>Add SKU pricing</div>
        <CreatePrice
          priceListId={priceListId}
          isVariant={true}
          renderToaster={renderToaster}
          itemSku={[variant?.sku]}
          itemId={[variant.itemId]}
          fetchProduct={() =>
            fetchProduct({
              itemId: variant.itemId,
              priceListId: priceListId,
            })
          }
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          isExpired={variantIsExpired}
          currency={currency}
          promoDates={variant.promoDates}
          isPriceUpcomingOrActive={anyUpcomingPrice}
        />
      </div>
    </div>
  )
}
class VariantDetails extends Component {
  state = {
    variant: '',
    error: '',
    noProduct: false,
    isLoading: false,
    currency: '',
    showToaster: false,
    perPage: 10,
    activePage: 1,
  }

  componentDidMount = async () => {
    let { priceListId, variantId } = this.props.params
    if (variantId) {
      variantId = decodeId(variantId)
    }
    this.fetchProduct({ itemId: variantId, priceListId })
    const { data } = await getPriceListCurrency(priceListId)
    return this.setState({ currency: data.currency })
  }

  renderToaster = (type, message) => {
    this.setState({
      showToaster: true,
      message: message,
      type: type,
    })
  }

  onClose = () => {
    this.setState({ showToaster: false })
  }

  fetchProduct = async ({ itemId, priceListId, offset }) => {
    const { perPage } = this.state
    try {
      this.setState({ isLoading: true })
      const { data } = await getItemById({
        id: itemId,
        priceListId,
        limit: perPage,
        offset,
      })
      this.setState({ variant: data, isLoading: false })
    } catch (e) {
      if (e?.response?.data?.code) {
        this.setState({
          error: e.response.data.message,
          noProduct: true,
          isLoading: false,
        })
      }
    }
  }

  handlePaginationVariant = (pageIndex) => {
    let { priceListId, variantId } = this.props.params
    if (variantId) {
      variantId = decodeId(variantId)
    }
    const { perPage } = this.state
    const offset = (pageIndex - 1) * perPage
    this.setState({ activePage: pageIndex })
    this.fetchProduct({ itemId: variantId, priceListId, offset })
  }

  isExpired = (endDate) => {
    if (endDate) {
      const parsedDate = isValid(endDate) ? endDate : parseISO(endDate)
      return isBefore(parsedDate, new Date())
    } else {
      return ''
    }
  }

  renderSnackBar = () => {
    setTimeout(this.onClose, 6000)
    return (
      <div className='snack-bar'>
        <Snackbar
          onDismiss={this.onClose}
          label={this.state.message}
          dismissable
          kind={this.state.type}
          show
          textColor={theme.palette.brand.primary.white}
          width='600px'
        />
      </div>
    )
  }

  render() {
    const { isLoading } = this.state
    return (
      <StyledWrapper>
        {isLoading ? (
          <SkeletonLoader screen='details' type='price' />
        ) : (
          this.renderContent()
        )}
        {this.state.showToaster && this.renderSnackBar()}
      </StyledWrapper>
    )
  }

  setIsLoading = (isLoading) => {
    this.setState({ isLoading: isLoading })
  }
  renderContent() {
    const { variant, noProduct, error, currency } = this.state
    let { priceRange } = variant

    return (
      <StyledContent
        isError={this.state.error && Object.keys(this.state.error).length > 0}
      >
        <PageHeading
          handleClick={() => this.props.navigate(OFFER_PRICE_PRODUCT_LIST_ROUTE)}
          data-testid='header-back-button'
        >
          <p className='page-title uppercase'>ALL PRICES</p>
        </PageHeading>
        <div className='product_content'>
          {noProduct ? (
            <RenderError message={error} />
          ) : (
            <div>
              <div className='product-info'>
                <StyledImages>
                  {variant.images && variant.images.length > 0 ? (
                    variant.images.map((image, index) => {
                      return (
                        <img key={index} src={image.source[0].url || noImage} />
                      )
                    })
                  ) : (
                    <img src={noImage} />
                  )}
                </StyledImages>
                <div>
                  <div className='product-details'>
                    <div>
                      <p data-testid='variant-title' className='title'>
                        {' '}
                        {variant.title}{' '}
                      </p>
                      {/* current Price will reflect here */}
                    </div>
                    <div className='price-range'>
                      <p className='price-text'>
                        Price
                        <span className='price-value'>
                          {' '}
                          {displayPriceAndRange({ priceRange, currency })}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {this.renderPriceHistory(variant)}
            </div>
          )}
        </div>
      </StyledContent>
    )
  }

  renderPriceHistory(variant) {
    const { perPage, activePage, isLoading, currency } = this.state
    const { priceListId } = this.props.params
    let { price } = variant
    const offers = price?.offers || []
    const totalRecords = price?.query?.count
    const variantIsExpired = this.isExpired(variant.endDate)
    const anyUpcomingPrice = checkIfAnyPriceIsUpcomingOrActive(offers)

    return (
      <StyledVariants>
        <RenderCreatePrice
          priceListId={priceListId}
          renderToaster={this.renderToaster}
          variant={variant}
          fetchProduct={this.fetchProduct}
          isLoading={isLoading}
          setIsLoading={this.setIsLoading}
          variantIsExpired={variantIsExpired}
          currency={currency}
          anyUpcomingPrice={anyUpcomingPrice}
        />
        {variantIsExpired && <RenderError message={CREATE_FAILURE} />}
        {offers.length > 0 ? (
          <Table
            cellClassName='table-cell'
            rowClassName='variant'
            columns={HEADERS}
            data={offers}
            showPagination={PaginationOnProductPrice}
            totalRecords={totalRecords}
            activePageNumber={activePage}
            handlePagination={this.handlePaginationVariant}
            perPage={perPage}
            enableSort
          />
        ) : (
          <div className='no-price' data-testid='empty-list'>
            <Empty
              className='empty'
              markdown={true}
              primaryText={EMPTY_VARIANT_DETAIL_HEADING}
              secondaryText={EMPTY_VARIANT_DETAIL_TEXT}
            />
          </div>
        )}
      </StyledVariants>
    )
  }
}


export default withRouter(VariantDetails)
