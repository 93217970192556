import styled from 'styled-components'

export const StyledField = styled.div`
  margin-top: ${(props) => (props.noMargin ? '0px' : '24px')};
  .view-title {
    color: ${(props) => props.theme.palette.blue.electric};
    width: 100%;
    font-size: 13px;
    font-weight: normal;
    margin-bottom: 12px;
  }
  .view-value {
    width: 100%;
    font-size: 13px;
    font-weight: normal;
  }
`

export const StyledDate = styled.div`
  flex-direction: column;
  .date-time {
    flex-direction: row;
    align-items: baseline;
    display: flex;
    margin-bottom: 5px;
  }
`
