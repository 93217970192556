import ProductPlaceholderIcon from 'components/svg/product-placeholder'
import { Checkbox } from '@teamfabric/copilot-ui'
import { getSkuAlreadyInUse } from 'src/modules/promo/helper/sku'
import { getBuyGetTargetTypes } from 'src/modules/promo/utils'
import {
  PromotionRadioTypeNames,
  BuyGetSpendNames,
} from 'src/modules/promo/constants'
import map from 'lodash/map'
import { FEATURE_FLAGS } from 'src/general-config'
import { isAccountActive } from 'src/api/featureFlag'
import { checkIfSetSelected } from 'src/lib/utils/helper'
const check = (sku, props) => {
    const { targetType } = props
    const selectedSkus = props[`${targetType}SelectedSkusTree`]
    return sku.isParent
      ? selectedSkus && selectedSkus[sku._id]
      : selectedSkus &&
          selectedSkus[sku.parent] &&
          selectedSkus[sku.parent][sku._id]
  },
  getImage = (sku, props) => {
    if (props.selectedCriteria.includes('Attributes')) {
      if (sku['Image 1 URL']) {
        return <img src={sku['Image 1 URL']} />
      } else {
        return <ProductPlaceholderIcon />
      }
    } else {
      if (sku.images && sku.images.length && sku.images[0].source[0].url) {
        return <img src={sku.images[0].source[0].url} />
      } else {
        return <ProductPlaceholderIcon />
      }
    }
  },
  renderImage = (sku, props) => {
    return <div className='product_image'>{getImage(sku, props)}</div>
  },
  isSkuDisabled = (sku, props) => {
    const { targetType } = props
    let selectedCategories = []
    let otherSelectedSkus = []
    const PIMConnector = isAccountActive({
      featureFlag: FEATURE_FLAGS?.Pim_Connector,
    })
    Object.keys(props.allData).forEach((data) => {
      if (
        data.includes('SelectedCategories') &&
        (data.includes(targetType.slice(0, 3)) ||
          checkIfSetSelected(props?.allData, data)) &&
        !data.includes('Final') &&
        !data.includes('Temp')
      ) {
        selectedCategories = selectedCategories.concat(
          props.allData[data].map((single) => {
            return single.id
          })
        )
      }
      if (
        data.includes('SelectedSkus') &&
        (data.includes(targetType.slice(0, 3)) ||
          checkIfSetSelected(props?.allData, data)) &&
        !data.includes(targetType) &&
        !data.includes('Temp') &&
        !data.includes('Final')
      ) {
        otherSelectedSkus = otherSelectedSkus.concat(props.allData[data])
      }
    })
    selectedCategories = selectedCategories.concat(
      props[`${targetType}CheckedCategories`]
    )
    if (!sku.isParent || !PIMConnector) {
      sku.belogsToCategories = sku.group?.map((group) => {
        return group._id
      })
    }
    const skuAlreadyInUse = getSkuAlreadyInUse(sku, selectedCategories)
    if (sku.itemId) {
      isSkuRelatedAttribute(sku.attributes, props)
    }
    const selectedOtherTypeSkus = map(otherSelectedSkus, '_id').concat(
      map(otherSelectedSkus, 'parent')
    )

    return selectedOtherTypeSkus.includes(sku._id) ||
      skuAlreadyInUse.length > 0 ||
      isSkuRelatedAttribute(sku.attributes, props)
      ? true
      : false
  },
  isSkuRelatedAttribute = (attributes, props) => {
    const { targetType, allData } = props
    let tempSelectedAttributes =
      allData[`${targetType}TempSelectedAttributes`] || []
    let selectedAttributes = tempSelectedAttributes.concat(
      props[`${targetType}SelectedAttributes`]
    )
    if (allData.selectedPromoTypeRadio === PromotionRadioTypeNames.BUY_GET) {
      let types = []
      types = types.concat(
        getBuyGetTargetTypes({
          buyGetType: BuyGetSpendNames.BUY,
          props: allData,
        })
      )
      types = types.concat(
        getBuyGetTargetTypes({
          buyGetType: BuyGetSpendNames.GET,
          props: allData,
        })
      )
      types = types.concat(
        getBuyGetTargetTypes({
          buyGetType: BuyGetSpendNames.SPEND,
          props: allData,
        })
      )
      types.forEach((type) => {
        if (type !== targetType) {
          selectedAttributes = selectedAttributes.concat(
            allData[`${type}SelectedAttributes`]
          )
        }
      })
    }
    let isSkuDisable = false
    selectedAttributes.forEach((selectedAttribute) => {
      attributes?.forEach((attribute) => {
        if (
          selectedAttribute?.parent === attribute?.id &&
          selectedAttribute?.id === attribute?.value
        ) {
          isSkuDisable = true
          return true
        }
      })
      if (isSkuDisable) return true
    })
    return isSkuDisable
  },
  isPartial = (sku, props) => {
    const { targetType } = props
    const selectedSkus = props[`${targetType}SelectedSkusTree`]

    if (selectedSkus) {
      if (
        sku.items.length === 0 ||
        (sku.items.length === 0 && !selectedSkus[sku._id])
      )
        return false
      const itemsCount = sku.items.length

      const checkedVariantsCount =
        selectedSkus[sku._id] && getCheckedVariantsCount(selectedSkus[sku._id])
      return itemsCount !== checkedVariantsCount
    }
    return false
  },
  getCheckedVariantsCount = (sku) => {
    let count = 0
    for (let key in sku) {
      if (typeof sku[key] === 'object' && sku[key] && sku[key]._id) {
        count += 1
      }
    }
    return count
  },
  getIsChecked = ({ isDisabled, isChecked }) => {
    if (isDisabled) {
      return true
    } else {
      return isChecked
    }
  }

const renderCheckBox = (sku, props) => {
  const isChecked = check(sku, props)
  const { targetType, viewMode } = props
  const isDisabled =
    props.selectedCriteria.includes('SKUs') && isSkuDisabled(sku, props)
  return props.selectedCriteria.includes('SKUs') ? (
    <div className='checkbox_padding'>
      <Checkbox
        disabled={isDisabled || viewMode}
        tabindex='0'
        onClick={() => {
          props.setOnCheck({ sku, targetType: targetType })
        }}
        checked={getIsChecked({ isDisabled, isChecked })}
        isPartial={checkIsPartial({ isDisabled, sku, props })}
        data-testid={sku._id}
        id={sku._id}
      />
    </div>
  ) : (
    <></>
  )
}
const checkIsPartial = ({ isDisabled, sku, props }) => {
  if (isDisabled) {
    return false
  } else {
    if (sku.isParent) {
      isPartial(sku, props)
    } else {
      return false
    }
  }
}
const renderTitle = (sku) => {
  return <div className='title'>{sku.title}</div>
}
const children = (props) => [
  {
    title: '',
    isSortable: false,
    accessor: 'checkbox',
    render: (details) => renderCheckBox(details, props),
  },
  {
    title: '',
    accessor: 'image',
    isSortable: false,
    render: (details) => {
      return renderImage(details, props)
    },
  },
  {
    title: 'Title',
    isSortable: false,
    accessor: 'title',
    render: (details) => renderTitle(details),
  },
  {
    title: 'SKU ID',
    isSortable: false,
    accessor: 'sku',
  },
]
const HEADERS = (props) => [
  {
    title: '',
    isSortable: false,
    accessor: 'checkbox',
    render: (details) => renderCheckBox(details, props),
    children: [...children(props)],
  },
  {
    title: 'Title',
    accessor: 'image',
    isSortable: false,
    render: (details) => {
      return renderImage(details, props)
    },
  },
  {
    title: '',
    isSortable: false,
    accessor: 'title',
    render: (details) => renderTitle(details),
  },
  {
    title: 'SKU ID',
    isSortable: false,
    accessor: 'sku',
  },
]
export { HEADERS }
