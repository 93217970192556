import React, { Suspense, useEffect } from 'react'
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import { APP_PAGE_PATHS, FEATURE_FLAGS } from '../config/constants'
import GenericPageLoader from 'src/components/loader'
import { hasPermission } from '../lib/rolePermissions'
import { READ_PERMISSIONS } from 'src/lib/constants/constants'
import { EmptyPage } from 'ds4-beta'
import { isFeatureActive } from '../api/featureFlag'

const ProductListing = React.lazy(() => import('../features/productListing'))
const PriceImportHistory = React.lazy(() =>
  import('../features/priceImportHistory')
)
const ImportHistory = React.lazy(() =>
  import('../features/couponPromoListing/components/ImportHistory')
)
const ProductPricing = React.lazy(() => import('../features/productPricing'))
const ProductVariant = React.lazy(() => import('../features/productVariant'))
const CustomerSegments = React.lazy(() =>
  import('../features/customerSegments')
)
const AdditionalAttributes = React.lazy(() =>
  import('../features/additionalAttributes')
)
const SettingsPriceList = React.lazy(() =>
  import('../features/settingsPriceList')
)
const SettingsGlobalExclusionListing = React.lazy(() =>
  import('../features/settingsGlobalExclusionListing')
)
const GlobalExclusionList = React.lazy(() =>
  import('../features/globalExclusionList')
)
const CouponPromoListing = React.lazy(() =>
  import('../features/couponPromoListing')
)
const CouponPromoFeature = React.lazy(() => import('../features/promo/create'))

const PromoForm = React.lazy(() => import('../features/promoForm'))
const CouponPromoEdit = React.lazy(() => import('../features/promo/edit'))
const CouponClone = React.lazy(() => import('../features/promo/clone'))
const CouponRedemtions = React.lazy(() =>
  import('../features/couponRedemptionListing')
)
const CouponRedemptionExportListing = React.lazy(() =>
  import('../features/couponRedemptionExportListing')
)

const AppContent = ({ children }) => {
  const navigate = useNavigate()
  useEffect(() => {
    //This navigate is to solve https://yottadv.atlassian.net/browse/OFFER-10096
    //the reason for using window.location and not the location passed by reach router
    //to the child component as location prop, is that the location prop is incorrect
    //when we navigate away from Offers to other app and come back to Offers
    navigate(window.location.pathname)
  }, [])
  return (
    <div className='app_root'>
      <main className='d-flex w-100 app_wrapper'>
        <div className='app_children'>{children}</div>
      </main>
    </div>
  )
}

const Loading = () => <GenericPageLoader />

const DS4AppRoutes = () => {
  const promoFormRedesign = isFeatureActive({
    flagName: FEATURE_FLAGS.PROMO_FORM_REDESIGN,
  })
  if (!hasPermission(READ_PERMISSIONS.READ_OFFERS)) {
    return (
      <EmptyPage
        description={`Please request access from an admin`}
        headerText={`You don't have permission to access offers`}
      />
    )
  }
  return (
    <main id='app_root' data-testid='ds4-app'>
      <Suspense fallback={<Loading />}>
        <Routes>
         {/* product pricing routes */}
        <Route path={APP_PAGE_PATHS.PRODUCT_LISTING} element={<ProductListing />} />
        <Route path={APP_PAGE_PATHS.PRICE_IMPORT_HISTORY} element={<PriceImportHistory />} />
        <Route path={APP_PAGE_PATHS.PRICE_DETAILS} element={<ProductPricing />} />
        <Route path={APP_PAGE_PATHS.VARIANT_DETAILS} element={<ProductVariant />} />
       
         {/* coupon/promo routes */}
        <Route path={APP_PAGE_PATHS.COUPON_CREATE} element={<CouponPromoFeature />} />
        <Route path={APP_PAGE_PATHS.PROMO_CREATE} element={promoFormRedesign ? <PromoForm /> : <CouponPromoFeature />} />
        <Route path={APP_PAGE_PATHS.COUPON_UPDATE} element={<CouponPromoEdit />} />
        <Route path={APP_PAGE_PATHS.PROMO_UPDATE} element={promoFormRedesign ? <PromoForm /> : <CouponPromoEdit />} />
        <Route element={<CouponPromoListing />} path={APP_PAGE_PATHS.ALL_COUPONS} />
        <Route element={<CouponPromoListing />} path={APP_PAGE_PATHS.PROMO_LISTING} />
        <Route path={APP_PAGE_PATHS.COUPON_CLONE} element={<CouponClone />} />
        <Route path={APP_PAGE_PATHS.PROMO_CLONE} element={promoFormRedesign ? <PromoForm isClone={true} /> : <CouponClone />} />
        <Route path={APP_PAGE_PATHS.COUPON_IMPORT_HISTORY} element={<ImportHistory type='coupon' />} />

        {/* Redemptions routes */}
        <Route element={<CouponRedemtions />} path={APP_PAGE_PATHS.COUPON_REDEMPTION_LISTING} />
        <Route element={<CouponRedemptionExportListing />} path={APP_PAGE_PATHS.COUPON_REDEMPTION_EXPORT_LISTING} />

         {/* settings page routes */}
         <Route element={<SettingsPriceList />} path={APP_PAGE_PATHS.SETTINGS_PRICE_LIST} />
         <Route element={<GlobalExclusionList type='create'/>} path={APP_PAGE_PATHS.CREATE_GLOBAL_EXCLUSIONS_LIST} />
         <Route element={<GlobalExclusionList type='update' />} path={APP_PAGE_PATHS.UPDATE_GLOBAL_EXCLUSIONS_LIST} />
         <Route element={<SettingsGlobalExclusionListing />} path={APP_PAGE_PATHS.SETTINGS_GLOBAL_EXCLUSIONS_LISTING} />
         <Route element={<CustomerSegments />} path={APP_PAGE_PATHS.CUSTOMER_SEGMENTS} />
         <Route element={<AdditionalAttributes />} path={APP_PAGE_PATHS.SETTINGS_ATTRIBUTES} />

         {/* redirection routes */}
         <Route path={APP_PAGE_PATHS.SETTINGS} element={<Navigate replace to={APP_PAGE_PATHS.SETTINGS_PRICE_LIST} />} />
         <Route path={APP_PAGE_PATHS.COUPON_LISTING} element={<Navigate replace to={APP_PAGE_PATHS.ALL_COUPONS} />} />
         <Route path={APP_PAGE_PATHS.OLD_COUPON_CREATE} element={<Navigate replace to={APP_PAGE_PATHS.COUPON_CREATE} />} />
         <Route path='/offers/' element={<Navigate replace to={APP_PAGE_PATHS.PRODUCT_LISTING} />} />
        </Routes>
      </Suspense>
    </main>
  )
}

export default DS4AppRoutes
