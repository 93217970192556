import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { Loading } from '@teamfabric/copilot-ui'
import { StyledSpinnerContainer } from './styles'

const spinnerSizes = {
  small: 13,
  default: 44
}

const renderSpinner = props => {
  const { small, id, ...rest } = props
  return (
    <StyledSpinnerContainer
      data-variant={props.variant}
      {...rest}
      data-testid={id ? id : 'fs-spinner'}
    >
      <Loading
        data-testid='spinner'
        className='global no-padding'
        size={small ? spinnerSizes.small : spinnerSizes.default}
      />
    </StyledSpinnerContainer>
  )
}

const Spinner = props => {
  return props.variant === 'fullScreen' && process.browser
    ? createPortal(renderSpinner(props), document.body)
    : renderSpinner(props)
}

Spinner.defaultProps = {
  variant: 'normal',
  small: false
}

Spinner.propTypes = {
  variant: PropTypes.oneOf(['normal', 'fullScreen', 'block', 'fill']),
  small: PropTypes.bool,
  color: PropTypes.oneOf(['grey', 'primary', 'secondary', 'success', 'error'])
}

export default Spinner
