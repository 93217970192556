import styled from 'styled-components'
import { theme } from '@teamfabric/copilot-ui'

export const StyledWrapper = styled.div`
  display: flex;
  padding-right: 24px;
  .snack-bar {
    position: absolute;
    margin-left: 200px;
    margin-top: -35px;
    z-index: 1;
  }
`

export const StyledContent = styled.div`
  width: 100%;
  .content_padding {
    margin-bottom: 40px;
    .search_count {
      display: flex;
      align-items: center;
      margin-top: 25px;
      margin-bottom: 80px;
      input {
        font-family: Gilroy-Regular;
      }
    }
    .total_result {
      margin-left: 16px;
      color: ${(props) => props.theme.palette.text.label};
      ${(props) => props.theme.typography.small};
    }
    .loadmore_container {
      display: flex;
      justify-content: center;
      padding: 24px 0;
      background-color: white;
      &.show_pagination {
        height: 60px;
        margin-top: -60px;
        }
      }
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-left: 20px;
  }
  .price-list-wrapper {
    justify-content: flex-end;
    display: flex;
    .price-list {
      div {
        width: fit-content;
      }
      background-color: ${(props) => props.theme.palette.common.white};
      border-radius: 40px;
      width: fit-content;
      .dropdown-header {
        padding: 0;
        box-shadow: none;
            
        .content-wrapper {
          flex: none;
          padding: 10px 24px;
        }
      }
    }
  }
`
export const StyledViewSwitcher = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100px;
  height: 42px;
  .switch {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    width: 42px;
    padding: 0;
    cursor: pointer;
  }
  .active {
    svg {
      rect {
        fill: #0d62ff;
      }
    }
  }
`

export const StyledOption = styled.li`
  text-align: left;
  list-style: none;
  cursor: pointer;
  color: #121213;
  padding: 14px 20px 14px 24px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 20px;
  .currency-text {
    color: ${theme.palette.brand.primary.gray};
  }
`
export const StyledOptionButton = styled.li`
  text-align: center;
  list-style: none;
  cursor: pointer;
  padding: 0px 20px 0px 24px;
`

export const Caption = styled.span`
  display: block;

  margin-bottom: 27px;

  font-family: Gilroy-Medium;
  font-size: 14px;
  line-height: 16px;

  color: #121213;
`
