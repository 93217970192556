import { BuyGetSpendNames } from 'src/modules/promo/constants'

export const joinSelection = ({ selection }) => {
  let elasticQuery = []
  for (let i in selection) {
    for (let j of selection[i].values) {
      elasticQuery.push({ match: { [`attributes.${i}`]: `${j}` } })
    }
  }
  return elasticQuery
}

export const transformPimConnector = ({ result, page, previousState }) => {
  let products = result?.data?.data?.map((hit) => pimConnector({ hit }))
  const totalProducts =
    page > 0 ? products?.length + previousState?.length : products?.length
  return {
    totalProducts: totalProducts,
    currentPage: page,
    maxPage: (result?.data?.offset / 10 || 0) + 1,
    searchParameter: '',
    hasMore: result?.data?.count > totalProducts,
    count: result?.data?.count,
    items: page > 0 ? products.concat(previousState) : products,
    itemsPagenation: products,
  }
}

const pimConnector = ({ hit }) => {
  let storeAttributes = []
  for (let name in hit._source.attributes) {
    storeAttributes.push({ key: name, value: hit._source.attributes[name] })
  }
  return {
    id: hit._id,
    attributes: storeAttributes,
    itemId: hit._source.itemId,
    title: hit._source.title,
    sku: hit._source.productSku,
    objectID: hit._source.productId,
    tenantId: hit._source.tenantId,
    parentSku: hit._source.parentSku,
  }
}

export const getBuyGetTargetTypes = ({ buyGetType, props }) => {
  const { promotion } = props
  if (buyGetType === BuyGetSpendNames.BUY) {
    let buyTypes = []
    promotion?.[`${buyGetType}Data`]?.forEach((buy) => {
      buyTypes.push(`set${buy.set}`)
    })
    return buyTypes
  } else if (buyGetType === BuyGetSpendNames.GET) {
    let getTypes = []
    promotion?.[`${buyGetType}Data`]?.forEach((get) => {
      if (get.getSkuSet) {
        getTypes.push(get.getSkuSet.set)
      }
    })
    return getTypes
  } else {
    let spendTypes = []
    if (
      promotion?.[`${buyGetType}Data`]?.skuCondition !== '*' &&
      props?.spendProducts?.length > 0
    ) {
      spendTypes.push('spend')
    }
    return spendTypes
  }
}

export const transformSegment = ({ customerSegment }) => {
  return {
    type: 'customerSegment',
    value: customerSegment?.map((segment) => {
      return {
        ...segment,
        isErrorName: segment?.name ? false : true,
        isErrorValue:
          segment?.value?.length === 0 || segment?.values?.length === 0,
      }
    }),
  }
}

export const transformExcludeSegment = ({ customerSegment }) => {
  return {
    ...transformSegment({ customerSegment }),
    type: 'excludeCustomerSegment',
  }
}
export const isArrayEqual = (x = [], y = []) => {
  return x.length ? _(x).differenceWith(y, _.isEqual).isEmpty() : false
}

export const exclusionConditionMapping = {
  AND: 'OR',
  OR: 'AND',
}
