export default ({ fill = '#8994A3' }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
  >
    <g fill='none' fillRule='nonzero'>
      <path d='M0 0h24v24H0z' />
      <path
        fill={fill}
        d='M11.29 14.706l-3.996-3.995A1.002 1.002 0 0 1 8.003 9h7.994a1.003 1.003 0 0 1 .71 1.711l-3.998 3.995a1.003 1.003 0 0 1-1.418 0z'
      />
    </g>
  </svg>
)
