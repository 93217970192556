import React from 'react'
import store from 'store'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import GlobalStylesOffers from 'lib/global-styles'
import { theme } from 'lib/theme'
import { getAvatarUrl } from 'lib/utils/avatar'
import AppRoutes from './AppRoutes'
import { GlobalStyle, theme as uiTheme } from '@teamfabric/copilot-ui'
const _user = {
  avatar: '',
  userEmail: '',
  userName: '',
  userNav: [
    {
      label: 'Edit Profile',
      link: '/auth/my-account',
    },
    {
      label: 'Logout',
      link: '/auth/login',
    },
  ],
}

class Offers extends React.Component {
  state = {
    user: _user,
    error: '',
  }

  static async getInitialProps({ Component, ctx }) {
    let pageProps = {}

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx)
    }
    return { pageProps }
  }
  componentDidMount = async () => {
    if (window && window.sessionStorage) {
      try {
        let storage = window.sessionStorage
        const [first, last] = [
          storage.getItem('firstName'),
          storage.getItem('lastName'),
        ]
        const user = {
          ..._user,
          avatar: getAvatarUrl(first),
          userName: `${first} ${last}`,
          userEmail: '',
        }
        this.setState({ user })
      } catch (error) {
        this.setState({ error })
      }
    }
  }

  render() {
    const { user } = this.state
    return (
      <Provider store={store}>
        <ThemeProvider theme={{ ...theme, uiTheme }}>
          <React.Fragment>
            <GlobalStyle />
            <GlobalStylesOffers />
              <AppRoutes user={user} />
          </React.Fragment>
        </ThemeProvider>
      </Provider>
    )
  }
}

export default Offers
