import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import { getItems } from 'src/api/product'
import { calculateHasMore } from 'lib/utils/helper'
import { Table, Input } from '@teamfabric/copilot-ui'
import AttributeItemTable from './item-table/attributes-items'
import { StyledSkus, StyledSkuContainer } from './styles'
import { HEADERS } from './item-table/headers'
import {
  getCheckedCategories,
  getCategoriesSkusCount,
  getTempSelectedSkusCount,
  getAttributeItemsDetails,
  getIsFetchingItems,
  getTempSelectedSkusTree,
  getSelectedAttributes,
  getSelectedSkus,
  getAllState,
} from 'modules/promo/selectors'
import {
  ON_SKU_CHECK,
  ON_SKUS_SELECT_ALL,
  SET_CATEGORIES_SKUS_COUNT,
  IS_FETCHING_ITEMS,
  GET_ATTRIBUTE_ITEMS,
} from 'modules/promo/actions'
import { StyledEmptyTable } from './item-table/styles'
import {
  getSelectedSKUsHeader,
  emptySkuWindowMessage,
} from 'src/lib/utils/helper.js'
class SkuListing extends Component {
  state = {
    items: [],
    hasMore: false,
    hasSearched: false,
    query: {
      limit: 5,
      offset: 0,
    },
    keyword: '',
    selectedCriteria: this.props.selectedCriteria,
    targetType: this.props.targetType || 'targetX',
    currentPage: 1,
  }

  componentDidUpdate(prevProps) {
    const { targetType } = this.state
    const { selectedCriteria } = this.props

    if (selectedCriteria !== prevProps.selectedCriteria) {
      this.setState({ selectedCriteria: selectedCriteria }, () => {
        this.getResults()
      })
    }
    if (
      (selectedCriteria === 'Categories' ||
        selectedCriteria === 'Collections') &&
      !isEqual(
        this.props[`${targetType}Checked${selectedCriteria}`].sort(),
        prevProps[`${targetType}Checked${selectedCriteria}`].sort()
      )
    ) {
      this.getResults()
    }
  }

  componentDidMount() {
    this.getResults()
    this.setState({ targetType: this.props.targetType })
  }

  getResults = () => {
    const { fetchAttributeItems, selectedCriteria } = this.props
    const { targetType } = this.state
    switch (selectedCriteria) {
      case 'Attributes':
        fetchAttributeItems({ page: 0, targetType })
        return
      case 'Categories':
        const checkedCategories = this.props[`${targetType}CheckedCategories`]
        checkedCategories.length > 0
          ? this.fetchSearchResults('')
          : this.setState({
              items: [],
              query: { limit: 5, offset: 0 },
              keyword: '',
            })
        return
      case 'Collections':
        const checkedCollections =
          this.props[`${targetType}CheckedCollections`] || []
        checkedCollections.length > 0
          ? this.fetchSearchResults('')
          : this.setState({
              items: [],
              query: { limit: 5, offset: 0 },
              keyword: '',
            })
        return
      default:
        this.fetchSearchResults('')
        return
    }
  }

  fetchSearchResults = async ({ keyword, page = 1 }) => {
    const { targetType, query } = this.state
    const { setIsLoading } = this.props
    let offset = 0
    const currentPage = page
    const emptyData = { data: { products: [] } }
    offset = parseInt(currentPage - 1) * parseInt(query.limit)
    setIsLoading(true)
    const selectedCategories =
      this.props.selectedCriteria === 'Categories'
        ? this.props[`${targetType}CheckedCategories`]
        : []
    const selectedCollections =
      this.props.selectedCriteria === 'Collections'
        ? this.props[`${targetType}CheckedCollections`]
        : []
    try {
      let reqData = {
        limit: query.limit,
        offset: offset,
        keyword: keyword,
        group:
          this.props.selectedCriteria === 'Categories'
            ? selectedCategories
            : selectedCollections,
      }
      const type =
        this.props.selectedCriteria === 'Categories' ? 'category' : ''
      const { data } =
        (this.props.selectedCriteria === 'Categories' &&
          selectedCategories.length > 0) ||
        this.props.selectedCriteria === 'SKUs'
          ? await getItems(reqData, type)
          : this.props.selectedCriteria === 'Collections' &&
            selectedCollections.length > 0
          ? await getItems(reqData, 'collection')
          : emptyData

      const { products } = data || []
      const hasMore = calculateHasMore(data)
      if (products && products.length === 0) {
        this.setState({
          items: products,
          query: { ...data.query },
          hasMore: hasMore,
          currentPage: currentPage,
        })
        setIsLoading(false)
        return
      }
      this.setState({
        items: products,
        query: { ...data.query },
        hasMore: hasMore,
        currentPage: currentPage,
      })
      if (this.props.selectedCriteria === 'Categories') {
        this.props.setCategoriesSkusCount({
          count: data.query.count,
          targetType,
        })
      }
      setIsLoading(false)
    } catch (e) {
      console.log('==================error', e)
      this.setState({ error: e })
      setIsLoading(false)
    }
  }

  onLoadMore = (page) => {
    this.fetchSearchResults({ keyword: this.state.keyword, page: page })
  }

  render() {
    return <StyledSkus>{this.renderList()}</StyledSkus>
  }

  onReset = ({ hasSearched, selectedCriteria, fetchAttributeItems }) => {
    hasSearched && selectedCriteria === 'Attributes'
      ? fetchAttributeItems({ page: 0 })
      : this.fetchSearchResults({ keyword: '' })
    this.setState({ keyword: '', hasSearched: false })
  }

  onKeyPress = ({ ev, selectedCriteria, fetchAttributeItems, keyword }) => {
    if (ev.key === 'Enter') {
      this.setState({ hasSearched: true })
      selectedCriteria === 'Attributes'
        ? fetchAttributeItems({ page: 0, searchParameter: keyword })
        : this.fetchSearchResults({ keyword: keyword })
    }
  }

  renderEmptySkuWindow = ({ data, selectedCriteria, isLoading }) => {
    return isEmpty(data) &&
      (selectedCriteria === 'Categories' ||
        selectedCriteria === 'Collections' ||
        selectedCriteria === 'SKUs') &&
      !isLoading ? (
      <tbody>
        <tr>
          <td colSpan={HEADERS(this.props).length + 1} align='center'>
            <StyledEmptyTable>
              <p className='table_text'>
                {emptySkuWindowMessage({ selectedCriteria })}
              </p>
            </StyledEmptyTable>
          </td>
        </tr>
      </tbody>
    ) : null
  }

  renderHeader = () => {
    const { keyword, hasSearched, targetType } = this.state
    const { fetchAttributeItems, selectedCriteria, isLoading } = this.props
    const attributeItemsDetails =
      this.props[`${targetType}AttributeItemsDetails`]
    const tempSelectedSkusCount =
      this.props[`${targetType}TempSelectedSkusCount`]
    const checkedCategories = this.props[`${targetType}CheckedCategories`]
    const categoriesSkusCount = this.props[`${targetType}CategoriesSkusCount`]
    const selectedCategoriesSkusCount =
      checkedCategories && checkedCategories.length > 0
        ? categoriesSkusCount
        : 0
    const selectedSKUsHeader = getSelectedSKUsHeader({
      selectedCriteria,
      attributeItemsDetails,
      selectedCategoriesSkusCount,
      tempSelectedSkusCount,
    })
    return (
      <div
        className='search_header'
        data-testid='search-header'
        style={{
          justifyContent:
            selectedCriteria === 'SKUs' &&
            selectedSKUsHeader === 0 &&
            'flex-end',
        }}
      >
        {selectedSKUsHeader > 0 && !isLoading && (
          <div className='search_header_selected'>
            {`${selectedSKUsHeader} SKUs`}
          </div>
        )}
        {selectedSKUsHeader > 0 && isLoading && (
          <div className='search_header_selected'>
            <></>
          </div>
        )}
        {this.props.selectedCriteria === 'SKUs' && (
          <div className='search-wrapper'>
            <Input
              className='search-local'
              icon='Search'
              width='436px'
              inputProps={{
                id: 'search',
                name: 'search',
                placeholder: 'Search by SKU Title/ID',
                value: keyword,
                onChange: (e) => {
                  this.setState({ keyword: e.target.value })
                },
                'data-testid': 'search-input',
                boxed: false,
              }}
              onReset={() => {
                this.onReset({
                  hasSearched,
                  selectedCriteria,
                  fetchAttributeItems,
                })
              }}
              onKeyPress={(ev) =>
                this.onKeyPress({
                  ev,
                  selectedCriteria,
                  fetchAttributeItems,
                  keyword,
                })
              }
              isFloatedLabel={false}
            />
          </div>
        )}
      </div>
    )
  }
  renderList = () => {
    const { items, targetType } = this.state
    const { selectedCriteria, isLoading, viewMode } = this.props
    const attributeItemsDetails =
      this.props[`${targetType}AttributeItemDetails`] || {}
    const products =
      selectedCriteria === 'Attributes'
        ? (attributeItemsDetails && attributeItemsDetails.items) || []
        : items
    products.forEach((product) => {
      product.children = product.items
      product.isParent = true
      product.children &&
        product.children.forEach((item) => {
          item.isParent = false
          item.parent = product._id
        })
    })

    return (
      <StyledSkuContainer>
        {!viewMode && this.props.renderFooter()}
        {this.renderHeader()}
        <div data-testid='skus-listing' className='skus-listing'>
          {selectedCriteria === 'Attributes' ? (
            <AttributeItemTable
              data={products || []}
              selectedCriteria={selectedCriteria}
              targetType={targetType}
            />
          ) : (
            <Table
              className='sku-table'
              cellClassName='sku-table-cell'
              columns={HEADERS(this.props)}
              data={products || []}
              loading={isLoading}
              perPage={this.state.query.limit}
              showPagination={true}
              collapseOnRowClick={false}
              totalRecords={this.state.query.count || 0}
              handlePagination={(page) => {
                this.onLoadMore(page)
              }}
              activePageNumber={this.state.currentPage}
              render={({ data }) =>
                this.renderEmptySkuWindow({ data, selectedCriteria, isLoading })
              }
            />
          )}
        </div>
      </StyledSkuContainer>
    )
  }
}

const mapStateToProps = (state) => {
  let typeState = {}
  const types = [
    'targetX',
    'targetY',
    'exclusion',
    state.promoReducer.targetType,
  ]
  types.forEach((type) => {
    typeState = {
      ...typeState,
      [`${type}CheckedCategories`]: getCheckedCategories(state, type),
      [`${type}CheckedCollections`]: getCheckedCategories(state, type, true),
      [`${type}CategoriesSkusCount`]: getCategoriesSkusCount(state, type),
      [`${type}TempSelectedSkusCount`]: getTempSelectedSkusCount(state, type),
      [`${type}AttributeItemDetails`]: getAttributeItemsDetails(state, type),
      [`${type}AttributeItemsDetails`]: getAttributeItemsDetails(state, type),
      [`${type}SelectedSkusTree`]: getTempSelectedSkusTree(state, type),
      [`${type}SelectedSkus`]: getSelectedSkus(state, type),
      [`${type}SelectedAttributes`]: getSelectedAttributes(state, type),
    }
  })
  return {
    allData: getAllState(state),
    isLoading: getIsFetchingItems(state),
    ...typeState,
  }
}

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      setOnCheck: ON_SKU_CHECK,
      setSelectAll: ON_SKUS_SELECT_ALL,
      setCategoriesSkusCount: SET_CATEGORIES_SKUS_COUNT,
      setIsLoading: IS_FETCHING_ITEMS,
      fetchAttributeItems: GET_ATTRIBUTE_ITEMS,
    },
    dispatch
  )
)(SkuListing)
