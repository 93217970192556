import jwtDecode from 'jwt-decode'

const isBrowser = typeof window !== 'undefined'

let decodedToken
let account =
  localStorage.getItem('isLoggedInWithIdV2') === 'true'
    ? sessionStorage.getItem('accountId')
    : sessionStorage.getItem('account') // account is used in iDv1 and accountID is used in iDv2
try {
  let JwtToken = window?.sessionStorage?.getItem('accessToken')
  if (!JwtToken) {
    console.log(
      'JwtToken is not defiend in sessionStorage, please refer general.config file'
    )
  } else {
    decodedToken = isBrowser ? jwtDecode(JwtToken) : null
  }
} catch (err) {
  console.error('Error @ general-config', err)
  window.location.href = `${window?.location?.origin}/auth/login`
}

export const algoliaClient = {
  appId: process.env.ALGOLIA_APP_ID,
  apiKey: process.env.ALGOLIA_API_KEY,
  attributesSearchIndex: `${account}-${process.env.SLS_STAGE}-copilot-search-attribute`,
  attributesItemsSearchIndex: `${account}-${process.env.SLS_STAGE}-copilot-search-item`,
}

export const xApiKey = process.env.PIM_CONNECTOR_X_API_KEY

export const dataDogSecrets = {
  ddClientToken: process.env.DATADOG_CLIENT_TOKEN_OFFERS,
  ddAppId: process.env.DATADOG_APPLICATION_ID_OFFERS,
}

let pimConnectorAccount
for (let account in process.env.dev02_copilot_pim_connector_toggles) {
  if (
    account === sessionStorage.getItem('account') ||
    account === sessionStorage.getItem('accountId')
  ) {
    // account is used in iDv1 and accountID is used in iDv2
    pimConnectorAccount =
      process.env.dev02_copilot_pim_connector_toggles[`${account}`]
  }
}

const isAwsSecretManager = () => {
  if (process.env.pim_connector_account) {
    return pimConnectorAccount
  } else {
    return false
  }
}

export const FEATURE_FLAGS = {
  PIM_CONNECTOR: 'pim_connector',
  Pim_Connector: 'pim_connector',
  CUSTOMER_SEGMENTS: 'customer_segments',
  GLOBAL_EXCLUSIONS: 'global_exclusions',
  FEATURE_FLAGS: 'feature_flag',
  OMS_FEATURE_FLAGS: 'oms_feature_flag',
  BUY_GET: 'buy-get',
  SPEND_GET: 'spend-get',
  PROMOTION_LIMIT: 'promotion_limit',
  ALGOLIA_PERFORMANCE_IMPROVEMENT: 'algolia_performance_improvement',
  BUT_GET_SHIPPING_CART: 'buy-get-shipping-cart-value',
  ENHANCEMENT_IN_BULK_PRICE_IMPORT: 'enhancement_in_bulk_price_import',
  PAGINATION_ON_PRODUCT_PRICE: 'pagination_on_product_price',
  PRICE_LIST_PAGINATION: 'price_list_pagination',
  DISABLE_CART_SHIPPING: 'disable_cart_shipping',
  RBAC_V2: 'rbac_v2',
  ELIMINATION_OF_EXTRA_FIELD: 'elimination_of_extra_field',
  DYNAMIC_DISCOUNT: 'dynamic_discount',
  PROMOTION_STACKING: 'promotion_stacking',
  CHEAPEST_EXPENSIVE_ITEM: 'cheapest_expensive_item',
  SECOND_MOST_EXPENSIVE_ITEM: 'second_most_expensive_item',
  NEW_PRODUCT_SELECTION: 'new_product_selection',
  COUPON_BULK_IMPORT: 'coupon_bulk_import',
  DS4: 'offers_ds4_migration',
  ALTERNATE_HIERARCHY: 'alternate_hierarchy',
  OMS_V1_SHIPPING_TYPE_API: 'ui-v1',
  PIM_V1: 'pim-v1',
  REDEMPTION_EXPORT: 'ui_redemption_export',
  PROMOTION_MESSAGING: 'promotion_messaging',
  PROMOTION_PROXIMITY: 'promotion_proximity',
  ADDITIONAL_ATTRIBUTES: 'additional_attributes',
  OFFERS_V3_API_MIGRATION: 'offers_v3_api_migration',
  SHIPPING_ONLY_CONFIG: 'ui_shipping_only_config',
  MERCHANT_IDENTIFIER_SKU: 'isMerchantIdentifierSku',
}
export const ACTIVE_ACCOUNT_FOR_PIM = 'active_accounts__pim'
