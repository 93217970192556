import { Timepicker } from '@teamfabric/copilot-ui'
import { Styledtimepicker } from './styles'
import { addHours, addMinutes, startOfDay } from 'date-fns'
const OffersTimepicker = ({
  setFieldValues,
  label,
  date,
  dateForTime,
  disabled,
}) => {
  return (
    <Styledtimepicker data-testid={`${label}-time`}>
      <Timepicker
        className='time-picker'
        date={date ? date : null}
        disabled={disabled}
        label={`Time`}
        onChange={setFieldValues}
        width='100%'
        minTime={getMinTime(dateForTime)}
        maxTime={getMaxTime()}
        canUserTypeIntoInput={false}
      />
    </Styledtimepicker>
  )
}
const getMinTime = (date) => {
  return date === '' || isToday(date) ? new Date() : startOfDay(new Date(date))
}
const getMaxTime = () => {
  const startDay = startOfDay(new Date())
  const hoursAdd = addHours(startDay, 23)
  return addMinutes(hoursAdd, 45)
}

const isToday = (date) => {
  const today = new Date()
  date = new Date(date)
  return (
    date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear()
  )
}
export default OffersTimepicker
