import React from 'react'
import { ButtonLoader, TableLoader } from '@teamfabric/copilot-ui'
import { SkeltetonBox } from './styles'

const SkeletonLoader = (props) => {
  const { type = 'promo', screen = 'listing' } = props
  return (
    <SkeltetonBox data-testid='skeleton-loader'>
      {type === 'promo' ? promoView(screen) : priceView(screen)}
    </SkeltetonBox>
  )
}

const promoView = (screen) => {
  return (
    <div className='skt_left'>
      {screen !== 'exclusion' && (
        <>
          <div className='skt_buttonContainer'>
            <ButtonLoader theme='dark' width='156px' />

            <ButtonLoader className='skt_btn2' theme='dark' width='156px' />
          </div>
          <ButtonLoader
            className='skt_search'
            theme='dark'
            height='40px'
            width='700px'
          />
          <ButtonLoader
            className='skt_text'
            theme='dark'
            height='16px'
            width='156px'
          />
        </>
      )}
      <div className='skt_table_container'>
        <div className='skt_table'>
          <TableLoader theme='dark' />
        </div>
      </div>
    </div>
  )
}

const priceView = (screen) => {
  return screen === 'listing' ? priceViewListing() : priceViewDetails()
}

const priceViewListing = () => {
  return (
    <div className='skt_left'>
      <div className='skt_buttonContainer'>
        <ButtonLoader theme='dark' width='156px' />
      </div>
      <ButtonLoader
        className='skt_search'
        theme='dark'
        height='40px'
        width='700px'
      />
      <ButtonLoader
        className='skt_text'
        theme='dark'
        height='16px'
        width='156px'
      />
      <div className='skt_table_container'>
        <div className='skt_table'>
          <TableLoader theme='dark' columnCount={8} rowCount={11} />
        </div>
      </div>
    </div>
  )
}

const priceViewDetails = () => {
  return (
    <div className='skt_left'>
      <div className='skt_buttonContainer'>
        <ButtonLoader theme='dark' width='156px' />
      </div>
      <div className='detail_header'>
        <ButtonLoader
          className='skt_search_details'
          theme='dark'
          height='40px'
          width='400px'
        />
        <ButtonLoader
          className='skt_text_add_button'
          theme='dark'
          height='16px'
          width='137px'
        />
        <div className='table_with_buttons'>
          <ButtonLoader
            className='skt_text_add_button_2'
            theme='dark'
            height='16px'
            width='137px'
          />
          <div className='add_price_button_loader'>
            <ButtonLoader
              className='skt_text'
              theme='dark'
              height='36px'
              width='136px'
            />

            <ButtonLoader
              className='skt_text'
              theme='dark'
              height='36px'
              width='136px'
            />
            <ButtonLoader
              className='skt_text'
              theme='dark'
              height='36px'
              width='136px'
            />
            <ButtonLoader
              className='skt_text'
              theme='dark'
              height='36px'
              width='186px'
            />
            <ButtonLoader
              className='skt_text'
              theme='dark'
              height='36px'
              width='115px'
            />
          </div>
          <div className='skt_table_container_detail'>
            <div className='skt_table'>
              <TableLoader theme='dark' rowCount={5} columnCount={6} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SkeletonLoader
