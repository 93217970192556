const getItemDetails = ({ sku, isParent }) => {
  return {
    _id: sku._id,
    title: sku.title,
    parent: !isParent ? sku.parent : '',
    images: sku.images,
    itemId: sku.itemId,
    sku: sku.sku,
  }
}

const buildQueryForCategory = ({ groups }) => {
  const terms = []
  groups?.forEach((group) => {
    terms.push(
      {
        terms: {
          'categories.categoryId.keyword': [group],
        },
      },
      {
        terms: {
          'categories.breadcrumbs.categoryId.keyword': [group],
        },
      },
      {
        terms: {
          'categories.breadcrumbs.name.keyword': [group],
        },
      },
      {
        terms: {
          'categories.name.keyword': [group],
        },
      }
    )
  })
  return {
    bool: {
      should: terms,
    },
  }
}

const buildQueryForFilters = ({ filters }) => {
  const { category, collection, attributes } = filters;
  const terms = []
  category?.forEach((cat) => {
    terms.push(
      {
        terms: {
          'categories.categoryId.keyword': [cat],
        },
      },
      {
        terms: {
          'categories.breadcrumbs.categoryId.keyword': [cat],
        },
      },
      {
        terms: {
          'categories.breadcrumbs.name.keyword': [cat],
        },
      },
      {
        terms: {
          'categories.name.keyword': [cat],
        },
      },
    )
  })
  collection?.forEach((coll) => {
    terms.push(
      {
        terms: {
          'collections.collectionId.keyword': [coll],
        },
      },
      {
        terms: {
          'collections.breadcrumbs.collectionId.keyword': [coll],
        },
      },
      {
        terms: {
          'collections.breadcrumbs.name.keyword': [coll],
        },
      },
      {
        terms: {
          'collections.name.keyword': [coll],
        },
      }
    )
  })
  attributes?.forEach((attr) => {
    const key = `attributes.${attr.label}.keyword`
    terms.push(
      {
        terms: {
          [key]: attr.value
        },
      })
  })
  return {
    bool: {
      should: terms,
    },
  }
}

const buildQueryForCollection = ({ groups }) => {
  const terms = []
  groups?.forEach((group) => {
    terms.push(
      {
        terms: {
          'collections.collectionId.keyword': [group],
        },
      },
      {
        terms: {
          'collections.breadcrumbs.collectionId.keyword': [group],
        },
      },
      {
        terms: {
          'collections.breadcrumbs.name.keyword': [group],
        },
      },
      {
        terms: {
          'collections.name.keyword': [group],
        },
      }
    )
  })
  return {
    bool: {
      should: terms,
    },
  }
}

export const buildSkuProductSearchQuery = ({ keyword = '', offset, limit, itemType }) => {
  const payload = {
    query: {
      bool: {
        must: [
          ...(itemType ? [{ match: { 'itemType.keyword': itemType } }] : []),
          ...(keyword ? [{ match: { 'productSku.keyword': keyword } }]: []),
        ],
      },
    },
    limit,
    offset,
  }
  return payload
}

export const buildVariantsProductSearchQuery = ({
  keyword = '',
  offset,
  limit
}) => {
  const payload = {
    query: {
      bool: {
        must: [
          { match: { 'parentSku.keyword': keyword } }
        ],
      },
    },
    limit,
    offset,
  }
  return payload
}

export const buildCategoryCollectionProductSearchQuery = ({
  requestParams,
  type,
  offset,
  limit,
}) => {
  const groupsQuery =
    type === 'category'
      ? buildQueryForCategory({ groups: requestParams?.group })
      : buildQueryForCollection({ groups: requestParams?.group })
  const payload = {
    query: {
      bool: {
        must: [{ match: { 'parentSku.keyword': '' } }, groupsQuery],
      },
    },
    limit,
    offset,
  }
  return payload
}

export const buildFilterProductSearchQuery = ({
  requestParams,
  offset,
  limit,
}) => {
  const groupsQuery = buildQueryForFilters({ filters: requestParams.filters })
  const payload = {
    query: {
      bool: {
        must: [{ match: { 'parentSku.keyword': '' } }, groupsQuery],
      },
    },
    limit,
    offset,
  }
  return payload
}

const removeParentAndItsVariant = ({
  isSelectAll,
  updatedTempSkus,
  updatedCount,
  sku,
  selectedSkusCount,
  notToUpdateCount,
}) => {
  if (isSelectAll) {
    return {
      updatedTempSkus,
      updatedCount,
    }
  }
  if (updatedTempSkus[sku._id] && !updatedTempSkus[sku._id]['_id']) {
    updatedCount =
      !notToUpdateCount &&
      setCount(
        Object.keys(updatedTempSkus[sku._id]).length,
        'sub',
        selectedSkusCount
      )
  } else {
    updatedCount = !notToUpdateCount && setCount(1, 'sub', selectedSkusCount)
  }
  delete updatedTempSkus[sku._id]
  return {
    updatedTempSkus,
    updatedCount,
  }
}

const updateSelectedSkus = ({
  selectedSkus,
  sku,
  notToUpdateCount = false,
  selectedSkusCount,
  isSelectAll = false,
}) => {
  let updatedTempSkus = { ...selectedSkus }
  let updatedCount = selectedSkusCount
  // Remove parent and it's variants if parent present
  if (updatedTempSkus[sku._id]) {
    return removeParentAndItsVariant({
      isSelectAll,
      updatedTempSkus,
      updatedCount,
      sku,
      selectedSkusCount,
      notToUpdateCount,
    })
  }

  // Remove variant and it's parent if present
  if (updatedTempSkus[sku.parent] && updatedTempSkus[sku.parent][sku._id]) {
    if (isSelectAll) {
      return {
        updatedTempSkus,
        updatedCount,
      }
    }
    return removeVariantAndItsParent(
      sku,
      updatedTempSkus,
      selectedSkusCount,
      notToUpdateCount
    )
  }
  // Add parent and it's variants
  if (!updatedTempSkus[sku._id] && !sku.parent) {
    return addParentAndItsVariant(
      sku,
      updatedTempSkus,
      selectedSkusCount,
      notToUpdateCount
    )
  }

  // Add variant and it's parent
  if (sku.parent) {
    return addVariantAndItsParent(
      sku,
      updatedTempSkus,
      selectedSkusCount,
      notToUpdateCount
    )
  }
}

const setCount = (count, type, selectedSkusCount) => {
  return type === 'add' ? selectedSkusCount + count : selectedSkusCount - count
}

const removeVariantAndItsParent = (
  sku,
  updatedTempSkus,
  selectedSkusCount,
  notToUpdateCount = false
) => {
  delete updatedTempSkus[sku.parent][sku._id]
  if (Object.keys(updatedTempSkus[sku.parent]).length === 0) {
    delete updatedTempSkus[sku.parent]
  }
  const updatedCount =
    !notToUpdateCount && setCount(1, 'sub', selectedSkusCount)
  return { updatedTempSkus, updatedCount }
}

const addParentAndItsVariant = (
  sku,
  updatedTempSkus,
  selectedSkusCount,
  notToUpdateCount = false
) => {
  if (
    !sku.items ||
    (sku.items && sku.items.length === 0) ||
    (sku?.items?.length && !sku?.items?.[0]?._id)
  ) {
    updatedTempSkus[sku._id] = {
      ...getItemDetails({ sku, isParent: true }),
    }
    const updatedTempCount =
      !notToUpdateCount && setCount(1, 'add', selectedSkusCount)
    return { updatedTempSkus, updatedCount: updatedTempCount }
  }
  updatedTempSkus[sku._id] = {
    [sku._id]: getItemDetails({ sku, isParent: true }),
  }
  sku?.items?.map((item) => {
    updatedTempSkus[sku._id] = {
      ...updatedTempSkus[sku._id],
      [item._id]: getItemDetails({ sku: item, isParent: false }),
    }
  })
  const selectedSkuCount = sku.items.length + 1
  const updatedCount =
    !notToUpdateCount &&
    setCount(sku.items && selectedSkuCount, 'add', selectedSkusCount)

  return { updatedTempSkus, updatedCount }
}

const addVariantAndItsParent = (
  sku,
  updatedTempSkus,
  selectedSkusCount,
  notToUpdateCount = false
) => {
  if (updatedTempSkus[sku.parent]) {
    updatedTempSkus[sku.parent] = {
      ...updatedTempSkus[sku.parent],
      [sku._id]: getItemDetails({ sku, isParent: false }),
    }
    const updatedTempCount =
      !notToUpdateCount && setCount(1, 'add', selectedSkusCount)
    return { updatedTempSkus, updatedCount: updatedTempCount }
  }
  const updatedCount =
    !notToUpdateCount && setCount(1, 'add', selectedSkusCount)
  updatedTempSkus[sku.parent] = {
    [sku._id]: getItemDetails({ sku, isParent: false }),
  }
  return { updatedTempSkus, updatedCount }
}

const getSelectedSkus = ({ skus }) => {
  let selectedSkus = []
  for (const sku in skus) {
    const selectedSku = skus[sku]
    if (selectedSku._id) {
      selectedSkus.push(getItemDetails({ sku: selectedSku, isParent: true }))
    } else {
      if (Object.keys(selectedSku).length > 0 && selectedSku) {
        const variants = selectedSku
        for (const variant in variants) {
          const selectedVariant = variants[variant]
          selectedSkus.push(
            getItemDetails({ sku: selectedVariant, isParent: false })
          )
        }
      }
    }
  }
  return selectedSkus
}

export { updateSelectedSkus, getSelectedSkus }
