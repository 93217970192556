import API, { API_URL } from '../index'
import { ACTIVE_ACCOUNT_FOR_PIM } from 'src/general-config'
import { UnleashClient } from 'unleash-proxy-client'
import {
  featureFlagDomainMapping,
  featureFlagEnvMapping,
  allDetailedFeatures,
  featuresWithNewProductSelection,
} from 'src/lib/data/constants'

const proxyEnv = process.env.SLS_STAGE || 'dev02'
export const config = {
  url: `https://${featureFlagDomainMapping[proxyEnv]}.foundation.feature.fabric.inc/proxy`,
  clientKey: process.env.FEATURE_FLAG_CLIENT_KEY || 'copilot-foundations-dev',
  appName: 'offers',
  environment: featureFlagEnvMapping[proxyEnv],
}
let client = null
let isClientReady = false
export const startFeatureFlagsProxy = () => {
  if (client) {
    // client already initialized
    return
  }

  client = new UnleashClient(config)
  client.start()
  const userId = sessionStorage.getItem('accountId')
  const tenantId =
    localStorage.getItem('isLoggedInWithIdV2') === 'true'
      ? sessionStorage.getItem('accountId')
      : sessionStorage.getItem('account') // account is used in iDv1 and accountID is used in iDv2
  client.updateContext({ userId, tenantId })
}

const isAccountActive = ({ featureFlag }) => {
  try {
    const isIdv2Login = localStorage.getItem('isLoggedInWithIdV2')
    const isFlagActive = isFeatureActive({ flagName: featureFlag })
    const currentAccount =
      isIdv2Login === 'true'
        ? sessionStorage.getItem('accountId')
        : sessionStorage.getItem('account')
    const isActiveAccount = localStorage.getItem(ACTIVE_ACCOUNT_FOR_PIM)
    const parsedActiveAccount = JSON?.parse(isActiveAccount)
    const active = parsedActiveAccount?.filter(
      (accout) => accout?.key === currentAccount
    )[0]?.value
    return isFlagActive && active
  } catch (error) {
    console.error(error)
    return {
      isPimConnector: false,
    }
  }
}

const getTestingFeature = ({ flagName }) => {
  if (localStorage.getItem('newProductSelection') === 'true') {
    return featuresWithNewProductSelection[flagName]
  }
  return allDetailedFeatures[flagName]
}

const isFeatureActive = ({ flagName, setFlag }) => {
  if (localStorage.getItem('environment') === 'testing') {
    const pimConnectorFeatureFlag = false
    if (
      localStorage.getItem('pimConnectorFeatureFlag') == pimConnectorFeatureFlag
    ) {
      return false
    } else {
      return getTestingFeature({ flagName })
    }
  }
  if (!client) {
    // Client not initialized
    startFeatureFlagsProxy()
  }

  const getFeatureFlag = async () => {
    return new Promise((res) => {
      client.on('ready', () => {
        isClientReady = true
        const flag = client.isEnabled(flagName)
        // For local environment - uncomment below line if you want to verify the FF state
        // isLocal && console.log(`${flagName} - ${flag}`)
        res(flag)
      })
    })
  }
  if (!isClientReady && setFlag) {
    getFeatureFlag().then((flag) => {
      setFlag(flag)
    })
  }
  const flag = client.isEnabled(flagName)
  // For local environment - uncomment below line if you want to verify the FF state
  // isLocal && console.log(`${flagName} - ${flag}`)
  if (setFlag) {
    setFlag(flag)
  }
  return flag
}

export { isAccountActive, isFeatureActive }
