import styled from 'styled-components'
import theme from 'lib/theme/theme'

export const StyledPromoTypes = styled.div`
  overflow: visible;
  border: 1px solid rgb(115, 127, 143, 0.2);
  padding: 32px;
  border-radius: 4px;

  .section_heading {
    ${(props) => props.theme.typography.h4};
    color: ${(props) => props.theme.palette.text.charcoal};
    margin-bottom: 18px;
    ${(props) =>
      props.disabled && { color: props.theme.palette.text.disabled }};
  }
  .sub_heading {
    ${(props) => props.theme.typography.custom};
    color: ${(props) => props.theme.palette.grey.brand};
    ${(props) =>
      props.disabled && { color: props.theme.palette.text.disabled }};
  }
`

export const StyledSpend = styled.div`
  .spend-heading {
    ${theme.typography.kicker};
    color: ${(props) => props.theme.palette.grey.brand};
    padding-top: 32px;
  }
  .spend-sub-heading {
    ${(props) => props.theme.typography.Custom}
    color : ${(props) => props.theme.palette.text.charcoal};
    ${(props) => props.disabled && { color: props.theme.palette.text.disabled }}
  }
  .minimumCartValue {
    padding-top: 30px;
  }
`
