import React from 'react'
import { ButtonLoader } from '@teamfabric/copilot-ui'
import { StyledLoadingState } from './styles'

const LoadingState = () => {
  return (
    <StyledLoadingState data-testid='skeleton-loader'>
      <ButtonLoader theme='dark' width='156px' />

      <div className='ls_content'>
        <div className='ls_left'>
          <ButtonLoader theme='dark' width='300px' />
          <ButtonLoader className='ls_text' theme='dark' width='156px' />
          <div className='ls_card ls_card1'>
            <ButtonLoader theme='dark' height='26px' width='156px' />
            <ButtonLoader
              className='ls_card1_btn'
              theme='dark'
              height='26px'
              width='156px'
            />
          </div>
          <div className='ls_card ls_card2'>
            <div className='ls_card2_top'>
              <ButtonLoader theme='dark' height='26px' width='180px' />
              <ButtonLoader theme='dark' height='26px' width='180px' />
            </div>
            <div className='ls_card2_bottom'>
              <ButtonLoader theme='dark' height='26px' width='100px' />
              <ButtonLoader
                className='ls_card2_bottom_btn'
                theme='dark'
                height='26px'
                width='100px'
              />
              <ButtonLoader theme='dark' height='26px' width='100px' />
            </div>
          </div>

          <div className='ls_card ls_card2 ls_card3'>
            <ButtonLoader theme='dark' height='26px' width='180px' />
            <div className='ls_card2_bottom'>
              <ButtonLoader theme='dark' height='26px' width='100px' />
              <ButtonLoader
                className='ls_card2_bottom_btn'
                theme='dark'
                height='26px'
                width='100px'
              />
              <ButtonLoader theme='dark' height='26px' width='100px' />
            </div>
            <ButtonLoader
              className='ls_card3_btn'
              theme='dark'
              height='26px'
              width='320px'
            />
            <ButtonLoader
              className='ls_card3_btn2'
              theme='dark'
              height='26px'
              width='320px'
            />
          </div>
        </div>
        <div className='ls_right'>
          <ButtonLoader theme='dark' width='100%' />
          <div className='ls_right_content'>
            <ButtonLoader theme='dark' width='190px' />
            <ButtonLoader className='ls_right_btn' theme='dark' width='140px' />
            <ButtonLoader theme='dark' width='170px' />
          </div>
        </div>
      </div>
    </StyledLoadingState>
  )
}

export default LoadingState
