import {
  renderStatus,
  renderImage,
  renderPrice,
  renderTitle,
  renderDate,
} from 'components/table-columns'

const children = [
  {
    title: '',
    accessor: 'image',
    isSortable: false,
    render: (details) => {
      return renderImage(details.images, details._id)
    },
  },
  {
    title: 'SKU',
    isSortable: false,
    accessor: 'sku',
  },
  {
    title: 'Product title',
    isSortable: false,
    accessor: 'title',
    render: (details) =>
      renderTitle({ rowDetails: { ...details }, isChild: true }),
  },

  {
    title: 'Cost',
    isSortable: false,
    accessor: '',
    render: (details) => {
      return renderPrice({
        rowDetails: { ...details },
        isChild: true,
        type: 'cost',
      })
    },
  },
  {
    title: 'Base Price',
    isSortable: false,
    accessor: '',
    render: (details) =>
      renderPrice({ rowDetails: { ...details }, isChild: true, type: 'base' }),
  },
  {
    title: 'Clearance',
    isSortable: false,
    accessor: '',
    render: (details) =>
      renderPrice({ rowDetails: { ...details }, isChild: true, type: 'sale' }),
  },
  {
    title: 'Status',
    isSortable: false,
    accessor: '',
    render: (details) => renderStatus({ ...details }),
  },
  {
    title: 'Start date',
    isSortable: false,
    accessor: 'startDate',
    render: (details) => renderDate(details.priceStartDate),
  },
  {
    title: 'End date',
    isSortable: false,
    accessor: 'endDate',
    render: (details) => renderDate(details.priceEndDate),
  },
]
const HEADERS = (currency = '') => [
  {
    title: '',
    accessor: 'image',
    isSortable: false,
    render: (details) => {
      return renderImage(details.images, details._id)
    },
    children: [...children],
  },
  {
    title: 'SKU',
    isSortable: false,
    accessor: 'sku',
  },
  {
    title: 'Product title',
    isSortable: false,
    accessor: 'title',
    render: (details) =>
      renderTitle({ rowDetails: { ...details }, isChild: false }),
  },
  {
    title: 'Cost',
    isSortable: false,
    accessor: '',
    render: (details) =>
      renderPrice({ rowDetails: { ...details }, type: 'cost', currency }),
  },
  {
    title: 'Base Price',
    isSortable: false,
    accessor: ' ',
    render: (details) =>
      renderPrice({ rowDetails: { ...details }, type: 'base', currency }),
  },
  {
    title: 'Clearance',
    isSortable: false,
    accessor: '',
    render: (details) =>
      renderPrice({ rowDetails: { ...details }, type: 'sale', currency }),
  },
  {
    title: 'Status',
    isSortable: false,
    accessor: '',
    render: (details) => renderStatus({ ...details }),
  },
  {
    title: 'Start date',
    isSortable: false,
    accessor: 'startDate',
    render: (details) => renderDate(details.priceStartDate),
  },
  {
    title: 'End date',
    isSortable: false,
    accessor: 'endDate',
    render: (details) => renderDate(details.priceEndDate),
  },
]
export { HEADERS }
