import styled from 'styled-components'
import theme from 'lib/theme/theme'
import { hexToRgba } from 'lib/utils/helper'

export const StyledProduct = styled.div`
  .product_row {
    width: 718px;
    border-bottom: 1px solid ${props => props.theme.palette.text.separation};
    align-items: center;
    height: 80px;
    ${props => props.theme.typography.body.css};
    background: ${props =>
      props.isParent
        ? props.theme.palette.common.white
        : props.theme.palette.text.container};
    display: grid;
    grid-template-columns: 1fr 0.5fr 10fr 2.5fr;
    :hover {
      background: ${props => props.theme.palette.text.container};
    }
    .product_image {
      img {
        width: 48px;
        height: 48px;
      }
      svg {
        width: 48px;
        height: 48px;
      }
    }
    .toggle {
      outline: 0;
      ${props => props.theme.typography.h5.css};
      cursor: pointer;
    }
    .checkbox_padding {
      padding-left: 16px;
      ${props => (props.isDisabled ? `cursor: not-allowed` : `cursor: pointer`)}
    }
    .title_row {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0px 16px;
      cursor: pointer;
      .title {
        ${props => props.theme.typography.h5.css};
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .sku_id {
        ${props => props.theme.typography.small.css};
        color: ${props => props.theme.palette.text.main};
      }
    }
  }
`
export const VariantContainer = styled.div`
  background: ${props => props.theme.palette.text.container};
  .variants_container {
    width: 718px;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    :nth-last-child {
      border-bottom: none;
    }
  }
  .show {
    max-height: ${props =>
      props.childCount > 0 ? `${props.childCount * 80}px` : `0px`};
    overflow: hidden;
  }
`

export const StyledProductScroller = styled.ul`
  position: relative;
  display: block;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-left: 0px;
  overflow-y: scroll;
  padding-bottom: 0px;
  max-height: ${props => (props.isSelectAllPresent ? '350px' : '384px')};
  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledLoadMore = styled.div`
  outline: 0;
  display: flex;
  height: 22px;
  align-items: center;
  justify-content: center;
  ${props => props.theme.typography.small};
  color: ${props => props.theme.palette.text.enable};
  cursor: pointer;
`

export const StyledEmptySkuList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 75px;

  .empty_content {
    margin: 40px 0px 24px 0px;
    ${props => props.theme.typography.bodyLarge};
    text-align: center;
  }
  button {
    width: 166px;
    height: 40px;
  }
`

export const StyledSelectAll = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 8px;
  margin-left: 16px;
  .checkbox {
    margin-right: 28px;
  }
  .select_all {
    ${props => props.theme.typography.small};
    color: ${props => props.theme.palette.text.main};
  }
`

export const StyledEmptyTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 280px;
  .table_text {
    ${props => props.theme.typography.h4};
  }
`

const StyledAttributesTableBorderRgba = hexToRgba(theme.palette.grey.brand, 0.2)

export const StyledAttributesTableWrapper = styled.div`
  table {
    border: 1px solid
      rgba(
        ${StyledAttributesTableBorderRgba.red},
        ${StyledAttributesTableBorderRgba.green},
        ${StyledAttributesTableBorderRgba.blue},
        ${StyledAttributesTableBorderRgba.opacity}
      );
  }

  .attribute-row {
    td {
      padding: 11px;
    }
  }
`
