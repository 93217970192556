import styled from 'styled-components'

export const StyledSkuContainer = styled.div`
  background-color: #fff;
  padding: 30px 30px 20px 30px;
`

export const StyledSkus = styled.div`
  width: 100%;
  height: 100%;
  .search_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    // margin: 10px 0;
    margin: 30px 0;
    .search_header_selected {
      ${(props) => props.theme.typography.h5};
      color: ${(props) => props.theme.palette.grey.brand};
    }
  }
  .skus-listing {
    table:before {
      content: none;
    }
    overflow-y: auto;
    overflow-x: hidden;
    height: 522px;
    background-color: #fff;
    border: 1px solid rgba(115, 127, 143, 0.2);
    .sku-table-cell {
      height: 72px;
      padding: 0px;
    }
    .product_image {
      img {
        width: 48px;
        height: 48px;
      }
      svg {
        width: 48px;
        height: 48px;
      }
    }
  }
  .count {
    margin: 16px;
    ${(props) => props.theme.typography.small};
    color: ${(props) => props.theme.palette.text.main};
  }
`
