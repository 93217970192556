import { Box, GridCol, GridRow, Skeleton } from 'ds4-beta'

const ContentLoader = () => {
  let loaderContent = []
  for (let i = 0; i <= 9; i += 1) {
    loaderContent.push(
      <Box as='div' margin={{ top: 4, bottom: 4 }}>
        <Skeleton height={'32px'} width={'100%'} borderRadius={'4px'} />
      </Box>
    )
  }
  return loaderContent
}

const GenericPageLoader = () => {
  return (
    <>
      <Box as='div' flex={{ flexDirection: 'column' }} margin={{ top: 6 }}>
        <GridRow>
          <GridCol sm={6}>
            <Skeleton height={'32px'} width={'240px'} borderRadius={4} />
          </GridCol>
          <GridCol
            sm={6}
            justifySelf='flex-end'
            flex={{ justifyContent: 'flex-end' }}
          >
            <Skeleton height={'32px'} width={'120px'} borderRadius={4} />
          </GridCol>
        </GridRow>
        <Box as='div' margin={{ top: 4 }}>
          <GridRow>
            <Skeleton height={'32px'} width={'300px'} borderRadius={44} />{' '}
            &nbsp;&nbsp;
            <Skeleton height={'32px'} width={'32px'} borderRadius={16} />
          </GridRow>
        </Box>
        <Box as='div' margin={{ top: 8, left: 6, right: 6 }}>
          <ContentLoader />
        </Box>
      </Box>
    </>
  )
}
export default GenericPageLoader
