import styled from 'styled-components'

export const StyledAttribute = styled.div`
  display: flex;
  .column {
    flex-direction: column;
  }

  .margin_left_32 {
    margin-left: 32px;
  }
  padding-top: 12px;

  .row {
    display: flex;
    padding-left: ${(props) => (props.isChild ? '48px' : '5px')};
    align-items: center;
    .check-box {
      padding: 0px;
    }
  }
  .toggle {
    outline: 0;
    cursor: pointer;
  }
`

export const StyledAttributesAndSkus = styled.div`
  .attributes {
    height: 400px;
    width: 300px;
    overflow: auto;
    ${(props) => props.theme.typography.body};
    color: ${(props) => props.theme.palette.text.charcoal};
  }
  .snack-bar {
    position: absolute;
    margin-left: 200px;
    margin-top: -35px;
    z-index: 1;
  }
  .spinner {
    top: 50%;
    position: absolute;
    left: 10%;
  }
  .load_more {
    outline: 0;
    display: flex;
    height: 22px;
    align-items: center;
    justify-content: center;
    ${(props) => props.theme.typography.small};
    color: ${(props) => props.theme.palette.text.enable};
    cursor: pointer;
  }
`

export const StyledValuesContainer = styled.div`
  .values_container {
    width: 240px;
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    :nth-last-child {
      border-bottom: none;
    }
  }
  .show {
    max-height: ${(props) =>
      props.childCount > 0 ? `${props.childCount * 80}px` : `0px`};
    overflow: hidden;
  }
`
