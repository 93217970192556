import React from 'react'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle, theme, CopilotUIProvider } from 'ds4-beta'
import AppRoutes from './AppRoutes'
import { Provider } from 'react-redux'
import store from 'src/ds4/store'
import { BrowserRouter } from 'react-router-dom';

const Offers = () => (
  <Provider store={store}>
    <CopilotUIProvider>
      <ThemeProvider theme={{ theme }}>
        <React.Fragment>
          <GlobalStyle />
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </React.Fragment>
      </ThemeProvider>
    </CopilotUIProvider>
  </Provider>
)

export default Offers
