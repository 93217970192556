import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import CloseIcon from 'components/svg/close'
import { StyledModal } from './styles'

export default class Modal extends Component {
  static propTypes = {
    closeOnBackdropClick: PropTypes.bool,
    portalize: PropTypes.bool,
    portalTarget: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    render: PropTypes.any.isRequired,
    id: PropTypes.string,
  }

  static defaultProps = {
    closeOnBackdropClick: true,
    portalize: true,
    portalTarget: 'body',
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden'
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto'
  }

  render() {
    return this.props.portalize
      ? ReactDOM.createPortal(
          this.renderContent(),
          document.querySelector(this.props.portalTarget)
        )
      : this.renderContent()
  }

  renderContent = () => {
    return (
      <StyledModal
        onClick={this.props.onBackdropClick}
        width={this.props.width}
        noPadding={this.props.noPadding}
        data-testid={this.props.id ? `${this.props.id}-modal` : 'modal'}
      >
        <div className='modal_content' onClick={(e) => e.stopPropagation()}>
          {!this.props.hideIcon && (
            <div className='close-container' onClick={this.props.onClose}>
              <CloseIcon />
            </div>
          )}
          {this.props.render()}
        </div>
      </StyledModal>
    )
  }
}
