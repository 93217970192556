import difference from 'lodash/difference'
import { algoliaClient } from 'src/general-config'
const itemsSearchIndex =
  algoliaClient.attributesItemsSearchIndex ||
  'teamfabric-copilotApi-jobs-develop-copilot-item-index'

/** 
 * default state
 * {
      attribute: []
   }
 * 
 * */

const onCheckAttributes = ({
  selectedAttributes,
  attribute,
  value,
  isValue = false,
}) => {
  //checking and unchecking child
  if (isValue) {
    if (selectedAttributes[attribute.id]) {
      // unchecking
      const isPresent = selectedAttributes[attribute.id].values.includes(value)
      if (isPresent) {
        const presentValues = selectedAttributes[attribute.id].values
        const updatedValues = difference(presentValues, [value])
        selectedAttributes[attribute.id] = {
          name: attribute.name,
          values: updatedValues,
        }
        if (updatedValues.length === 0) {
          delete selectedAttributes[attribute.id]
        }
        return selectedAttributes
      }
      //checking
      let values = selectedAttributes[attribute.id].values
      selectedAttributes[attribute.id] = {
        name: attribute.name,
        values: values.concat(value),
      }
      return selectedAttributes
    }
    selectedAttributes[attribute.id] = {
      name: attribute.name,
      values: [value],
    }
    return selectedAttributes
  }

  //unchecking parent
  if (selectedAttributes[attribute.id]) {
    delete selectedAttributes[attribute.id]
    return selectedAttributes
  }
  return {
    ...selectedAttributes,
    [attribute.id]: {
      name: attribute.name,
      values: attribute.value,
    },
  }
}

const getSelectedAttributes = ({ attributes }) => {
  let selectedAttributes = []
  for (const attribute in attributes) {
    const selectedAttribute = attributes[attribute]
    let selectedValues = selectedAttribute.values.map((value) => {
      return {
        id: value,
        title: `${selectedAttribute.name}/${value}`,
        parent: attribute,
        parentName: selectedAttribute.name,
      }
    })
    selectedAttributes = selectedAttributes.concat(selectedValues)
  }
  return selectedAttributes
}

const makeQueries = ({ selectedAttributesTree, page, searchParameter }) => {
  let queries = []
  for (const attribute in selectedAttributesTree) {
    const selectedAttribute = selectedAttributesTree[attribute]
    queries.push(
      getQuery(
        selectedAttribute.name,
        selectedAttribute.values,
        page,
        searchParameter
      )
    )
  }
  return queries
}

const getQuery = (key, values, page, searchParameter) => {
  let facetValues = []
  values.forEach((value) => {
    facetValues.push(`attributes.value:${value}`)
    facetValues.push(`variants.value:${value}`)
  })

  const facetFilters = [
    [`attributes.key:${key}`, `variants.key:${key}`],
    facetValues,
  ]
  return {
    indexName: itemsSearchIndex,
    query: searchParameter,
    params: {
      page,
      hitsPerPage: 5,
      facetFilters,
      distinct: true,
    },
  }
}

export { onCheckAttributes, getSelectedAttributes, makeQueries }
