export const FETCH_RESULTS_FAIL = 'Unable to fetch results'
export const PAGE_LIMIT = 20
export const SEARCH_PAGE_LIMIT = 10
export const INITIAL_PAGE = 1
export const INITIAL_CONFIG = {
  limit: SEARCH_PAGE_LIMIT,
  count: 0,
  offset: 0
}
export const STATUSES = {
  ACTIVE: 'ACTIVE',
  UPCOMING: 'UPCOMING',
  EXPIRED: 'EXPIRED'
}
