import styled from 'styled-components'
import { theme } from '@teamfabric/copilot-ui'

export const StyledPageHeading = styled.div`
  ${theme.typography.kicker};
  color: ${theme.palette.brand.primary.charcoal};
  display: flex;
  align-items: center;
  display: flex;
  cursor: pointer;
  .children {
    margin-left: 12px;
  }
  .page-title {
    font-weight: 600;
  }
`
export const StyledClick = styled.div`
  width: fit-content;
`
