import styled from 'styled-components'

const fillStyle = `
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledSpinnerContainer = styled.div`
  display: inline-block;
  &[data-variant='fill'] {
    ${fillStyle};
  }
  &[data-variant='fullScreen'] {
    ${fillStyle};
    height: ${() =>
      process.browser && document.querySelector('.app_wrapper')
        ? `${document.querySelector('.app_wrapper').clientHeight}px`
        : '100%'};
    background-color: rgba(0, 0, 0, 0.1);
    z-index: ${props => 10};
    bottom: 0;
    position: fixed;
  }
  &[data-variant='block'] {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 80px;
  }
  .no-padding {
    padding: 0px;
  }
`
