import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ProductsModal from './products-modal'
import UploadCsvFile from './upload-csv-file'
import { Button, Modal, Icon, Tooltip } from '@teamfabric/copilot-ui'
import {
  BULK_SKU_CHECK,
  ON_EXCLUDE_INCLUDE_UPDATE_COUNT,
  RESET_PRODUCTS,
  SET_TARGET_TYPE,
  UPDATE_COUNT,
  UPDATE_ADVANCED_SKUS,
} from 'modules/promo/actions'
import { getProducts, getAllState } from 'modules/promo/selectors'
import { StyledBuyProducts, StyledTooltip } from './styles'
import { getOtherTargetTypesSelectedSKus } from 'lib/utils/helper'
class BuyProductSelection extends Component {
  state = {
    showProductsModal: false,
    showUploadCsvModal: false,
    type: this.props.type,
    errors: this.props.errors,
    targetType:
      this.props.targetType === 'setundefined' //puting a check here due to shortage of time have to remove later on
        ? 'setA'
        : this.props.targetType,
    isUploadCsvModalCancelable: true,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errors !== this.props.errors) {
      this.setState({ errors: this.props.errors })
    }
  }

  handleErrors() {
    this.setState({ errors: '' })
  }

  handleModal = (value, type) => {
    this.props.setTargetType(this.state.targetType)
    this.setState({
      showProductsModal: value,
      type: type,
      isShowMore: type ? true : false,
      errors: '',
    })
  }
  uploadCsvModal = (value) => {
    this.setState({
      showUploadCsvModal: value,
    })
  }
  hideUploadCsvModal = () => {
    this.setState({
      showUploadCsvModal: false,
    })
  }
  setUploadCsvModalCancelable = (value) => {
    this.setState({
      isUploadCsvModalCancelable: value,
    })
  }
  render() {
    const { showProductsModal, targetType } = this.state
    const { viewMode } = this.props
    return (
      <StyledBuyProducts disabled={this.props.disabled}>
        <div className='buy_products'>{this.renderContent()}</div>
        {showProductsModal && (
          <ProductsModal
            onDismiss={() => this.handleModal(false)}
            type={this.state.type}
            viewMode={viewMode}
            isShowMore={this.state.isShowMore}
            fromAdvancedOptions={false}
            targetType={targetType}
          />
        )}
        {this.state.showUploadCsvModal && (
          <Modal
            footerButtons={[]}
            headerButtons={[]}
            headerText=''
            hideIcon={!this.state.isUploadCsvModalCancelable}
            onClose={this.hideUploadCsvModal}
            id='sku-bulk-upload'
            onBackdropClick={() => {
              if (this.state.isUploadCsvModalCancelable)
                this.hideUploadCsvModal()
            }}
            render={() => {
              let otherTargetTypesSelectedSKus =
                getOtherTargetTypesSelectedSKus({
                  targetType: targetType,
                  allData: this.props.allData,
                })
              return (
                <UploadCsvFile
                  targetType={targetType}
                  onAddProducts={this.props.onAddProducts}
                  bulkSkuCheck={this.props.bulkSkuCheck}
                  selectedSkus={this.props[`${targetType}SelectedSkuList`]}
                  onClose={this.hideUploadCsvModal}
                  showHideCloseIcon={(value) =>
                    this.setUploadCsvModalCancelable(value)
                  }
                  otherTargetTypesSelectedSKus={otherTargetTypesSelectedSKus}
                  headersData={[['SKU ID']]}
                  errorData={[['SKU ID', 'ERROR']]}
                />
              )
            }}
          />
        )}
      </StyledBuyProducts>
    )
  }
  renderContent() {
    const { disabled, viewMode } = this.props
    const { targetType } = this.state
    const totalProductsCount =
      this.props.allData[`${targetType}FinalProductsCount`] ||
      this.props.allData[`${targetType}IncludeFinalProductsCount`]
    const list = this.props.allData[`${targetType}Products`]

    return (
      <>
        {!viewMode && (
          <div className='btn-wrapper'>
            <Button
              disabled={disabled}
              className='browse_button'
              isPrimary={true}
              size='small'
              data-testid={`${targetType}-browse-skus`}
              onClick={() => {
                this.handleErrors()
                this.handleModal(true, 'All SKUs')
              }}
              text='Browse SKUs'
            />
            <div className='upload'>
              <Button
                disabled={disabled}
                isPrimary={false}
                size='small'
                onClick={() => {
                  this.handleErrors()
                  this.uploadCsvModal(true)
                }}
                data-testid={`${targetType}-bulk-upload-skus`}
                text='Bulk Upload SKUs'
              />
            </div>
          </div>
        )}
        {list && list.length > 0 && (
          <div
            data-testid={`${targetType}-products_link`}
            className='products_link'
            onClick={() => {
              const type = list[list.length - 1].key
                ? list[list.length - 1].key === 'SKUs'
                  ? 'All SKUs'
                  : list[list.length - 1].key
                : 'All SKUs'
              !disabled && this.handleModal(true, type)
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>View added SKUs ({totalProductsCount})</div>
              <ViewMoreProduct content={list} />
            </div>
          </div>
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  let typeState = {}
  const types = [state.promoReducer.targetType]
  types.forEach((type) => {
    typeState = {
      ...typeState,
      [`${type}ProductsList`]: getProducts(state, type),
    }
  })
  return {
    allData: getAllState(state),
    ...typeState,
  }
}

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      bulkSkuCheck: BULK_SKU_CHECK,
      onAddProducts: ON_EXCLUDE_INCLUDE_UPDATE_COUNT,
      updateAdvancedValues: UPDATE_ADVANCED_SKUS,
      resetProducts: RESET_PRODUCTS,
      setTargetType: SET_TARGET_TYPE,
      updateCount: UPDATE_COUNT,
    },
    dispatch
  )
)(BuyProductSelection)

export const ViewMoreProduct = ({ content }) => {
  let array = []
  for (let item of content) {
    array.push(item)
  }

  if (array.length === 0) return null
  let transformedContent = array
    ?.map((item) => item?.values)
    ?.flat()
    ?.map((item) => (item?.title ? item?.title : item?.sku))

  const constructContent = ({ content }) => {
    if (content?.length > 5) {
      let output = content.slice(0, 5)
      let firstFiveItem = output
        .map((item, index) => (index === 4 ? `${item}...` : `${item}, `))
        .join(' ')
      let remainingLength = content.length - 5
      return `${firstFiveItem} +${remainingLength} more`
    } else {
      return content.join(', ')
    }
  }
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        width: '16px',
        height: '40px',
        paddingLeft: '8px',
        marginTop: '-2px',
      }}
    >
      <Icon
        style={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
        iconName='Info'
        iconSize={16}
        type=''
      />
      <Tooltip
        className='tooltip'
        position='right'
        style={{ width: '300px', bottom: '18px', left: '40px' }}
      >
        <StyledTooltip>
          {' '}
          {constructContent({ content: transformedContent })}
        </StyledTooltip>
        <p />
      </Tooltip>
    </div>
  )
}
