import styled from 'styled-components'
export const StyledListView = styled.div`
  overflow-x: auto;
  .expansion-cell {
    cursor: pointer;
  }
  .table-cell {
    padding: 15px 0px;
    min-width: 100px;
    &:first-child {
      min-width: auto;
    }
    &:nth-child(2) {
      min-width: 50px;
    }
    .time {
      color: ${props => props.theme.palette.grey.brand};
      font-size: 12px;
      margin-top: 2px;
    }
  }
`
