import React from 'react'
import { FEATURE_FLAGS } from 'src/general-config'
import { rbac } from '@teamfabric/copilot-utilities'
import { isFeatureActive } from '../featureFlag'

export const hasPermission = (permission) => {
  const rbacFlag = isFeatureActive({ flagName: FEATURE_FLAGS?.RBAC_V2 })
  const isIdv2Login = localStorage.getItem('isLoggedInWithIdV2')
  if (isIdv2Login === 'true' && rbacFlag) {
    const { hasAccess } = rbac.checkPermission({
      allowedPermissions: [permission],
    })
    return hasAccess
  } else {
    return true
  }
}
