exports.CREATE_SUCCESS_PROMO = 'Promotion created successfully'

exports.CREATE_SUCCESS_COUPON = 'Coupon created successfully'

exports.CLONE_SUCCESS_COUPON = 'Coupon  cloned successfully'

exports.CREATE_FAILURE_PROMO = 'Unable to create Promotion'

exports.CREATE_FAILURE_COUPON = 'Unable to create Coupon'

exports.CLONE_FAILURE_COUPON = 'Unable to clone Coupon'

exports.UPDATE_SUCCESS_COUPON = 'Coupon updated successfully'

exports.UPDATE_SUCCESS_PROMO = 'Promotion updated successfully'

exports.UPDATE_FAILURE_COUPON = 'Unable to update Coupon'

exports.UPDATE_FAILURE_PROMO = 'Unable to update Promotion'

exports.DELETE_SUCCESS = 'Promotion deleted successfully'

exports.DELETE_FAILURE = 'Unable to delete promotion'

exports.GENERATED_CODES_SUCCESS =
  'Codes generated successfully. Download the coupon codes from the listing page. '

exports.GENERATED_CODES_FAILURE = 'Unable to generate codes'

exports.ACTIVE_OR_SCHEDULED_PROMO_INFO =
  'Sorry, You cannot update a promotion starting from 15 mins before the promotion goes live to till it goes live but can kill the promotion by using End Now button.'

exports.EXPIRED_PROMO_INFO =
  'Sorry, you cannot make any changes to an expired promotion'

exports.EXPIRED_STATE = 'EXPIRED'
exports.SCHEDULED_STATE = 'SCHEDULED'

exports.PromotionRadioTypes = [
  {
    key: 'SKU(s)',
    rules: [],
  },
  {
    key: 'Cart Value',
    rules: [],
  },
  {
    key: 'Quantity',
    rules: [],
  },
  {
    key: 'Buy-Get',
    rules: [],
  },
]
exports.PromotionRadioTypeNames = {
  SKU: 'SKU(s)',
  CART_VALUE: 'Cart Value',
  QUANTITY: 'Quantity',
  BUY_GET: 'Buy-Get',
}

exports.BuyRadioTypeNames = {
  SKU: 'SKUs',
  BUNDLE: 'Bundle',
  CART_VALUE: 'Cart Value',
}

exports.SkusPromotionType = {
  shipping: 'Shipping off',
  percentage: 'Percentage off',
  amount: 'Amount off',
}

exports.BuyRadioTypes = [
  {
    key: 'SKUs',
    rules: [],
  },
  {
    key: 'Bundle',
    rules: [],
  },
  {
    key: 'Cart Value',
    rules: [],
  },
]

exports.SpendRadioTypes = [
  {
    key: 'All',
    value: '*',
  },
  {
    key: 'Include',
    value: 'INCLUDE',
  },
  {
    key: 'Exclude',
    value: 'EXCLUDE',
  },
]

exports.PromoTypeRadioIds = {
  all: 'ALL_PRODUCTS',
  include: 'INCLUDE_PRODUCTS',
  exclude: 'EXCLUDE_PRODUCTS',
}

exports.GetRadioTypes = [
  {
    key: 'SKU',
    rules: [],
  },
  {
    key: 'Cart',
    rules: [],
  },
  {
    key: 'Shipping',
    rules: [],
  },
]

exports.GetRadioTypeNames = {
  SKU: 'SKU',
  CART: 'Cart',
  SHIPPING: 'Shipping',
}

exports.GetTypeNames = {
  SKU: 'SKU',
  CART: 'CART',
  SHIPPING: 'SHIPPING',
}

exports.BuyGetSpendNames = {
  BUY: 'buy',
  GET: 'get',
  SPEND: 'spend',
}

exports.SingleUsePromoUsage = [
  {
    id: 'PER_USER',
    title: 'Limit coupon use per customer',
    checked: true,
    value: '',
  },
  {
    id: 'SITE_WIDE',
    title: 'Limit coupon usage site wide',
    checked: true,
    value: 1,
  },
]

exports.promoTypes = {
  FIXED_PRICE: 'Fixed price',
  PERCENTAGE_OFF: 'Percentage off',
  AMOUNT_OFF: 'Amount off',
  FREE: 'Free',
  SHIPPING_OFF: 'Shipping off',
}

exports.emptyShippingTypes = [
  {
    label: 'No shipping type available',
    value: 'No shipping type available',
  },
]

exports.getTypePromotionOptions = [
  {
    id: '0',
    name: 'Percentage off',
  },
  {
    id: '1',
    name: 'Amount off',
  },
  {
    id: '2',
    name: 'Free',
  },
  {
    id: '3',
    name: 'Fixed price',
  },
]

exports.PromotionTypeOptions = [
  {
    title: 'Percentage off',
    units: '%',
    key: '%OFF',
  },
  {
    title: 'Amount off',
    units: '$',
    key: 'AMOUNT_OFF',
  },
  {
    title: 'Shipping off',
    units: '$',
    key: 'SHIPPING_OFF',
  },
  {
    title: 'Fixed price',
    units: '$',
    key: 'FIXED',
  },
]

exports.PRICE_KIND_KEY_NAME = {
  KIND: 'kind',
  KEY: 'key',
}

exports.PROMO_TYPE = {
  'SKU(s)': 'PRODUCT',
  'Cart Value': 'CART',
  Quantity: 'QUANTITY',
  'Buy-Get': 'BUYGET',
  'Spend-Get': 'SPENDGET',
}

exports.PROMO_TYPE_FROM_EXTRA_FIELD = {
  PRODUCT: 'SKU(s)',
  CART: 'Buy-Get',
  QUANTITY: 'Quantity',
  BUYGET: 'Buy-Get',
  SPEND_GET: 'Buy-Get',
}

exports.BROWSE_SKUs_TABS = {
  SKUs: 'SKUs',
  Categories: 'Categories',
  Collections: 'Collections',
  Attributes: 'Attributes',
}

exports.DISCOUNT_WINDOW = 'discount'

exports.TERMS_AND_CONDITIONS_TYPE = {
  type: 'termsAndConditions',
}

exports.BUY_INPUT_EMPTY_ERROR = 'Enter a value'
exports.BUY_INPUT_ZERO_ERROR = 'Enter value greater than 0'
exports.BUY_INPUT_SKU_SELECT_ERROR = 'Select SKUs'
exports.GET_INPUT_PERCENTAGE_ERROR = 'Enter value less than 100'
exports.GET_INPUT_DECIMAL_ERROR = 'Only up to 2 decimal places are allowed'
exports.ATTRIBUTE_VALUES_QUERY = ({ name, page }) => {
  return {
    enableRules: false,
    page: page,
    maxValuesPerFacet: 1,
    distinct: true,
    hitsPerPage: 1000,
    facetFilters: [[`attributes.key:${name}`]],
    attributesToRetrieve: ['attributes'],
    attributesToHighlight: [],
  }
}

exports.ATTRIBUTE_KEYS_QUERY = {
  getRankingInfo: false,
  analytics: false,
  enableABTest: false,
  enableRules: false,
  page: 0,
  hitsPerPage: 10,
  maxValuesPerFacet: 1000,
  facets: ['attributes.key'],
  attributesToHighlight: [],
}

exports.LIMIT_TYPE = {
  ORDER: 'ORDER',
}

exports.PRICE_LIST_DROPDOWN_ERRORS = {
  SELECT_PRICELIST: 'Select atleast 1 price list',
  SAME_CURRENCY: 'All selected price lists must have the same currency',
}

exports.PRICE_KIND_MAP = {
  'base-price': 'BASE',
  'sale-price': 'SALE',
  'sale-base-price': 'SALE-BASE',
}
exports.ALL = 'ALL'

exports.SINGLE_MULTIUSE = {
  SINGLE_USE: 'isSingle',
  MULTI_USE: 'isMultiple',
}
