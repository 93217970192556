import filterTruthy from 'boolean-filter-obj'
import { isBefore } from 'date-fns'

export function getValidationErrors(fields = {}) {
  return filterTruthy({
    startDate: fields.startDate
      ? getDateValidations(fields, 'startDate')
      : 'Start date is required',
    startTime: !fields.startTime && 'Start time is required',
    base: getBaseValidations(fields) || false,
    sale: (fields.sale && getSaleValidations(fields)) || false,
    cost: (fields.cost && getCostValidations(fields)) || false,
  })
}

function getSaleValidations(prices) {
  if (
    Number.parseFloat(prices.sale) < 0 &&
    prices.sale != undefined &&
    prices.sale != ''
  ) {
    return 'Clearance Price should be greater than or equal to 0'
  }
  if (
    Number.parseFloat(prices.sale) >= Number.parseFloat(prices.base) &&
    Number.parseFloat(prices.sale) !== 0
  ) {
    return 'Clearance Price should not be equal or greater than Price'
  }
  return ''
}

function getBaseValidations(prices) {
  const { base, cost } = prices
  if (Number.parseFloat(base) < 0) {
    return 'Price should be greater than or equal to 0'
  }
  if (!base) {
    return 'Price value is required'
  }
  if (
    Number.parseFloat(cost) &&
    Number.parseFloat(base) < Number.parseFloat(cost)
  ) {
    return 'Price cannot be less than cost'
  }
  return ''
}

function getCostValidations(prices) {
  if (
    Number.parseFloat(prices.cost) < 0 &&
    prices.cost != undefined &&
    prices.cost != ''
  ) {
    return 'Cost should be greater than or equal to 0'
  }
  return ''
}

function getDateValidations(fields, type) {
  if (fields[type].length <= 0) {
    return `Please enter ${type} date`
  }
  if (type === 'startDate' && isBefore(fields.endDate, fields.startDate)) {
    return "Start Date can't be greater than End Date"
  }
  if (isBefore(fields[type], new Date())) {
    return type === 'startDate'
      ? "Start date can't be in past"
      : "End date can't be in past"
  }
  return false
}
