import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import CloseIcon from 'components/svg/close'
import {
  getFinalAttributeItemsDetails,
  getFinalCategoriesSkusCount,
  getFinalCollectionsSkusCount,
  getFinalSelectedSkusCount,
} from 'modules/promo/selectors'
import {
  DELETE_SKUS_AND_FETCH_NEW_ITEMS,
  UPDATE_PRODUCTS_AND_COUNT,
  DELETE_CATEGORIES_AND_FETCH_NEW_ITEMS,
  DELETE_COLLECTIONS_AND_FETCH_NEW_ITEMS,
  DELETE_ATTRIBUTE_AND_FETCH_NEW_ITEMS,
} from 'modules/promo/actions'
import { StyledProductChips } from './styles'

class ProductChips extends Component {
  onRemove = (type, value) => {
    const {
      targetType,
      onRemoveSKUs,
      onRemoveCategories,
      onRemoveAttributes,
      onRemoveCollections,
    } = this.props
    switch (type) {
      case 'SKUs':
        return onRemoveSKUs({ sku: value, targetType })
      case 'Categories':
        return onRemoveCategories({ id: value.id, targetType })
      case 'Collections':
        return onRemoveCollections({ id: value.id, targetType })
      case 'Attributes':
        return onRemoveAttributes({
          value: value.id,
          id: value.parent,
          name: value.parentName,
          targetType,
        })
    }
  }

  render() {
    const { list } = this.props
    return list.map(
      (child) => child.values.length > 0 && this.renderProductChips(child)
    )
  }

  getChips = (child) => {
    return child.values.length > 10 ? child.values.slice(0, 9) : child.values
  }

  getAttrSkusCount = (attributeItemsDetails) => {
    return attributeItemsDetails &&
      Object.keys(attributeItemsDetails).length > 0
      ? attributeItemsDetails.totalProducts
      : 0
  }

  getMoreSkusCount = (child, chips) => {
    return child.values.length === chips.length
      ? chips.length
      : child.values.length - chips.length
  }

  renderProductChips = (child) => {
    const { targetType, viewMode } = this.props
    const attributeItemsDetails =
      this.props[`${targetType}FinalAttributeItemsDetails`]
    const finalSelectedSkusCount =
      this.props[`${targetType}FinalSelectedSkusCount`]
    const categoriesSkusCount =
      this.props[`${targetType}FinalCategoriesSkusCount`]
    const collectionsSkusCount =
      this.props[`${targetType}FinalCollectionsSkusCount`]
    const chips = this.getChips(child)
    const moreSkusCount = this.getMoreSkusCount(child, chips)
    const showMoreSkus = moreSkusCount < child.values.length
    const attrSkusCount = this.getAttrSkusCount(attributeItemsDetails)

    return (
      <StyledProductChips
        targetType={targetType}
        disabled={this.props.disabled}
      >
        <div className='header' data-testid={`header-${child.key}`}>
          <span className='key'>
            {child.key === 'Categories' &&
              `${child.key} (${categoriesSkusCount} SKUs)`}
            {child.key === 'Collections' &&
              `${child.key} (${collectionsSkusCount} SKUs)`}
            {child.key === 'Attributes' &&
              `${child.key} (${attrSkusCount} SKUs)`}
            {child.key === 'SKUs' &&
              `${child.key} (${finalSelectedSkusCount} SKUs)`}
          </span>
          {!viewMode && (
            <div
              className='remove'
              onClick={() =>
                this.props.updateProducts({ key: child.key, targetType })
              }
              data-testid={`all-remove-${child.key}`}
            >
              {' '}
              Remove{' '}
            </div>
          )}
        </div>
        <div className='container' data-testid={`container-${child.key}`}>
          <ul>
            {chips.map((value) => (
              <li key={value.sku}>
                <span>{value.sku ? value.sku : value.title}</span>
                {!viewMode && (
                  <span
                    onClick={() => this.onRemove(child.key, value)}
                    data-testid={`remove-${value.sku ? value.sku : value.id}`}
                  >
                    <CloseIcon />
                  </span>
                )}
              </li>
            ))}
          </ul>
          {showMoreSkus && (
            <span
              className='more_skus'
              onClick={() =>
                this.props.showMore(
                  child.key === 'SKUs' ? 'All SKUs' : child.key
                )
              }
            >
              {' '}
              +{moreSkusCount} more{' '}
            </span>
          )}
        </div>
      </StyledProductChips>
    )
  }
}

export const mapStateToPropsProduct = (state) => {
  let typeState = {}
  const types = [
    'targetX',
    'targetY',
    'exclusion',
    state.promoReducer.targetType,
  ]
  types.forEach((type) => {
    typeState = {
      ...typeState,
      [`${type}FinalAttributeItemsDetails`]: getFinalAttributeItemsDetails(
        state,
        type
      ),
      [`${type}FinalCategoriesSkusCount`]: getFinalCategoriesSkusCount(
        state,
        type
      ),
      [`${type}FinalCollectionsSkusCount`]: getFinalCollectionsSkusCount(
        state,
        type
      ),
      [`${type}FinalSelectedSkusCount`]: getFinalSelectedSkusCount(state, type),
    }
  })
  return {
    ...typeState,
  }
}

export default connect(mapStateToPropsProduct, (dispatch) =>
  bindActionCreators(
    {
      onRemoveSKUs: DELETE_SKUS_AND_FETCH_NEW_ITEMS,
      updateProducts: UPDATE_PRODUCTS_AND_COUNT,
      onRemoveCategories: DELETE_CATEGORIES_AND_FETCH_NEW_ITEMS,
      onRemoveCollections: DELETE_COLLECTIONS_AND_FETCH_NEW_ITEMS,
      onRemoveAttributes: DELETE_ATTRIBUTE_AND_FETCH_NEW_ITEMS,
    },
    dispatch
  )
)(ProductChips)
