import styled, { css } from 'styled-components'

const sub_heading = css`
  ${(props) => props.theme.typography.label.css};
  color: ${(props) => props.theme.palette.blue.electric};
`

const section_heading = css`
  ${(props) => props.theme.typography.h4};
  color: ${(props) => props.theme.palette.text.charcoal};
  margin-bottom: 18px;
  ${(props) => props.disabled && { color: props.theme.palette.text.disabled }};
`
const product_links = css`
  ${(props) => props.theme.typography.label};
  color: ${(props) => props.theme.palette.blue.main};
  margin-top: 24px;
  cursor: pointer;
`

export const StyledPromoProducts = styled.div`
  border: 1px solid rgb(115, 127, 143, 0.2);
  padding: 32px;
  border-radius: 4px;
  .section_heading {
    ${section_heading}
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -10px;
    margin-bottom: 24px;
    ${(props) =>
      props.disabled && { color: props.theme.palette.text.disabled }};
  }
  .promo-radio-button {
    :first-child {
      .radio-label {
        padding-left: 0px;
      }
    }
  }
  .buttons {
    /* margin: -70px; */
    display: flex;
    column-gap: 20px;
    margin-right: -16px;
    .btn {
      height: 36px;
    }
  }
  .options {
    display: flex;
    flex-direction: column;
    .buttons {
      .btn_browse {
        ${(props) => props.theme.typography.label};
        color: ${(props) => props.theme.palette.blue.main};
        cursor: pointer;
        margin-top: 10px;
      }
    }
  }
  .sub_heading {
    ${sub_heading}
    margin-top: 32px;
    ${(props) =>
      props.disabled && { color: props.theme.palette.text.disabled }};
  }
  .radio_option_row {
    display: grid;
    grid-template-columns: 145px 145px 145px;
  }
  .products_link {
    ${product_links}
    ${(props) =>
      props.disabled && { color: props.theme.palette.text.disabled }};
  }

  .btn-wrapper {
    padding-top: 32px;
    .btn {
      border-radius: 2px;
      padding: 5px 20px;
    }
    .browse {
      margin-right: 20px;
    }
  }
`
export const StyledExclusionProducts = styled.div`
  .exclusion_products {
    padding: 35px 32px 45px 32px;
    .btn-wrapper {
      .browse_button {
        margin-left: 15px;
      }
      display: flex;
      justify-content: flex-end;
      margin-top: -30px;
    }
    .section_heading {
      ${(props) => props.theme.typography.h4}
      display:flex;
    }
  }
  .products_link {
    ${product_links}
  }
  .empty {
    height: 0px;
    background-color: ${(props) => props.theme.palette.common.white};
  }
`
export const StyledBuyProducts = styled.div`
  .buy_products {
    .btn-wrapper {
      display: flex;
      .upload {
        padding-left: 8px;
      }
      .browse_button {
        height: 32px;
      }
    }
    .section_heading {
      ${(props) => props.theme.typography.h4}
      display:flex;
    }
  }
  .products_link {
    ${(props) => props.theme.typography.buyGetLink};
    color: ${(props) => props.theme.palette.blue.main};
    ${(props) => props.disabled && { color: props.theme.palette.blue.disabled }}
    cursor: pointer;
    padding-top: 16px;
  }
  .empty {
    height: 0px;
    background-color: ${(props) => props.theme.palette.common.white};
  }
`
export const StyledProductChips = styled.div`
  margin-top: 24px;
  width: ${(props) => (props.targetType === 'exclusion' ? '100%;' : '460px;')}
    .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    .key {
      ${sub_heading}
      ${(props) =>
        props.disabled && { color: props.theme.palette.text.disabled }};
    }
    .remove {
      ${(props) => props.theme.typography.h6};
      color: ${(props) => props.theme.palette.blue.main};
      cursor: pointer;
      ${(props) =>
        props.disabled && { color: props.theme.palette.text.disabled }};
    }
  }
  .container {
    min-height: 56px;
    border: 1px solid ${(props) => props.theme.palette.text.separation};
    padding: 16px 8px;
    .more_skus {
      display: flex;
      ${(props) => props.theme.typography.h6};
      color: ${(props) => props.theme.palette.warn.main};
      align-items: center;
      width: 100%;
      justify-content: flex-end;
      cursor: pointer;
      ${(props) =>
        props.disabled && { color: props.theme.palette.text.disabled }};
      pointer-events: none;
    }
    ul {
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      li {
        border-radius: 28px;
        background-color: ${(props) => props.theme.palette.text.chip};
        padding: 4px 8px;
        margin: 6px;
        ${(props) => props.theme.typography.h6.css};
        color: ${(props) => props.theme.palette.text.charcoal};
        ${(props) =>
          props.disabled && {
            color: props.theme.palette.common.white,
            backgroundColor: '#A0A0A1',
            pointerEvents: 'none',
          }};

        svg {
          margin-left: 8px;
          width: 8px;
          height: 8px;
          cursor: pointer;
          path {
            fill: ${(props) => props.theme.palette.common.black};
            ${(props) =>
              props.disabled && {
                fill: props.theme.palette.common.white,
              }};
          }
        }
      }
    }
  }
`

export const StyledUploadWrapper = styled.div`
  p {
    font-size: 14px;
    span {
      color: ${(props) => props.theme.palette.grey.brand};
    }
  }
  .header {
    ${(props) => props.theme.typography.h4.css};
    display: flex;
    align-items: center;
    svg {
      margin-right: 18px;
    }
    color: ${(props) => props.theme.palette.black.mainUpdated};
  }
  .dropzone {
    align-items: center;
    section {
      margin-top: 30px;
      .button_wrapper {
        display: flex;
        align-items: center;
        color: ${(props) => props.theme.palette.text.label};
        button {
          text-transform: capitalize;
          margin-right: 30px;
        }
      }
      .file_error {
        ${(props) => props.theme.typography.h5};
        color: ${(props) => props.theme.palette.error.main};
      }
      .file_name {
        word-break: break-all;
      }
    }
  }
  .template_here {
    font-weight: bold;
    a {
      color: ${(props) => props.theme.palette.common.black};
    }
    span {
      color: ${(props) => props.theme.palette.blue.main};
    }
  }
  .button-container {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    button {
      padding: 4px 27px;
    }
    .cancel {
      margin-right: 10px;
      color: ${(props) => props.theme.palette.common.black};
    }
    .cancel_btn {
      margin-right: 10px;
      border: none;
      &:hover {
        background: ${(props) => props.theme.palette.common.white};
        color: ${(props) => props.theme.palette.common.black};
        border: none;
      }
    }
  }
  .sku-details-section {
    margin-bottom: 24px;
    padding-top: 15px;
  }
`
export const StyledTooltip = styled.p`
  margin-bottom: -14px;
  margin-top: 0px;
`
