import React, { useState, useEffect } from 'react'
import getSymbolFromCurrency from 'currency-symbol-map'
import { DETAIL_CONSTANTS, getSectionTitle } from '../helper'
import { getAvailableEligiblePriceList } from 'src/api/product'
import Field from 'src/components/view/field'
import {
  StyledPromoTitle,
  StyledFromToDate,
  StyledGenerateCodes,
} from './styles'
import RenderDate from 'src/components/view/render-date'

const PromoInfo = ({
  isCoupon,
  promotion,
  selectedExplicitOption,
  selectedEligiblePriceList,
}) => {
  const { title, startDate, endDate, promoCodes } = promotion
  const {
    couponTitle,
    promoTitle,
    startDate: startDateHeading,
    endDate: endDateHeading,
    couponUsuage,
    couponCode,
    singleUse,
    multipleUse,
    priceList,
  } = DETAIL_CONSTANTS
  const [selectedPriceList, setSelectedPriceList] = useState([])
  let generateCode
  const code = promoCodes && promoCodes.length >= 1 ? promoCodes[0] : ''
  if (code.length > 3) {
    const values = code.match(/[^\d]+|\d+/g)
    if (values.length > 1) {
      generateCode = {
        prefix: values[0],
        start: values[1],
        count: promoCodes.length,
      }
    }
  }

  const fetchPriceList = async () => {
    const {
      data: { data: priceData },
    } = await getAvailableEligiblePriceList({ offset: 0, notExpired: true })
    const list = []
    priceData?.forEach((singleList) => {
      if (selectedEligiblePriceList?.includes(singleList?.priceListId)) {
        list.push(
          `${singleList.name} - ${getSymbolFromCurrency(singleList.currency)} ${
            singleList.currency
          }`
        )
      }
    })
    setSelectedPriceList(list)
  }

  useEffect(() => {
    if (selectedEligiblePriceList.length > 0) {
      fetchPriceList()
    }
  }, [selectedEligiblePriceList])

  return (
    <div className='content_wrapper_25'>
      <StyledPromoTitle>
        <div className='section_heading'>
          {getSectionTitle('PROMO_DETAIL_TITLE', isCoupon)}
        </div>
        <Field title={isCoupon ? couponTitle : promoTitle} value={title} />
        <StyledFromToDate>
          <div className='promo_active_dates'>
            <div className='dates_row'>
              <RenderDate label={startDateHeading} value={startDate} />
              <RenderDate label={endDateHeading} value={endDate} />
            </div>
          </div>
        </StyledFromToDate>
        {isCoupon && (
          <>
            <Field
              title={couponUsuage}
              value={
                selectedExplicitOption === 'isSingle' ? singleUse : multipleUse
              }
            />
            {selectedExplicitOption === 'isSingle' && generateCode ? (
              <StyledGenerateCodes>
                <span className='span_title'>Generate codes</span>
                <div className='promos_container'>
                  <div className='row_space_between'>
                    <Field
                      noMargin
                      title='Prefix'
                      value={generateCode.prefix}
                    />
                    <Field
                      noMargin
                      title='Starting number'
                      value={generateCode.start}
                    />
                    <Field
                      noMargin
                      title='Total coupon count'
                      value={generateCode.count}
                    />
                  </div>
                </div>
              </StyledGenerateCodes>
            ) : (
              <Field title={couponCode} value={promoCodes} />
            )}
          </>
        )}
        <Field title={priceList} value={selectedPriceList} />
      </StyledPromoTitle>
    </div>
  )
}

export default PromoInfo
