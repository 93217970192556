import { format, parseISO } from 'date-fns'
import { StyledPreview, StyledDate, StyledHistory } from './styles'
import PlaceholderIcon from 'components/svg/product-placeholder'
import { ellipsis, isPriceUpcoming, isPriceActive } from 'lib/utils/helper'
import getSymbolFromCurrency from 'currency-symbol-map'
import { useNavigate } from 'react-router-dom'

const computePriceRange = (rowDetails, type) => {
  /*
    Checking for 0 price here otherwise it will be considered as false value
    and "else" block will be returned resulting in a - rendered instead of 0
  */
  if (
    rowDetails.price &&
    (rowDetails.price[type] || rowDetails.price[type] === 0)
  ) {
    return (
      <div className='variant-price'>
        {getSymbolFromCurrency(rowDetails.price.currency)}
        {rowDetails.price[type]}
      </div>
    )
  } else {
    return <div className='variant-price'>-</div>
  }
}
const computeConditionalPriceRange = (isChild, rowDetails, type, isSale) => {
  if (!isChild && rowDetails.items && rowDetails.items.length) {
    if (rowDetails[`${type}PriceRange`]) {
      return (
        <div className='variant-price'>
          {renderPriceRange(rowDetails[`${type}PriceRange`])}
        </div>
      )
    } else {
      return isSale ? (
        computePriceRange(rowDetails, type)
      ) : (
        <div className='variant-price'>-</div>
      )
    }
  } else {
    return computePriceRange(rowDetails, type)
  }
}
export const renderProductAndVariantData = ({
  rowDetails,
  isChild,
  type,
  render,
}) => {
  if (!rowDetails.hasLink) {
    if (render) {
      if (
        type !== 'sale' ||
        (rowDetails.hasOwnProperty('kind') &&
          rowDetails.kind.name === 'Clearance')
      ) {
        return computeConditionalPriceRange(isChild, rowDetails, type, false)
      } else {
        return computeConditionalPriceRange(isChild, rowDetails, type, true)
      }
    }
    return <div />
  }
  return <div />
}

export const calculateStatus = (rowDetails) => {
  if (computePriceRange(rowDetails, 'base').props.children === '-') return '-'
  if (rowDetails.children && rowDetails.children.length > 0) {
    rowDetails.startDate =
      rowDetails?.price?.startDate ||
      (rowDetails.noVariants && rowDetails.startDate)
    rowDetails.endDate =
      rowDetails?.price?.endDate ||
      (rowDetails.noVariants && rowDetails.endDate)
  }
  if (isPriceActive(rowDetails)) return 'Current'
  else {
    if (isPriceUpcoming(rowDetails)) {
      return 'Upcoming'
    } else {
      return 'Expired'
    }
  }
}
const getVariantTitle = (variant) => {
    if (variant.variants.length > 0) {
      let title = []
      variant?.variants?.length &&
        variant?.variants?.forEach((item) => title.push(item.value))
      return title.filter(Boolean).join('/')
    }
    return variant.title
  },
  renderStatus = (rowDetails) => {
    const navigate = useNavigate();
    if (rowDetails) {
      return !rowDetails.hasLink ? (
        <div className='status'>
          <div
            className='status-label'
            data-testid={`price-status-${rowDetails._id}`}
          >
            {calculateStatus(rowDetails)}
          </div>
        </div>
      ) : (
        <StyledHistory>
          <div
            onClick={() =>
              navigate(rowDetails.link, {
                state: { variant: rowDetails },
              })
            }
            className='history'
            data-testid={rowDetails['data-testid']}
          >
            <div />
            <a className='history_title' href=''>
              View history
            </a>
          </div>
        </StyledHistory>
      )
    } else {
      return <div>-</div>
    }
  },
  renderImage = (content, render) => {
    if (render) {
      return content.length > 0 ? (
        <StyledPreview url={content[0].source[0].url} />
      ) : (
        <PlaceholderIcon />
      )
    }
    return null
  },
  renderPriceRange = ({ min, max }) => {
    if (min && max && max > min) return `$${min} - $${max}`
    if (min && min === max) return `$${min}`
    if (!min && max) return `$${max}`
    return `-`
  },
  renderPrice = ({ rowDetails, isChild, type, render }) => {
    return renderProductAndVariantData({ rowDetails, isChild, type, render })
  },
  renderTitle = ({ rowDetails, isChild, render }) => {
    if (render) {
      if (isChild)
        return (
          <div
            data-title={getVariantTitle(rowDetails)}
            data-testid={`title-${rowDetails._id}`}
            className='variant-column'
          >
            {ellipsis(getVariantTitle(rowDetails), 50)}
          </div>
        )
      return (
        <div
          data-title={rowDetails.title}
          data-testid={`title-${rowDetails._id}`}
          className='variant-column'
        >
          {ellipsis(rowDetails.title, 25)}
        </div>
      )
    }
    return null
  },
  renderDate = (content, hasLink = false) => {
    if (!hasLink) {
      if (content) {
        const parsedDate = parseISO(content)
        return (
          <StyledDate>
            <div className='date'>{format(parsedDate, 'MMM d yyyy ')}</div>
            <div className='time'>{format(parsedDate, 'hh:mm a')}</div>
          </StyledDate>
        )
      } else {
        return <>-</>
      }
    } else {
      return null
    }
  }
const children = [
  {
    title: '',
    accessor: 'image',
    isSortable: false,
    render: (details) => {
      return renderImage(details.images, false)
    },
  },
  {
    title: 'SKU ID',
    isSortable: false,
  },
  {
    title: 'Title',
    isSortable: false,
    accessor: 'title',
    render: () => null,
  },
  {
    title: 'Cost',
    isSortable: false,
    accessor: '',
    render: (details) => (
      <div data-testid={`cost-${details._id}`}>
        {' '}
        {renderPrice({
          rowDetails: { ...details },
          type: 'cost',
          render: true,
        })}
      </div>
    ),
  },
  {
    title: 'Base Price',
    isSortable: false,
    accessor: ' ',
    render: (details) => (
      <div data-testid={`base-${details._id}`}>
        {' '}
        {renderPrice({
          rowDetails: { ...details },
          type: 'base',
          render: true,
        })}
      </div>
    ),
  },
  {
    title: 'Clearance price',
    isSortable: false,
    accessor: '',
    render: (details) => (
      <div data-testid={`sale-${details._id}`}>
        {' '}
        {renderPrice({
          rowDetails: { ...details },
          type: 'sale',
          render: true,
        })}
      </div>
    ),
  },
  {
    title: 'Start date & Time',
    isSortable: false,
    accessor: 'startDate',
    render: (details) => (
      <div data-testid={`startDate-${details._id}`}>
        {' '}
        {renderDate(details.startDate, details.hasLink)}
      </div>
    ),
  },
  {
    title: 'End date & Time',
    isSortable: false,
    accessor: 'endDate',
    render: (details) => (
      <div data-testid={`endDate-${details._id}`}>
        {' '}
        {renderDate(details.endDate, details.hasLink)}
      </div>
    ),
  },
  {
    title: 'Status',
    isSortable: false,
    accessor: '',
    render: (details) => renderStatus({ ...details }),
  },
]

const HEADERS_FOR_PRODUCTS = (isVariable = false) => {
  let headers = [
    {
      title: 'Cost',
      isSortable: false,
      accessor: '',
      render: (details) =>
        renderPrice({
          rowDetails: { ...details },
          type: 'cost',
          render: true,
        }),
    },
    {
      title: 'Base Price',
      isSortable: false,
      accessor: ' ',
      render: (details) =>
        renderPrice({
          rowDetails: { ...details },
          type: 'base',
          render: true,
        }),
    },
    {
      title: 'Clearance price',
      isSortable: false,
      accessor: '',
      render: (details) =>
        renderPrice({
          rowDetails: { ...details },
          type: 'sale',
          render: true,
        }),
    },
    {
      title: 'Start date & Time',
      isSortable: false,
      accessor: 'startDate',
      render: (details) => (
        <div data-testid={`startDate-${details._id}`}>
          {' '}
          {renderDate(
            (details.price && details.price.startDate) ||
              (details.noVariants && details.startDate)
          )}
        </div>
      ),
    },
    {
      title: 'End date & Time',
      isSortable: false,
      accessor: 'endDate',
      render: (details) => (
        <div data-testid={`endDate-${details._id}`}>
          {' '}
          {renderDate(
            (details.price && details.price.endDate) ||
              (details.noVariants && details.endDate)
          )}
        </div>
      ),
    },
    {
      title: 'Status',
      isSortable: false,
      accessor: '',
      render: (details) => renderStatus({ ...details }),
    },
  ]
  if (isVariable) {
    headers = HEADERS_FOR_VARIABLE_PRODUCTS.concat(headers)
  }
  return headers
}

const HEADERS_FOR_VARIABLE_PRODUCTS = [
  {
    title: '',
    accessor: 'image',
    isSortable: false,
    render: (details) => renderImage(details.images, !details.noVariants),
    children: [...children],
  },
  {
    title: 'SKU ID',
    isSortable: false,
    render: (details) => (details.noVariants ? null : details.sku),
  },
  {
    title: 'Title',
    isSortable: false,
    accessor: 'title',
    render: (details) =>
      renderTitle({
        rowDetails: { ...details },
        isChild: false,
        render: !details.noVariants,
      }),
  },
]

export { HEADERS_FOR_PRODUCTS }
