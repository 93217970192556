import React, { Suspense } from 'react'
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider, Outlet, Navigate } from 'react-router-dom';
import { Empty } from '@teamfabric/copilot-ui'
import ProductList from 'modules/price/product-list'
import Product from 'modules/price/product'
import Variant from 'modules/price/variant'
import PromoView from 'modules/promo/view'
import SideNavigation from 'components/navigation'
import { hasPermission } from 'src/api/rolePermissions'
import { READ_PERMISSIONS, PERMISSION_ERROR } from 'src/lib/constants/constants'
import { isFeatureActive } from 'src/api/featureFlag'
import { FEATURE_FLAGS } from 'src/general-config'

const Settings = React.lazy(() => import('modules/settings'))
const BulkImportHistory = React.lazy(() => import('modules/price/bulk-import'))
const PromoListing = React.lazy(() => import('modules/promo/list'))
const PromoCreate = React.lazy(() => import('modules/promo/create'))
const PromoEdit = React.lazy(() => import('modules/promo/edit'))
const PromoClone = React.lazy(() => import('modules/promo/clone'))
const GlobalExclusionsList = React.lazy(() =>
  import('modules/global-exclusions/list')
)
const GlobalExclusionsCreate = React.lazy(() =>
  import('modules/global-exclusions/create')
)
const GlobalExclusionsEdit = React.lazy(() =>
  import('modules/global-exclusions/edit')
)
const CustomerSegments = React.lazy(() =>
  import('src/modules/settings/customer-segments')
)
const Redemption = React.lazy(() =>
  import('src/modules/promo/redemption/redemption')
)
const RedemptionExportHistory = React.lazy(() =>
  import(
    'src/modules/promo/redemption/redemption-export-history/redemption-export-history'
  )
)

const DateTimePicker = React.lazy(() => import('src/components/date-picker'))
const AppContent = () => {
  return (
    <div className='app_root'>
      <main className='d-flex w-100 app_wrapper'>
        <SideNavigation />
        <div className='app_children'> <Outlet /></div>
      </main>
    </div>
  )
}

export const routes = createRoutesFromElements(
  <Route element={<AppContent path="/" />}>
    {/* product listing pages */}
            <Route path='/offers/price/product-list' element={<ProductList />} />
            <Route path='/offers/price/bulk' element={<BulkImportHistory />} />
            <Route path='/offers/price/product-list/:keyword' element={<ProductList />} />
            <Route path='/offers/price/variant/:variantId/:priceListId' element={<Variant />} />
            <Route path='/offers/price/product/:productId/:priceListId' element={<Product />} />

            {/* Promo & coupon pages */}
            <Route path='/offers/promo-list' element={<PromoListing />} />
            <Route path='/offers/coupon-import-history' element={<BulkImportHistory type={'coupon'} />} />
            <Route path='/offers/promo-edit/:promoId' element={<PromoEdit />} />
            <Route path='/offers/promo/:promoId' element={<PromoView />} />
            <Route path='/offers/promo-create' element={<PromoCreate />} />
            <Route path='/offers/coupon-list' element={<PromoListing type={'coupon'} />} />
            <Route path='/offers/coupon-edit/:couponId' element={<PromoEdit />} />
            <Route path='/offers/coupon/:couponId' element={<PromoView />} />
            <Route path='/offers/coupon-clone/:couponId' element={<PromoClone />} />
            <Route path='/offers/coupon-create' element={<PromoCreate />} />


            {/* Redemption pages */}
            <Route path='/offers/coupon/redemption' element={<Redemption />} />
            <Route path='/offers/coupon/redemption/exports-history' element={<RedemptionExportHistory />} />

             {/* global exclusions pages */}
            <Route path='/offers/settings/exclusions-list' element={<GlobalExclusionsList />} />
            <Route path='/offers/settings/exclusions-create' element={<GlobalExclusionsCreate />} />
            <Route path='/offers/settings/exclusions-edit/:skuListId' element={<GlobalExclusionsEdit />} />
            <Route path='/offers/settings/exclusions/:skuListId' element={<GlobalExclusionsEdit viewMode />} />

            {/* segments & pricelists page */}
            <Route path='/offers/settings/customer-segments' element={<CustomerSegments />} />
            <Route path='/offers/settings/price-list' element={<Settings />} />
 
             {/* redirect routes */}
            <Route path='/offers' element={<Navigate replace to='/offers/price/product-list' />} />
  </Route>
);

const router = createBrowserRouter(routes);

const AppRoutes = ({ user }) => {
  const active = isFeatureActive({ flagName: FEATURE_FLAGS.DS4 })
  if (!hasPermission(READ_PERMISSIONS.READ_OFFERS)) {
    return (
      <Empty
        className='empty'
        primaryText={PERMISSION_ERROR.OFFER_ERROR}
        secondaryText={PERMISSION_ERROR.SECONDARY_TEXT}
      />
    )
  }
  return (
    <main id='app_root' data-testid='ds3-app'>
      <Suspense fallback={() => <div>Loading</div>}>
       <RouterProvider router={router} />
        {!active && <DateTimePicker />}
      </Suspense>
    </main>
  )
}

export default AppRoutes
