import React from 'react'
import { format, isValid, parseISO } from 'date-fns'
import Field from './field'
import { StyledDate } from './styles'

const RenderDate = ({ label, value }) => {
  const dateValue = value ? getParsedDate(value) : 'N/A'
  return (
    <StyledDate>
      <div className='date-time'>
        <Field noMargin title={label} value={dateValue} />
      </div>
    </StyledDate>
  )
}

const getParsedDate = (date) => {
  const parsedDate = isValid(date) ? date : parseISO(date)
  return `${format(parsedDate, 'MMM d, yyyy')} ${format(parsedDate, 'hh:mm a')}`
}

export default RenderDate
