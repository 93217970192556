import styled from 'styled-components'

export const StyledWrapper = styled.div`
  display: flex;
  width: 100%;
  .snack-bar {
    position: absolute;
    margin-left: 200px;
    margin-top: -35px;
  }
  .view-title {
    color: ${(props) => props.theme.palette.blue.electric};
    width: 100%;
    font-size: 13px;
    font-weight: normal;
    margin-bottom: 12px;
  }
  .view-value {
    width: 100%;
    font-size: 13px;
    font-weight: normal;
  }
  .padding8 {
    padding-bottom: 8px;
  }
  .margin_24 {
    margin-top: 24px;
  }
`

export const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  .heading {
    display: flex;
    justify-content: space-between;
    ${(props) => props.theme.typography.h4.css};
    .status {
      margin-left: 20px;
    }
  }
`
