import styled from 'styled-components'

export const StyledLoadingState = styled.div`
  width: 100%;
  .ls_content {
    display: flex;
    background-color: #fff;
    margin-top: 20px;
    padding: 30px;
    .ls_left {
      width: 70%;
      padding-right: 10px;

      .ls_text {
        margin-top: 20px;
        margin-bottom: 40px;
      }

      .ls_card {
        border: 1px solid rgba(115, 127, 143, 0.4);
        border-radius: 4px;
        padding: 20px 20px 50px 20px;

        &.ls_card2 {
          margin-top: 16px;
          padding: 20px;
          .ls_card2_top {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .ls_card2_bottom {
            display: flex;
            align-items: center;
            margin-top: 30px;
            .ls_card2_bottom_btn {
              margin: 0 16px;
            }
          }
          .ls_card3_btn {
            margin-top: 50px;
          }
          .ls_card3_btn2 {
            margin-top: 40px;
          }
        }
        &.ls_card3 {
          padding: 20px 20px 50px 20px;
        }
        .ls_card1_btn {
          margin-top: 26px;
        }
      }
    }
    .ls_right {
      width: 30%;
      background-color: #fff;
      padding: 0 16px 16px 16px;
      align-self: flex-start;
      .ls_right_content {
        margin-top: 90px;
        border: 1px solid rgba(115, 127, 143, 0.4);
        border-radius: 4px;
        padding: 16px 16px 50px 16px;
        .ls_right_btn {
          margin: 12px 0;
        }
      }
    }
  }
`
