import styled from 'styled-components'

export const StyledModal = styled.div`
  position: fixed;
  z-index: ${props => props.theme.zIndex.hoverOver};
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.palette.backdrop.main};
  display: flex;
  justify-content: center;
  align-items: center;
  .modal_content {
    border-radius: 6px;
    position: relative;
    background: ${props => props.theme.palette.common.white};
    color: ${props => props.theme.palette.common.black};
    padding: ${props => (!props.noPadding ? '24px' : '0px')};
    width: ${props => (props.width ? props.width : '480')}px;
    min-height: 24px;
    z-index: ${props => props.theme.zIndex.modal};
    .close-container {
      cursor: pointer;
      position: absolute;
      top: 16px;
      right: 16px;
    }
  }

  .seperator {
    width: 100%;
    height: 2px;
    background-color: ${props => props.theme.palette.text.seperator};
    margin-top: 16px;
  }
`
