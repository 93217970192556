export const getSectionTitle = (section, isCoupon) => {
  const {
    PROMO_DETAIL,
    COUPON_DETAIL,
    PROMO_TYPE,
    COUPON_TYPE,
    CUSTOMER_SEGMENTS,
    COUPON_LIMIT,
  } = promoSections
  switch (section) {
    case 'PROMO_DETAIL_TITLE':
      return isCoupon ? COUPON_DETAIL : PROMO_DETAIL
    case 'PROMO_TYPE_TITLE':
      return isCoupon ? COUPON_TYPE : PROMO_TYPE
    case 'CUSTOMER_SEGMENTS_TITLE':
      return CUSTOMER_SEGMENTS
    case 'COUPON_LIMIT':
      return COUPON_LIMIT
  }
}

const promoSections = {
  PROMO_DETAIL: 'Promotion details',
  COUPON_DETAIL: 'Coupon details',
  PROMO_TYPE: 'Promotion type',
  COUPON_TYPE: 'Coupon type',
  CUSTOMER_SEGMENTS: 'Customer segments',
  COUPON_LIMIT: 'Coupon Limit',
}

export const DETAIL_CONSTANTS = {
  promoTitle: 'Promotion title',
  couponTitle: 'Coupon title',
  couponStackable: 'Coupon Stackable',
  couponUsuage: 'Coupon Usuage',
  couponCode: 'Coupon code',
  startDate: 'Start date',
  endDate: 'End date',
  priceList: 'Price list',
  singleUse: 'Single-use',
  multipleUse: 'Multiple-use',
}

export const TYPE_CONSTANTS = {
  promoApplied: 'Promotion applied to',
  couponApplied: 'Coupon applied to',
  customerBuys: 'Customer Buys',
  customerGets: 'Customer Gets',
}

export const SEGMENT_CONSTANTS = {}

export const PROMO_TYPES = {
  Quantity: 'SKU quantity',
  'SKU(s)': 'SKUs',
  'Buy-Get': 'Buy X Get Y',
}

export const PromotionRadioTypeNames = {
  SKU: 'SKU(s)',
  CART_VALUE: 'Cart Value',
  QUANTITY: 'Quantity',
  BUY_GET: 'Buy-Get',
}

export const GetRadioTypeNames = {
  SKU: 'SKU',
  CART: 'Cart',
  SHIPPING: 'Shipping',
}

export const GetTypeNames = {
  SKU: 'SKU',
  CART: 'CART',
  SHIPPING: 'SHIPPING',
}

export const BuyGetSpendNames = {
  BUY: 'buy',
  GET: 'get',
  SPEND: 'spend',
}

export const BuyRadioTypeNames = {
  SKU: 'SKUs',
  BUNDLE: 'Bundle',
  CART_VALUE: 'Cart Value',
}

export const getOperatorValue = (name) => {
  switch (name) {
    case 'OR': {
      return 'SKUs'
    }
    case 'AND': {
      return 'Bundle'
    }
    default: {
      return 'Cart Value'
    }
  }
}

export const getTargetValue = (skuCondition) => {
  switch (skuCondition) {
    case '*': {
      return 'All'
    }
    case 'INCLUDE': {
      return 'Include'
    }
    case 'EXCLUDE': {
      return 'Exclude'
    }
  }
}

export const getTargetAudienceLabel = (key) => {
  switch (key) {
    case 'All':
      return 'All'

    case 'USER_SEGMENT':
      return 'Segment'
  }
}

export const getProductSelectionLabel = (key) => {
  switch (key) {
    case 'ALL_PRODUCTS':
      return 'All SKUs'

    case 'EXCLUDE_PRODUCTS':
      return 'Exclude SKUs'

    case 'INCLUDE_PRODUCTS':
      return 'Include SKUs'

    default:
      return key
  }
}

export const formattedValue = (selectedType, selectedValue) => {
  if (selectedType !== 'Percentage off') {
    return `$ ${selectedValue}`
  } else {
    return `${selectedValue} %`
  }
}
