import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import UpCapIcon from 'components/svg/cap-up-fill-icon'
import DownCapIcon from 'components/svg/cap-down-fill-icon'
import { Checkbox } from '@teamfabric/copilot-ui'
import Spinner from 'components/spinner'
import { getGroupTree } from 'src/api/product'
import {
  ADD_TO_TEMP_SELECTED_COLLECTIONS,
  CHECK_COLLECTION_AND_FETCH_NEW_ITEMS,
  SET_COLLECTIONS_PATH,
} from 'modules/promo/actions'
import {
  getPromotion,
  getTempSelectedCollections,
  getCollectionsPath,
  getAllState,
} from 'modules/promo/selectors'
import {
  StyledRows,
  StyledCategories,
  StyledChildren,
  StyledChild,
} from '../categories/styles'
import { mergeDeep } from 'src/modules/promo/helper/categories'
const SPACEBAR_KEY = 32

const Collections = (props) => {
  const { targetType, collectionsPath, viewMode } = props
  const [collections, setCollections] = useState([])
  const [collectionDropdown, setCollectionDropdown] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let ignore = false
    const fetchCollections = async () => {
      setIsLoading(true)
      try {
        const { data } = await getGroupTree('collection')
        if (!ignore) {
          setCollections(data)
          setIsLoading(false)
        }
      } catch (error) {
        console.log('==================error', error)
      }
    }
    fetchCollections()
    return () => {
      ignore = true
    }
  }, [])

  useEffect(() => {
    const selectedCollections = props[`${targetType}SelectedCollections`]
    props.setTempSeletedCollections({
      collections: mergeDeep(getCollectionsState(), selectedCollections),
      targetType,
    })
  }, [collections])

  const getCollectionsState = () => {
    let catState = {}
    let collectionsPath = {}
    collections?.forEach((collection) => {
      if (collection.children && collection.children.length > 0) {
        let collectionObj =
          collection &&
          createCollectionState({
            collection,
            isRoot: true,
            totalCount: collection.children.length,
          })
        collectionsPath[collection._id] = collection._id
        catState[collection._id] = {
          ...collectionObj,
          ...createCollectionsState(
            collection,
            collectionsPath,
            collection._id,
            collection.parentId,
            collectionObj
          ),
        }
      } else {
        collectionsPath[collection._id] = collection._id
        catState[collection._id] = createCollectionState({
          collection,
          isChild: true,
          totalCount: collection.children.length,
        })
      }
    })

    props.setCollectionsPath({
      ...props.collectionsPath,
      ...collectionsPath,
    })
    return catState
  }

  const createCollectionsState = (
    collection,
    collectionsPath,
    parentId = '',
    parentParentId = '',
    catState = {}
  ) => {
    let newCatState = {}
    if (collection.children.length === 0) {
      let cat = createCollectionState({
        collection,
        parentId,
        parentParentId: catState.parentId,
        isChild: true,
        totalCount: collection.children.length,
      })
      collectionsPath[collection._id] = cat.parentId + '.' + collection._id
      return cat
    }

    collection.children.map((collectionChild) => {
      if (collectionChild.children && collectionChild.children.length > 0) {
        let catObj = createCollectionState({
          collection: collectionChild,
          parentId,
          parentParentId: catState.parentId,
          totalCount: collectionChild.children.length,
        })
        newCatState[collectionChild._id] = {
          ...catObj,
          ...createCollectionsState(
            collectionChild,
            collectionsPath,
            collectionChild._id,
            parentParentId,
            catObj
          ),
        }
        collectionsPath[collectionChild._id] =
          catObj.parentId + '.' + collectionChild._id
      } else {
        let catObj = createCollectionState({
          collection: collectionChild,
          parentId,
          parentParentId: catState.parentId,
          isChild: true,
          totalCount: collectionChild.children.length,
        })
        newCatState[collectionChild._id] = catObj
        collectionsPath[collectionChild._id] =
          catObj.parentId + '.' + collectionChild._id
      }
    })
    return newCatState
  }

  const createCollectionState = ({
    collection,
    parentId = '',
    parentParentId = '',
    isRoot = false,
    isChild = false,
    totalCount = 0,
  }) => {
    let isChecked = false
    let isPartialChecked = false
    let count = 0

    const parentID = () => {
      return parentParentId.length > 0
        ? parentParentId + '.' + parentId
        : parentId
    }

    return {
      isChecked: isChecked,
      parentId: isRoot ? '' : parentID(),
      firstParent: parentId,
      isChild: isChild,
      name: collection.name,
      count: count,
      totalCount,
      isPartialChecked,

      path: getCollectionPath(collection),
      _id: collection._id,
    }
  }

  const getCollectionPath = (collection) => {
    let collectionsPath
    for (let i = 0; i < collections.length; i++) {
      let parent = collections[i].name
      if (collections[i]._id === collections._id) {
        collectionsPath = parent
        return collectionsPath
      }
      collectionsPath = getDepthPath(collection._id, collections[i])
      if (collectionsPath) {
        collectionsPath = uniq(collectionsPath)
        collectionsPath.unshift(parent)
        collectionsPath = collectionsPath.join(' / ')
        break
      }
    }
    return collectionsPath
  }

  const getDepthPath = (id, arr) => {
    if (id === arr._id) {
      return [arr.name]
    } else {
      const { children } = arr || []
      for (let j = 0; j < children.length; j++) {
        const path = getDepthPath(id, children[j])
        if (Array.isArray(path)) {
          path.unshift(children[j].name)
          return path
        }
      }
    }
  }

  const getCollections = () => {
    return collections?.map((collection) => {
      return (
        <StyledRows key={collection._id}>
          {collection.children.length > 0 ? (
            <StyledChildren>
              {renderChild(collection, 0)}
              {renderCollection(collection, 1)}
            </StyledChildren>
          ) : (
            <StyledChildren>{renderChild(collection, 0, true)}</StyledChildren>
          )}
        </StyledRows>
      )
    })
  }
  const renderCollection = (collection, n) => {
    if (collection.children.length === 0) {
      return renderChild(collection, n, true)
    }
    return collectionDropdown[collection.name]
      ? collection.children.map((childCategory) => {
          return (
            <StyledChildren key={childCategory._id}>
              {childCategory.children.length > 0 ? (
                <StyledChildren>
                  {renderChild(childCategory, n)}
                  <StyledChildren>
                    {renderCollection(childCategory, n + 1)}
                  </StyledChildren>
                </StyledChildren>
              ) : (
                renderCollection(childCategory, n)
              )}
            </StyledChildren>
          )
        })
      : []
  }

  const renderChild = (collection, n, isChild = false) => {
    const selectedCategories = props[`${targetType}SelectedCollections`]
    const isAlreadyPresentCategory = get(
      selectedCategories,
      `${collectionsPath[collection._id]}`
    )
    const isAlreadyPresentOtherTypeCategory = get(
      {},
      `${collectionsPath[collection._id]}`
    )
    return (
      <StyledChild padding={n}>
        <div className='left'>
          <div className='checkbox'>
            {!isChild ? renderHideOrShowCollections(collection) : renderSpace()}
            <Checkbox
              disabled={
                viewMode ||
                (isAlreadyPresentOtherTypeCategory
                  ? isAlreadyPresentOtherTypeCategory.isChecked ||
                    isAlreadyPresentOtherTypeCategory.isPartialChecked
                  : false)
              }
              checked={
                isAlreadyPresentOtherTypeCategory &&
                (isAlreadyPresentOtherTypeCategory.isChecked ||
                  isAlreadyPresentOtherTypeCategory.isPartialChecked)
                  ? true
                  : isAlreadyPresentCategory
                  ? isAlreadyPresentCategory.isChecked ||
                    isAlreadyPresentCategory.isPartialChecked
                  : false
              }
              isPartial={
                isAlreadyPresentOtherTypeCategory &&
                isAlreadyPresentOtherTypeCategory.isPartialChecked
                  ? true
                  : isAlreadyPresentCategory
                  ? isAlreadyPresentCategory.isPartialChecked
                  : false
              }
              onChange={() => {
                props.onCheckCollection({
                  id: collection._id,
                  targetType,
                  type: 'collection',
                })
              }}
              label={collection.name}
              otherProps={{ 'data-testid': `checkbox-${collection._id}` }}
            />
          </div>
        </div>
      </StyledChild>
    )
  }
  const renderSpace = () => <div className='no-checkbox' />

  const renderHideOrShowCollections = (collection) => {
    return (
      <div
        className='icon'
        tabIndex='0'
        onClick={() => {
          const dropdown = {
            ...collectionDropdown,
            [collection.name]: !collectionDropdown[collection.name],
          }
          setCollectionDropdown(dropdown)
        }}
        onKeyDown={(e) =>
          e.keyCode === SPACEBAR_KEY &&
          setCollectionDropdown({
            ...collectionDropdown,
            [collection.name]: !collectionDropdown[collection.name],
          })
        }
        data-testid={`toggle-${collection._id}`}
      >
        {collectionDropdown[collection.name] ? (
          <UpCapIcon fill='#0D62FF' />
        ) : (
          <DownCapIcon fill='#121213' />
        )}
      </div>
    )
  }
  return (
    <StyledCategories
      isListingModal={props.isListingModal}
      data-testid='collections-list'
    >
      {isLoading ? (
        <div className='spinner'>
          <Spinner />
        </div>
      ) : (
        <div className='categories'>{collectionsPath && getCollections()}</div>
      )}
    </StyledCategories>
  )
}

const mapStateToProps = (state) => {
  let typeState = {}
  const types = [
    'targetX',
    'targetY',
    'exclusion',
    state.promoReducer.targetType,
  ]
  types.forEach((type) => {
    typeState = {
      ...typeState,
      [`${type}SelectedCollections`]: getTempSelectedCollections(state, type),
      collectionsPath: getCollectionsPath(state),
    }
  })
  return {
    promotion: getPromotion(state),
    allData: getAllState(state),
    ...typeState,
  }
}

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      setTempSeletedCollections: ADD_TO_TEMP_SELECTED_COLLECTIONS,
      setCollectionsPath: SET_COLLECTIONS_PATH,
      onCheckCollection: CHECK_COLLECTION_AND_FETCH_NEW_ITEMS,
    },
    dispatch
  )
)(Collections)
