export default () => (
  <svg width='32px' height='32px' viewBox='0 0 32 32'>
    <g
      id='Items-Listing'
      stroke='none'
      strokeWidth='1'
      fill='none'
      fillRule='evenodd'
    >
      <g id='Item-Listing-All' transform='translate(-109.000000, -616.000000)'>
        <g id='table' transform='translate(56.000000, 176.000000)'>
          <g
            id='Product-Row-Copy-2'
            transform='translate(0.000000, 432.000000)'
          >
            <g id='Table-Cells-/-Media'>
              <g id='Icon-NoVisual' transform='translate(53.000000, 8.000000)'>
                <path
                  d='M2,0 L30,0 C31.1045695,0 32,0.8954305 32,2 L32,30 C32,31.1045695 31.1045695,32 30,32 L2,32 C0.8954305,32 0,31.1045695 0,30 L0,2 C0,0.8954305 0.8954305,0 2,0 Z'
                  id='Rectangle'
                  fill='#EDEDED'
                  fillRule='nonzero'
                />
                <rect
                  id='Path'
                  fillRule='nonzero'
                  x='8'
                  y='8'
                  width='16'
                  height='16'
                />
                <path
                  d='M10.6666667,18.3905243 L12.0890103,16.9681807 C12.763139,16.2940519 13.8193791,16.1894635 14.6126242,16.7182937 L15.2483881,17.1421362 L17.2524531,15.1380712 C18.0335017,14.3570226 19.2998316,14.3570226 20.0808802,15.1380712 L21.3333333,16.3905243 L21.3333333,11.3333333 C21.3333333,10.9651435 21.0348565,10.6666667 20.6666667,10.6666667 L11.3333333,10.6666667 C10.9651435,10.6666667 10.6666667,10.9651435 10.6666667,11.3333333 L10.6666667,18.3905243 Z M10.6666667,20.2761424 L10.6666667,20.6666667 C10.6666667,21.0348565 10.9651435,21.3333333 11.3333333,21.3333333 L20.6666667,21.3333333 C21.0348565,21.3333333 21.3333333,21.0348565 21.3333333,20.6666667 L21.3333333,18.2761424 L19.1380712,16.0808802 C18.8777217,15.8205307 18.4556117,15.8205307 18.1952621,16.0808802 L15.8047379,18.4714045 C15.5800283,18.6961141 15.2279483,18.7309769 14.9635332,18.5547002 L13.873024,17.827694 C13.6086089,17.6514173 13.2565289,17.6862801 13.0318193,17.9109897 L10.6666667,20.2761424 Z M11.3333333,9.33333333 L20.6666667,9.33333333 C21.7712362,9.33333333 22.6666667,10.2287638 22.6666667,11.3333333 L22.6666667,20.6666667 C22.6666667,21.7712362 21.7712362,22.6666667 20.6666667,22.6666667 L11.3333333,22.6666667 C10.2287638,22.6666667 9.33333333,21.7712362 9.33333333,20.6666667 L9.33333333,11.3333333 C9.33333333,10.2287638 10.2287638,9.33333333 11.3333333,9.33333333 Z M13.1666667,14.3333333 C12.5223345,14.3333333 12,13.8109989 12,13.1666667 C12,12.5223345 12.5223345,12 13.1666667,12 C13.8109989,12 14.3333333,12.5223345 14.3333333,13.1666667 C14.3333333,13.8109989 13.8109989,14.3333333 13.1666667,14.3333333 Z'
                  id='Shape'
                  fill='#8994A3'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
