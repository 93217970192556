import styled from 'styled-components'

export const StyledNavigation = styled.div`
  .side-navigation {
    height: 100%;
    width: 200px;
    padding: 20px;
    box-sizing: border-box;
  }
`
