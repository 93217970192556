import { StyledSearchInput, StyledSearch } from './styles'
import SearchIcon from 'components/svg/search'
import CloseIcon from 'components/svg/close'

function SearchInput({
  placeholder,
  type,
  keyword,
  onChange,
  width,
  border,
  onSubmit,
  onReset,
  onFocus,
  autoFocus,
}) {
  return (
    <StyledSearch width={width} border={border}>
      <div className='icon'>
        <SearchIcon />
      </div>
      <StyledSearchInput
        width={width}
        inputProps={{
          placeholder: placeholder,
          id: 'search',
          name: 'search',
          type: type,
          value: keyword,
          onFocus: onFocus && ((e) => onFocus(e)),
          onChange: (e) => {
            if (e.target.value === '') {
              onReset()
            }
            onChange(e.target.value)
          },
          'data-testid': 'search-input',
          autoFocus,
        }}
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            onSubmit()
          }
        }}
      />
      {keyword && (
        <div
          className='close-icon'
          data-testid='reset-icon'
          onClick={() => onReset()}
        >
          <CloseIcon />
        </div>
      )}
    </StyledSearch>
  )
}

export default SearchInput
