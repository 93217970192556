import React from 'react'
import ProductsSelection from 'components/products-selection'
import {
  getOperatorValue,
  getTargetValue,
  GetRadioTypeNames,
  formattedValue,
} from '../helper'
import { StyledBuyGet, StyledBuy, StyledGet } from '../styles'
import { StyledSpend } from './styles'
import Field from 'src/components/view/field'

const BuyGet = (props) => {
  const {
    buyData = [],
    getData = [],
    buyOperator,
    getType,
    spendData = {},
  } = props.promotion

  const getLabelsMultiSelect = (data = []) => {
    const list = []
    data.forEach((sku) => {
      list.push(sku.label)
    })
    return list
  }

  return (
    <StyledBuyGet>
      <StyledBuy>
        <div>
          <div className='buy-heading'>Customer buys</div>
          <Field noMargin title='' value={getOperatorValue(buyOperator)} />
          {buyOperator ? (
            <div className='input-fields'>
              {buyData?.map((b) => (
                <>
                  <div className='collapse'>
                    <div className='set-name'>{`SET ${b?.set}`}</div>
                  </div>
                  <Field
                    title={`Buy minimum quantity of SKUs (${b?.set})`}
                    value={b.minimumQuantity}
                  />
                  <div className='browse-skus-heading'>
                    <div className='product-selection'>
                      <ProductsSelection
                        isBuyGet={true}
                        viewMode={true}
                        targetType={`set${b?.set}`}
                      />
                    </div>
                  </div>
                </>
              ))}
            </div>
          ) : (
            <StyledSpend>
              <Field
                title='Spends minimum cart value'
                value={spendData?.minimumCartValue?.toString()}
              />
              <Field
                title='Target SKUs'
                value={getTargetValue(spendData?.skuCondition)}
              />
              {spendData.skuCondition !== '*' && (
                <div className='product-selection'>
                  <ProductsSelection
                    isBuyGet={true}
                    viewMode={true}
                    targetType={'spend'}
                    type={props.type}
                  />
                </div>
              )}
            </StyledSpend>
          )}
        </div>
      </StyledBuy>
      <StyledGet>
        <div className='get-heading'>Customer gets</div>
        <Field noMargin title='' value={GetRadioTypeNames[getType]} />
        {getData?.map((g, index) => {
          return (
            <div>
              <div
                className='discount-number'
                data-testid={`discount-number-${index + 1}`}
              >
                DISCOUNT {index + 1}
              </div>
              {getType === 'SKU' && (
                <>
                  <Field
                    title={'Get discount on'}
                    value={getLabelsMultiSelect(
                      getData[index].getSelectedSkuSets
                    )}
                  />{' '}
                  <div className='min-max-inputs'>
                    <Field
                      noMargin
                      title='Max quantity of SKUs allowed'
                      value={getData[index].getMaxQuantity.toString()}
                    />
                  </div>
                  {getData[index].getSkuSet && (
                    <div className='product-selection'>
                      <ProductsSelection
                        isBuyGet={true}
                        targetType={getData[index].getSkuSet.set}
                        viewMode={true}
                      />
                    </div>
                  )}
                </>
              )}
              {getType === 'SHIPPING' && (
                <div
                  className='shipping-fields'
                  data-testid={`shipping-fields`}
                >
                  <Field
                    noMargin
                    title='Shipping type'
                    value={getLabelsMultiSelect(
                      getData[index].getShippingType
                    ).join(', ')}
                  />
                </div>
              )}
              <div className='discount-fields'>
                <Field
                  title='Get discount type on SKUs'
                  value={getData[index].getDiscountType.name}
                />
                {getData[index].getDiscountType.name !== 'Free' && (
                  <Field
                    title={`${
                      getData[index].getDiscountType.name !== 'Free' && 'Value'
                    }`}
                    value={
                      getData[index].getDiscountType.name === 'Free'
                        ? 'Free'
                        : formattedValue(
                            getData[index].getDiscountType.name,
                            getData[index].getDiscountAmount.toString()
                          )
                    }
                  />
                )}
              </div>
            </div>
          )
        })}
      </StyledGet>
    </StyledBuyGet>
  )
}

export default BuyGet
