import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useNavigate } from 'react-router-dom'
import PageHeading from 'components/page-heading'
import { getPromoById } from 'src/api/promo'
import LoadingState from 'components/loading-state'
import { SET_PROMOTION } from '../actions'
import {
  getPromotion,
  getExplicitOption,
  getEligiblePriceList,
  getSelectedPromoTypeRadio,
  getRadioId,
  getPromoUsage,
  getUserSegment,
  getPromotionLimit,
  getCustomerSegment,
  getPromotionActive,
  getTargetType,
} from '../selectors'
import { StyledWrapper, StyledContainer } from '../styles'
import { StyledPromoInfo, StyledFormWrapper, StyledForm } from './styles'
import { GetTypeNames } from './helper'
import {
  OFFER_COUPON_LIST_ROUTE,
  OFFER_PROMO_LIST_ROUTE,
} from 'src/lib/constants/page-links'
import PromoInfo from './promo-info'
import PromoType from './promo-type'
import TermsAndConditions from './terms-conditions'
import CustomerSegments from './customer-segments'
import PromoLimit from './promo-limit'
import PromoUsage from './promo-usage'
import ProductsSelection from 'components/products-selection'

const RenderContent = ({ props, isCoupon }) => {
  const {
    promotion,
    selectedExplicitOption,
    selectedEligiblePriceList,
    selectedPromoTypeRadio,
    promoUsage,
    userSegment,
    defaultPromotionLimit,
    customerSegmentDefault,
    isPromotionLimitActive,
    targetType,
  } = props

  const { termsAndConditions = [], title, getType } = promotion
  const navigate = useNavigate()
  return (
    <StyledContainer>
      <PageHeading
        handleClick={() => {
          navigate(isCoupon ? OFFER_COUPON_LIST_ROUTE : OFFER_PROMO_LIST_ROUTE)
        }}
        data-testid='header-back-button'
      >
        <p className='page-title uppercase'>{`all ${
          isCoupon ? 'Coupons' : 'promotions'
        }`}</p>
      </PageHeading>
      <StyledPromoInfo>
        <div className='info-head'>
          <div className='name-toggle'>
            <div className='title-wrapper'>
              <span>{title}</span>
            </div>
          </div>
        </div>
      </StyledPromoInfo>
      <StyledFormWrapper>
        <StyledForm>
          {/* promo info section */}
          <PromoInfo
            isCoupon={isCoupon}
            promotion={promotion}
            selectedExplicitOption={selectedExplicitOption}
            selectedEligiblePriceList={selectedEligiblePriceList}
          />

          {/* Promo type section */}

          <PromoType
            isCoupon={isCoupon}
            selectedPromoTypeRadio={selectedPromoTypeRadio}
            promotion={promotion}
          />

          <div className='section_divider' />
          {selectedPromoTypeRadio !== 'Buy-Get' && (
            <ProductsSelection
              isPromo
              viewMode
              errors={{}}
              subHeading={
                targetType === 'targetX' ? `Target SKUs` : `Target 'X' SKUs`
              }
            />
          )}
          {/* coupon limit  */}

          {isCoupon && (
            <PromoUsage
              promoUsage={promoUsage}
              selectedExplicitOption={selectedExplicitOption}
            />
          )}

          {/* terms & conditions */}

          {isCoupon && (
            <TermsAndConditions termsAndConditions={termsAndConditions} />
          )}

          {/* customer sesgments */}
          <CustomerSegments
            customerSegmentDefault={customerSegmentDefault}
            userSegment={userSegment}
          />

          {/* promo limit */}
          {isPromotionLimitActive &&
            getType !== GetTypeNames.SHIPPING &&
            getType !== GetTypeNames.CART && (
              <PromoLimit defaultPromotionLimit={defaultPromotionLimit} />
            )}
        </StyledForm>
      </StyledFormWrapper>
    </StyledContainer>
  )
}

const PromoView = (props) => {
  const { location, promoId, couponId, setPromotion, promotion } = props
  const [isLoading, setIsLoading] = useState(false)
  const isCoupon = location?.pathname?.includes('coupon')

  const fetchPromotionData = async () => {
    setIsLoading(true)
    const byId = isCoupon ? couponId : promoId
    const { data } = await getPromoById({ id: byId, isCoupon })
    setPromotion(data)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchPromotionData()
  }, [])

  return (
    <StyledWrapper>
      {isLoading && <LoadingState />}
      {promotion && !isLoading && (
        <RenderContent props={props} isCoupon={isCoupon} />
      )}
    </StyledWrapper>
  )
}

export default connect(
  (state) => ({
    promotion: getPromotion(state),
    targetType: getTargetType(state),
    isPromotionLimitActive: getPromotionActive(state),
    selectedExplicitOption: getExplicitOption(state),
    selectedEligiblePriceList: getEligiblePriceList(state),
    selectedPromoTypeRadio: getSelectedPromoTypeRadio(state),
    selectedRadioId: getRadioId(state, 'targetX'),
    promoUsage: getPromoUsage(state),
    userSegment: getUserSegment(state),
    defaultPromotionLimit: getPromotionLimit(state),
    customerSegmentDefault: getCustomerSegment(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        setPromotion: SET_PROMOTION,
      },
      dispatch
    )
)(PromoView)
