import React from 'react'
import Field from 'src/components/view/field'
import { StyledPromoUsage } from '../styles'

const PromoUsage = ({ promoUsage, selectedExplicitOption }) => {
  const getLimitValue = (value) => {
    return selectedExplicitOption === 'isSingle' ? 1 : value
  }
  return (
    <div className='content_wrapper_25'>
      <StyledPromoUsage>
        <div className='title_heading'>Coupon limits</div>
        {promoUsage.map((item) => (
          <Field title={item.title} value={getLimitValue(item.value)} />
        ))}
      </StyledPromoUsage>
    </div>
  )
}

export default PromoUsage
