import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import UpCapIcon from 'components/svg/cap-up-fill-icon'
import DownCapIcon from 'components/svg/cap-down-fill-icon'
import { Checkbox } from '@teamfabric/copilot-ui'
import Spinner from 'components/spinner'
import { getGroupTree } from 'src/api/product'
import {
  ADD_TO_TEMP_SELECTED_CATEGORIES,
  CHECK_CATEGORY_AND_FETCH_NEW_ITEMS,
  SET_CATEGORIES_PATH,
} from 'modules/promo/actions'
import {
  getPromotion,
  getTempSelectedCategories,
  getCategoriesPath,
  getAllState,
} from 'modules/promo/selectors'
import {
  StyledRows,
  StyledCategories,
  StyledChildren,
  StyledChild,
} from './styles'
import { checkIfSetSelected } from 'src/lib/utils/helper'
import { mergeDeep } from 'src/modules/promo/helper/categories'
const SPACEBAR_KEY = 32

class Categories extends Component {
  state = {
    categories: [],
    categoryDropDowns: {},
    newCategoryState: {},
    isLoading: false,
    targetType: this.props.targetType || 'targetX',
  }
  componentDidMount() {
    let otherTypeSelectedCategories = {}
    let categoriesToSelect = {}
    let categoryToAdd = {}
    if (this.props.targetType && this.props.targetType !== 'targetX') {
      Object.keys(this.props.allData).forEach((data) => {
        if (
          data.includes('FinalSelectedCategories') &&
          !data.includes('targetX') &&
          !data.includes(this.props.targetType) &&
          (data.includes(this.props.targetType.slice(0, 3)) ||
            checkIfSetSelected(this.props.allData, data))
        ) {
          Object.keys(this.props.allData[data]).forEach((singleCategory) => {
            let singleCategoryData = this.props.allData[data][singleCategory]
            if (this.categoryIsCheckedOrPartial(singleCategoryData)) {
              categoryToAdd = this.getCategoryToAdd(
                singleCategoryData,
                categoryToAdd
              )
              categoriesToSelect = {
                ...categoriesToSelect,
                [singleCategory]: {
                  ...categoryToAdd,
                  isPartialChecked: singleCategoryData.isPartialChecked,
                  isChecked: singleCategoryData.isChecked,
                },
              }
            }
          })
          otherTypeSelectedCategories = {
            ...otherTypeSelectedCategories,
            ...categoriesToSelect,
          }
        }
      })
    }
    this.setState(
      {
        otherTypeSelectedCategories: otherTypeSelectedCategories,
        targetType: this.props.targetType,
      },
      () => this.fetchCategories()
    )
  }

  getCategoryToAdd = (singleCategoryData, categoryToAdd) => {
    if (singleCategoryData.isChecked) {
      categoryToAdd = singleCategoryData
    } else if (singleCategoryData.isPartialChecked) {
      categoryToAdd = this.getChildCheckedCategory(
        singleCategoryData,
        categoryToAdd
      )
    }
    return categoryToAdd
  }

  getChildCheckedCategory = (singleCategoryData, categoryToAdd) => {
    Object.keys(singleCategoryData).forEach((childCategory) => {
      let singleCategoryDataWithChildData = singleCategoryData[childCategory]
      if (this.categoryIsCheckedOrPartial(singleCategoryDataWithChildData)) {
        categoryToAdd = {
          ...categoryToAdd,
          [childCategory]: singleCategoryDataWithChildData,
        }
      }
    })
    return categoryToAdd
  }

  categoryIsCheckedOrPartial = (data) => {
    return data.isChecked || data.isPartialChecked
  }

  fetchCategories = async () => {
    const { targetType } = this.state
    this.setState({ isLoading: true })
    try {
      const { data } = await getGroupTree('category')
      this.setState(
        {
          categories: data,
          isLoading: false,
        },
        () => {
          const selectedCategories =
            this.props[`${targetType}SelectedCategories`]
          this.props.setTempSeletedCategories({
            categories: mergeDeep(
              this.getCategoriesState(),
              selectedCategories
            ),
            targetType,
          })
        }
      )
    } catch (error) {
      console.log('==================error', error)
    }
  }

  getCategoriesState = () => {
    let catState = {}
    let categoriesPath = {}
    this.state.categories?.forEach((category) => {
      if (category.children && category.children.length > 0) {
        let categroyObj =
          category &&
          this.createCategoryState({
            category,
            isRoot: true,
            totalCount: category.children.length,
          })
        categoriesPath[category._id] = category._id
        catState[category._id] = {
          ...categroyObj,
          ...this.createCategoriesState(
            category,
            categoriesPath,
            category._id,
            category.parentId,
            categroyObj
          ),
        }
      } else {
        categoriesPath[category._id] = category._id
        catState[category._id] = this.createCategoryState({
          category,
          isChild: true,
          totalCount: category.children.length,
        })
      }
    })
    this.props.setCategoriesPath({
      ...this.props.categoriesPath,
      ...categoriesPath,
    })
    return catState
  }

  createCategoriesState = (
    category,
    categoriesPath,
    parentId = '',
    parentParentId = '',
    catState = {}
  ) => {
    let newCatState = {}
    if (category.children.length === 0) {
      let cat = this.createCategoryState({
        category,
        parentId,
        parentParentId: catState.parentId,
        isChild: true,
        totalCount: category.children.length,
      })
      categoriesPath[category._id] = cat.parentId + '.' + category._id
      return cat
    }

    category.children.map((categoryChild) => {
      if (categoryChild.children && categoryChild.children.length > 0) {
        let catObj = this.createCategoryState({
          category: categoryChild,
          parentId,
          parentParentId: catState.parentId,
          totalCount: categoryChild.children.length,
        })
        newCatState[categoryChild._id] = {
          ...catObj,
          ...this.createCategoriesState(
            categoryChild,
            categoriesPath,
            categoryChild._id,
            parentParentId,
            catObj
          ),
        }
        categoriesPath[categoryChild._id] =
          catObj.parentId + '.' + categoryChild._id
      } else {
        let catObj = this.createCategoryState({
          category: categoryChild,
          parentId,
          parentParentId: catState.parentId,
          isChild: true,
          totalCount: categoryChild.children.length,
        })
        newCatState[categoryChild._id] = catObj
        categoriesPath[categoryChild._id] =
          catObj.parentId + '.' + categoryChild._id
      }
    })
    return newCatState
  }

  createCategoryState = ({
    category,
    parentId = '',
    parentParentId = '',
    isRoot = false,
    isChild = false,
    totalCount = 0,
  }) => {
    let isChecked = false
    let isPartialChecked = false
    let count = 0

    const parentID = () => {
      return parentParentId.length > 0
        ? parentParentId + '.' + parentId
        : parentId
    }

    return {
      isChecked: isChecked,
      parentId: isRoot ? '' : parentID(),
      firstParent: parentId,
      isChild: isChild,
      name: category.name,
      count: count,
      totalCount,
      isPartialChecked,

      path: this.getCategoryPath(category),
      _id: category._id,
    }
  }

  getCategoryPath = (category) => {
    let { categories } = this.state
    let categoriesPath
    for (let i = 0; i < categories.length; i++) {
      let parent = categories[i].name
      if (categories[i]._id === category._id) {
        categoriesPath = parent
        return categoriesPath
      }
      categoriesPath = this.getDepthPath(category._id, categories[i])
      if (categoriesPath) {
        categoriesPath = uniq(categoriesPath)
        categoriesPath.unshift(parent)
        categoriesPath = categoriesPath.join(' / ')
        break
      }
    }
    return categoriesPath
  }

  getDepthPath = (id, arr) => {
    if (id === arr._id) {
      return [arr.name]
    } else {
      const { children } = arr || []
      for (let j = 0; j < children.length; j++) {
        const path = this.getDepthPath(id, children[j])
        if (Array.isArray(path)) {
          path.unshift(children[j].name)
          return path
        }
      }
    }
  }

  getCategories = () => {
    return this.state?.categories?.map((category) => {
      return (
        <StyledRows key={category._id}>
          {category.children.length > 0 ? (
            <StyledChildren>
              {this.renderChild(category, 0)}
              {this.renderCategory(category, 1)}
            </StyledChildren>
          ) : (
            <StyledChildren>
              {this.renderChild(category, 0, true)}
            </StyledChildren>
          )}
        </StyledRows>
      )
    })
  }

  render() {
    const { isLoading } = this.state
    const { categoriesPath } = this.props
    return (
      <StyledCategories
        isListingModal={this.props.isListingModal}
        data-testid='categories-list'
      >
        {/* <div className='separator' /> */}
        {isLoading ? (
          <div className='spinner'>
            <Spinner />
          </div>
        ) : (
          <div className='categories'>
            {categoriesPath && this.getCategories()}
          </div>
        )}
      </StyledCategories>
    )
  }

  renderCategory = (category, n) => {
    if (category.children.length === 0) {
      return this.renderChild(category, n, true)
    }
    return this.state.categoryDropDowns[category.name]
      ? category.children.map((childCategory) => {
          return (
            <StyledChildren key={childCategory._id}>
              {childCategory.children.length > 0 ? (
                <StyledChildren>
                  {this.renderChild(childCategory, n)}
                  <StyledChildren>
                    {this.renderCategory(childCategory, n + 1)}
                  </StyledChildren>
                </StyledChildren>
              ) : (
                this.renderCategory(childCategory, n)
              )}
            </StyledChildren>
          )
        })
      : []
  }

  renderChild = (category, n, isChild = false) => {
    const { targetType, otherTypeSelectedCategories } = this.state
    const { categoriesPath, viewMode } = this.props
    const selectedCategories = this.props[`${targetType}SelectedCategories`]
    const isAlreadyPresentCategory = get(
      selectedCategories,
      `${categoriesPath[category._id]}`
    )
    const isAlreadyPresentOtherTypeCategory = get(
      otherTypeSelectedCategories,
      `${categoriesPath[category._id]}`
    )
    return (
      <StyledChild padding={n}>
        <div className='left'>
          <div className='checkbox'>
            {!isChild
              ? this.renderHideOrShowCategories(category)
              : this.renderSpace()}
            <Checkbox
              disabled={
                viewMode ||
                (isAlreadyPresentOtherTypeCategory
                  ? isAlreadyPresentOtherTypeCategory.isChecked ||
                    isAlreadyPresentOtherTypeCategory.isPartialChecked
                  : false)
              }
              checked={
                isAlreadyPresentOtherTypeCategory &&
                (isAlreadyPresentOtherTypeCategory.isChecked ||
                  isAlreadyPresentOtherTypeCategory.isPartialChecked)
                  ? true
                  : isAlreadyPresentCategory
                  ? isAlreadyPresentCategory.isChecked ||
                    isAlreadyPresentCategory.isPartialChecked
                  : false
              }
              isPartial={
                isAlreadyPresentOtherTypeCategory &&
                isAlreadyPresentOtherTypeCategory.isPartialChecked
                  ? true
                  : isAlreadyPresentCategory
                  ? isAlreadyPresentCategory.isPartialChecked
                  : false
              }
              onChange={() => {
                this.props.onCheckCategory({ id: category._id, targetType })
              }}
              label={category.name}
              otherProps={{ 'data-testid': `checkbox-${category._id}` }}
              // onKeyDown={e =>
              //   e.keyCode === SPACEBAR_KEY &&
              //   this.props.onCheckCategory({ id: category._id, targetType })
              // }
              // id={`checkbox-${category._id}`}
            />
          </div>
          {/* <div className='title'>{category.name}</div> */}
        </div>
      </StyledChild>
    )
  }
  renderSpace = () => <div className='no-checkbox' />

  renderHideOrShowCategories = (category) => {
    const { categoryDropDowns } = this.state
    return (
      <div
        className='icon'
        tabIndex='0'
        onClick={() =>
          this.setState({
            categoryDropDowns: {
              ...categoryDropDowns,
              [category.name]: !categoryDropDowns[category.name],
            },
          })
        }
        onKeyDown={(e) =>
          e.keyCode === SPACEBAR_KEY &&
          this.setState({
            categoryDropDowns: {
              ...categoryDropDowns,
              [category.name]: !categoryDropDowns[category.name],
            },
          })
        }
        data-testid={`toggle-${category._id}`}
      >
        {categoryDropDowns[category.name] ? (
          <UpCapIcon fill='#0D62FF' />
        ) : (
          <DownCapIcon fill='#121213' />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let typeState = {}
  const types = [
    'targetX',
    'targetY',
    'exclusion',
    state.promoReducer.targetType,
  ]
  types.forEach((type) => {
    typeState = {
      ...typeState,
      [`${type}SelectedCategories`]: getTempSelectedCategories(state, type),
      categoriesPath: getCategoriesPath(state),
    }
  })
  return {
    promotion: getPromotion(state),
    allData: getAllState(state),
    ...typeState,
  }
}

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      setTempSeletedCategories: ADD_TO_TEMP_SELECTED_CATEGORIES,
      setCategoriesPath: SET_CATEGORIES_PATH,
      onCheckCategory: CHECK_CATEGORY_AND_FETCH_NEW_ITEMS,
    },
    dispatch
  )
)(Categories)
