import React, { Component } from 'react'
import cx from 'classnames'
import OffersCalender from 'components/offers-calender'
import OffersTimepicker from 'components/offers-time-picker'
import { Input, Button, Modal } from '@teamfabric/copilot-ui'
import { getPriceKinds, createPrice } from 'src/api/product'
import { getValidationErrors } from './validations'
import { StyledCreatePrice, StyledPriceContainer, StyledModal } from './styles'
import { CREATE_SUCCESS, CREATE_FAILURE } from './constants'
import {
  format,
  endOfDay,
  startOfDay,
  isAfter,
  isBefore,
  isEqual,
  parse,
  addSeconds,
} from 'date-fns'
import getSymbolFromCurrency from 'currency-symbol-map'
const CLEARANCE = 'Clearance'

const setEndDate = () => {
  const parsedDate = parse('2099-12-31', 'yyyy-mm-dd', new Date())
  return endOfDay(parsedDate)
}
export default class CreatePrice extends Component {
  state = {
    priceData: {
      startDate: '',
      startTime: '',
      endDate: setEndDate(),
      channel: 12,
    },
    priceKinds: null,
    error: {},
    showPriceTypeSelector: false,
    priceTypeSelected: '',
    dataError: '',
    priceKindId: null,
    isCreatePriceConfirmation: false,
    showOverWriteModal: false,
  }

  setFieldValues = (ev, targetedValue = null, type = null) => {
    const {
      target: { name, value },
    } = type ? { target: { name: type, value: targetedValue } } : ev
    let onChangeErrors = {
      ...this.state.error,
      [name]: '',
      startDate:
        name === 'startTime' || name === 'startDate'
          ? ''
          : this.state.error.startDate,
    }
    let newPriceData = this.state.priceData
    newPriceData = {
      ...newPriceData,
      [name]: value,
      channel: 12,
    }
    if (type === 'startDate' || type === 'startTime')
      newPriceData.startDate = this.formatDate(
        newPriceData.startDate,
        newPriceData.startTime
      )
    return this.setState({
      priceData: newPriceData,
      error: onChangeErrors,
    })
  }

  componentWillMount() {
    this.fetchPriceKinds()
  }

  formatDate = (startDate, startTime) => {
    startDate = startDate || new Date()
    startTime = startTime || new Date()
    const newTime = new Date(startTime).toLocaleTimeString('en-GB', {
      hour12: false,
    })
    const newDate = new Date(startDate).toLocaleDateString('en')
    return new Date(newDate + ' ' + newTime)
  }

  fetchPriceKinds = async () => {
    try {
      const reqData = {
        limit: 10,
        page: 1,
      }
      const { data } = await getPriceKinds(reqData)
      let priceKindId = null
      if (data && data.priceKinds) {
        let newPriceData = this.state.priceData
        data.priceKinds.forEach((element) => {
          if (element.name === CLEARANCE) priceKindId = element.priceKindId
        })
        newPriceData = {
          ...newPriceData,
          priceType: null,
        }
        this.setState({
          priceKinds: data,
          priceData: newPriceData,
          priceKindId,
        })
      }
    } catch (error) {
      return this.handleApiError(error)
    }
  }

  parseDate = (dateTime) => {
    if (dateTime) {
      return addSeconds(dateTime, 0)
    } else {
      return ''
    }
  }

  isPromoDate = (date) => {
    if (
      !this.props.promoDates ||
      (!this.props.promoDates.startDate && !this.props.promoDates.endDate)
    )
      return true
    let currentDateTime = startOfDay(date)
    const { promoDates } = this.props
    const startDate = startOfDay(promoDates.startDate)
    const endDate = startOfDay(promoDates.endDate)
    return !(
      (isAfter(currentDateTime, startDate) ||
        isEqual(currentDateTime, startDate)) &&
      (isBefore(currentDateTime, endDate) || isEqual(currentDateTime, endDate))
    )
  }

  closeConfirmationModal = () => this.setState({ showConfirmationModal: false })
  closeOverWriteModal = () => this.setState({ showOverWriteModal: false })

  createVariantPrice = async () => {
    const { priceData } = this.state
    const { itemSku } = this.props
    let newPriceData = {
      kind:
        (priceData.sale &&
          Number(parseFloat(priceData.sale) >= 0 && this.state.priceKindId)) ||
        null,
      channel: parseInt(priceData.channel),
      startDate: this.parseDate(priceData.startDate),
      endDate: setEndDate(),
      price: {
        base: Number(parseFloat(priceData.base).toFixed(5)),
        currency: this.props.currency || 'USD',
      },
    }
    if (priceData.sale && Number(parseFloat(priceData.sale) >= 0)) {
      newPriceData.price.sale = Number(parseFloat(priceData.sale).toFixed(5))
    }
    if (priceData.cost && Number(parseFloat(priceData.cost) >= 0)) {
      newPriceData.price.cost = Number(parseFloat(priceData.cost).toFixed(5))
      if (
        !this.state.isCreatePriceConfirmation &&
        parseFloat(priceData.cost) > parseFloat(priceData.sale)
      ) {
        this.setState({ showConfirmationModal: true })
        return
      }
    }
    const data = {
      itemIds: this.props.itemId,
      itemSkus: itemSku,
      priceListId: this.props.priceListId,
      offers: [newPriceData],
    }
    try {
      this.props.setIsLoading(true)
      const res = await createPrice(data)
      if (res.status === 200) {
        const updatedData = this.state.priceData
        delete updatedData['sale']
        delete updatedData['base']
        this.props.renderToaster('message', CREATE_SUCCESS)
        this.props.fetchProduct()
        this.props.setIsLoading(false)
        this.setState({
          isCreatePriceConfirmation: false,
          priceData: {
            startDate: '',
            startTime: '',
            endDate: setEndDate(),
            channel: 12,
          },
        })
      }
    } catch (error) {
      console.log('=====i come in error', error)
      this.props.setIsLoading(false)
      return this.handleApiError(error)
    }
  }

  handleApiError(error) {
    this.setState(
      {
        dataError:
          error.response && error.response.data
            ? error.response.data.message
            : CREATE_FAILURE,
      },
      () => {
        this.props.renderToaster('alert', this.state.dataError)
      }
    )
  }

  render() {
    return (
      <StyledPriceContainer isVariant={this.props.isVariant}>
        {this.state.showConfirmationModal && this.renderConfirmationModal()}
        {this.state.showOverWriteModal && this.renderOverWritePriceModal()}
        {this.renderPriceFields()}
      </StyledPriceContainer>
    )
  }

  renderPriceField = (props) => {
    const { priceData, error } = this.state
    const { placeholder, name } = props
    const { currency } = this.props
    return (
      <div className='price_value' data-testid={`create-price-${name}`}>
        <Input
          prefix={getSymbolFromCurrency(currency)}
          className='primary'
          error={error && error[name] ? true : false}
          errorMessage={
            error && error[name] ? (
              <span data-testid={`${name}-error`} className='error_message'>
                {error[name]}
              </span>
            ) : null
          }
          errorProps={{}}
          inputProps={{
            disabled: false,
            id: name,
            name: name,
            type: 'number',
            value: priceData[name] ? priceData[name].toString() : '',
            onChange: (e) => {
              if (
                (typeof e.target.value === 'string' &&
                  e.target.value.length === 0) ||
                Number(parseFloat(e.target.value < 0))
              ) {
                e.target.value = ''
              }
              this.setFieldValues(e)
            },
            'data-testid': name,
          }}
          isFloatedLabel
          label={placeholder}
          width='135px'
        />
      </div>
    )
  }

  renderPriceFields = () => {
    const { error } = this.state
    const { isVariant } = this.props
    return (
      <StyledCreatePrice isVariant={isVariant}>
        <div
          className={cx('row_wrapper', {
            padding_bottom_30:
              Object.values(error) &&
              Object.values(error).filter(Boolean).length,
          })}
        >
          {this.renderPriceField({ placeholder: 'Cost', name: 'cost' })}
          {this.renderPriceField({ placeholder: 'Price', name: 'base' })}
          {this.renderPriceField({
            placeholder: 'Clearance price',
            name: 'sale',
          })}
          <div className='price_value'>
            <OffersCalender
              startDate={
                this.state.priceData.startDate
                  ? typeof this.state.priceData.startDate === 'string'
                    ? new Date(this.state.priceData.startDate)
                    : this.state.priceData.startDate
                  : ''
              }
              setFieldValues={(date, e) =>
                this.setFieldValues(e, date, `startDate`)
              }
              label='start'
              error={error && error.startDate ? true : false}
            />
            {error && error.startDate ? (
              <span data-testid='start-date-error' className='error_message'>
                {error.startDate}{' '}
              </span>
            ) : null}
          </div>
          <div className='price_value'>
            <OffersTimepicker
              label='start'
              date={this.state.priceData.startTime}
              setFieldValues={(time, e) =>
                this.setFieldValues(e, time, `startTime`)
              }
              disabled={this.state.priceData.startDate ? false : true}
              dateForTime={this.state.priceData.startDate || new Date()}
            />
            {error && error.startTime ? (
              <span data-testid='start-time-error' className='error_message'>
                {error.startTime}{' '}
              </span>
            ) : null}
          </div>
          <Button
            disabled={this.props.isExpired}
            isPrimary={false}
            className='save_button'
            size='medium'
            text='Add Price '
            onClick={() => {
              const validationErrors = getValidationErrors(this.state.priceData)
              if (Object.keys(validationErrors).length > 0) {
                this.setState({ error: validationErrors })
                return
              }
              if (this.props.isPriceUpcomingOrActive) {
                this.setState({ showOverWriteModal: true })
              } else {
                this.createVariantPrice()
              }
            }}
            data-testid='add-price'
          />
        </div>
      </StyledCreatePrice>
    )
  }

  renderConfirmationModal = () => {
    const modalContent = () => {
      return (
        <StyledModal>
          <div className='header' data-testid='clearance-info-modal'>
            Change clearance price
          </div>
          <div className='info'>
            Clearance price is less than the cost. Are you sure you want to
            liquidate?
          </div>
          <div className='button_actions'>
            <Button
              color='text'
              size='medium'
              variant='block'
              text='Cancel'
              isPrimary={false}
              onClick={this.closeConfirmationModal}
            >
              Cancel
            </Button>
            <Button
              size='medium'
              variant='block'
              text='Yes'
              onClick={() => {
                this.closeConfirmationModal()
                this.setState({ isCreatePriceConfirmation: true }, () =>
                  this.createVariantPrice()
                )
              }}
              data-testid='confirm-clearance'
            >
              Yes
            </Button>
          </div>
        </StyledModal>
      )
    }
    return (
      <Modal
        footerButtons={[]}
        headerButtons={[]}
        onBackdropClick={() => {
          return false
        }}
        onClose={this.closeConfirmationModal}
        padding='32px'
        render={modalContent}
        showCloseButton
        size='small'
        headerText=''
      />
    )
  }

  renderOverWritePriceModal = () => {
    const { startDate } = this.state.priceData
    const modalContent = () => {
      return (
        <StyledModal data-testid='overwrite-info-modal'>
          <div className='header'>Overwrite details</div>
          <div className='info'>
            You will overwrite the existing price, clearance price, and cost
            from {format(startDate, 'd MMM yyyy, hh:mm a')} to 31 Dec 2099,
            11:59 PM.
          </div>
          <div className='info'>Are you sure you want to overwrite?</div>
          <div className='button_actions'>
            <Button
              color='text'
              size='medium'
              variant='block'
              text='Cancel'
              isPrimary={false}
              onClick={this.closeOverWriteModal}
              data-testid='cancel-button'
            >
              Cancel
            </Button>
            <Button
              size='medium'
              variant='block'
              text='Yes'
              onClick={() => {
                this.closeOverWriteModal()
                this.createVariantPrice()
              }}
              data-testid='confirm-button'
            >
              Yes
            </Button>
          </div>
        </StyledModal>
      )
    }
    return (
      <Modal
        footerButtons={[]}
        headerButtons={[]}
        onBackdropClick={() => {
          return false
        }}
        onClose={this.closeOverWriteModal}
        padding='32px'
        render={modalContent}
        showCloseButton
        size='small'
        headerText=''
      />
    )
  }
}
