import styled from 'styled-components'

export const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  opacity: 0.6;
`

export const NoResultsContainer = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  margin-top: 60px;
  flex-direction: column;
  align-items: center;
  svg {
    margin-bottom: 40px;
  }
`

export const TilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 32px 24px;

  padding: 32px 59px;

  background-color: white;
  border-radius: 4px;
`
