import React, { Component } from 'react'
import PromoProductSelection from './promo'
import ExclusionProductSelection from './global-exclusion'
import BuyProductSelection from './buy'
class ProductsSelection extends Component {
  render() {
    if (this.props.isPromo) {
      return <PromoProductSelection {...this.props} />
    } else if (this.props.isBuyGet) {
      return <BuyProductSelection {...this.props} />
    } else {
      return <ExclusionProductSelection {...this.props} />
    }
  }
}

export default ProductsSelection
