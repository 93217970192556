import styled from 'styled-components'

export const StyledCategories = styled.div`
  .categories {
    height: ${props =>
      props.isListingModal ? `464px` : `calc(100vh - 120px)`};
    width: 300px;
    overflow-x: hidden;
  }
  .separator {
    width: ${props => (props.isListingModal ? '100%' : '448px')};
    height: 1px;
    background-color: ${props => props.theme.palette.text.separation};
  }
  .spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 400px;
  }
`

export const StyledChild = styled.div`
  display: flex;
  align-items: center;
  height: 8px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: calc(20 * ${props => props.padding}px);
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
    .title {
      padding-left: 20px;
      ${props => props.theme.typography.h5}
    }
    .checkbox {
      margin-right: 16px;
      display: flex;
      display: flex;
      align-items: center;
      label {
        padding: 9px 5px;
        span:nth-of-type(2) {
          width: 200px;
        }
      }
    }
  }
  .icon {
    outline: 0;
    cursor: pointer;
  }
  .no-checkbox {
    margin-right: 10px;
    height: 24px;
    width: 16px;
  }
`

export const StyledChildren = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledRows = styled.div`
  display: flex;
  flex-direction: column;
`
