import React from 'react'
import { StyledPromoUsage, ContentWrapper } from '../styles'
import { getTargetAudienceLabel } from '../helper'
import Field from 'src/components/view/field'

const CustomerSegments = ({ customerSegmentDefault, userSegment }) => {
  return (
    <div className='content_wrapper_25'>
      <StyledPromoUsage>
        <div className='title_heading'>Customer segments</div>
        <div className='margin_24'>
          <Field
            title={`Target Audience`}
            value={getTargetAudienceLabel(userSegment)}
          />
        </div>
        {userSegment === 'USER_SEGMENT' &&
          customerSegmentDefault.map((item) => (
            <ContentWrapper>
              <div className='segment-name'>
                <Field title={`Segment name`} value={item.name} />
                <Field
                  title={`Segment values`}
                  value={item.value ? item.value.join(', ') : ''}
                />
              </div>
            </ContentWrapper>
          ))}
      </StyledPromoUsage>
    </div>
  )
}

export default CustomerSegments
