import styled from 'styled-components'
export const StyledStatusLabel = styled.div`
  width: 72px;
  height: 16px;
  border-radius: 2px;
  .status {
    border-radius: 2px;
    background-color: ${(props) =>
      props.isCurrent
        ? props.theme.palette.success.light
        : props.theme.palette.info.lighter};
    .status_label {
      text-align: center;
      ${(props) => props.theme.typography.h6.css};
      color: ${(props) =>
        props.isCurrent
          ? props.theme.palette.success.main
          : props.theme.palette.info.main};
    }
  }
`
export const StyledPreview = styled.div`
  height: 32px;
  width: 32px;
  background: ${(props) => props.theme.palette.grey.light};
  ${(props) => props.url && `background-image: url(${props.url});`}
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`
export const StyledNoResult = styled.div`
  text-align: center;
  width: 500px;
  margin: 66px auto 0 auto;
`
export const StyledTime = styled.div`
  padding-top: 5px;
  color: ${(props) => props.theme.palette.grey.brand};
`
