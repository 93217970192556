import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import algoliasearch from 'algoliasearch'
import cx from 'classnames'
import UpCapIcon from 'components/svg/cap-up-fill-icon'
import DownCapIcon from 'components/svg/cap-down-fill-icon'
import { Checkbox, Snackbar, theme } from '@teamfabric/copilot-ui'
import Spinner from 'components/spinner'
import {
  StyledAttribute,
  StyledAttributesAndSkus,
  StyledValuesContainer,
} from './styles'
import { getAttributesFromPimConnector } from 'src/api/promo'
import { isAccountActive, isFeatureActive } from 'src/api/featureFlag'

import {
  ON_ATTRIBUTE_CHECK,
  SET_ATTRIBUTE_ITEM_DETAILS,
} from 'modules/promo/actions'

import { algoliaClient, FEATURE_FLAGS } from 'src/general-config'
import {
  fetchAttributeValues,
  getSelectedNameFromAttributeData,
} from 'lib/utils/helper'
import { getBuyGetTargetTypes } from 'src/modules/promo/utils'
import {
  PromotionRadioTypeNames,
  BuyGetSpendNames,
  ATTRIBUTE_KEYS_QUERY,
} from 'src/modules/promo/constants'
import { isPartial } from 'src/lib/utils/helper'
import { mapStateToPropsAttributes } from 'src/modules/promo/selectors'
const AlgilaPerformanceImprovement = isFeatureActive({
  flagName: FEATURE_FLAGS?.ALGOLIA_PERFORMANCE_IMPROVEMENT,
})
const APP_ID = algoliaClient.appId
const ALGOLIA_API_KEY = algoliaClient.apiKey
const client = algoliasearch(APP_ID, ALGOLIA_API_KEY)

const attributesSearchIndex = algoliaClient.attributesSearchIndex
const attributesItemsSearchIndex = algoliaClient.attributesItemsSearchIndex
const attributesIndex = client.initIndex(attributesSearchIndex)
const attributesItemIndex = client.initIndex(attributesItemsSearchIndex)
const SPACEBAR_KEY = 32

const UpCap = ({ state }) => (
  <div
    data-testid='up-cap-button'
    className='toggle'
    onClick={() => state({ selectedAttribute: null })}
  >
    {' '}
    <UpCapIcon fill={theme.palette.ui.states.active} />
  </div>
)

const DownCap = ({ state, name }) => (
  <div
    data-testid='downCap-cap-button'
    className='toggle'
    onClick={() => state({ selectedAttribute: name })}
  >
    <DownCapIcon fill={theme.palette.brand.primary.charcoal} />
  </div>
)

export const AttributeSelected = ({ state, name, selectedAttribute }) =>
  selectedAttribute === name ? (
    <UpCap state={state} />
  ) : (
    <DownCap state={state} name={name} />
  )

export const setListWithAttributeValuesConnector = ({
  attributes,
  state,
  offset,
}) => {
  let attributesWithValues = []

  for (const key in attributes) {
    attributesWithValues.push({
      id: key,
      name: key,
      value: attributes[key],
    })
  }
  return {
    attributesList:
      offset == 0
        ? attributesWithValues
        : state.attributesList.concat(attributesWithValues),
    isAttributesLoading: false,
  }
}

export class Attributes extends Component {
  state = {
    attributesList: [],
    page: 0,
    isLoadMore: false,
    selectedAttribute: null,
    targetType: this.props.targetType || 'targetX',
    attributeValuesList: [],
    attributeValuesCount: {},
    [FEATURE_FLAGS.Pim_Connector]: false,
    pimConnectorPagination: {
      limit: 10,
      offset: 0,
    },
  }

  componentDidMount = () => {
    const PIMConnector = isAccountActive({
      featureFlag: FEATURE_FLAGS?.Pim_Connector,
    })
    this.setState(
      {
        targetType: this.props.targetType,
        PIMConnector: PIMConnector,
      },
      () => {
        if (PIMConnector) {
          this.getAttributesPimConnector({
            offset: this.state.pimConnectorPagination.offset,
          })
        } else {
          if (AlgilaPerformanceImprovement) {
            this.getAttributesKeys('')
          } else {
            this.getAttributes('')
          }
        }

        this.props.setAttributeItems({
          page: 0,
          targetType: this.state.targetType,
        })
      }
    )
  }

  getCheckedAttributesCount = (attribute) => {
    let count = 0
    for (let key in attribute) {
      if (
        typeof attribute[key] === 'object' &&
        attribute[key] &&
        attribute[key]._id
      ) {
        count += 1
      }
    }
    return count
  }

  getIsChecked = ({
    attribute,
    value,
    isChild,
    targetType,
    checkIsDisable = false,
  }) => {
    let selectedAttributes = {}
    if (targetType && targetType !== 'targetX' && checkIsDisable) {
      Object.keys(this.props.allData).forEach((data) => {
        if (
          data.includes('FinalSelectedAttributesTree') &&
          !data.includes('targetX') &&
          !data.includes(targetType)
        ) {
          let attributesToSelect = {}
          Object.keys(this.props.allData[data]).forEach((singleAttribute) => {
            let singleAttributeData = this.props.allData[data][singleAttribute]
            attributesToSelect = {
              ...attributesToSelect,
              [singleAttribute]: {
                name: singleAttributeData.name,
                values: this.getCheckedAttributesValues(
                  singleAttributeData,
                  selectedAttributes[singleAttribute]
                ),
              },
            }
          })
          selectedAttributes = { ...selectedAttributes, ...attributesToSelect }
        }
      })
    } else {
      selectedAttributes = this.props[`${targetType}SelectedAttributes`]
    }
    if (isChild) {
      if (selectedAttributes && selectedAttributes[attribute.id])
        return selectedAttributes[attribute.id].values.includes(value)
      return false
    }
    return selectedAttributes &&
      (selectedAttributes[attribute.id] ||
        getSelectedNameFromAttributeData({
          selectedAttributes,
          name: attribute.name,
        }))
      ? true
      : false
  }

  getCheckedAttributesValues = (
    singleAttributeData,
    selectedAttributesData
  ) => {
    if (selectedAttributesData?.values) {
      return selectedAttributesData?.values?.concat(singleAttributeData.values)
    }
    return singleAttributeData.values
  }

  getAttributes = (keyword) => {
    const { page } = this.state
    this.setState({
      isAttributesLoading: true,
    })
    attributesIndex
      .search(keyword, {
        page: page,
        hitsPerPage: 10,
        distinct: true,
      })
      .then((response) => {
        const { hits, nbPages } = response
        if (page < nbPages - 1) {
          this.setState({
            isLoadMore: true,
            page: page + 1,
          })
        } else {
          this.setState({
            isLoadMore: false,
          })
        }

        if (hits.length === 0) {
          this.setState({
            attributesList: [],
            isAttributesLoading: false,
          })
          return
        }
        this.setListWithAttributeValues(hits)
      })
      .catch((error) => {
        const { message } = error
        this.setState({
          isAttributesLoading: false,
          errorToaster: true,
          message: message,
        })
      })
  }

  getAttributesKeys = () => {
    this.setState({
      isAttributesLoading: true,
    })
    attributesItemIndex
      .search('', ATTRIBUTE_KEYS_QUERY)
      .then((values) => {
        const attributes = values?.facets['attributes.key'] || []
        const attributeKeys = Object.keys(attributes).map((attribute) => {
          return {
            name: attribute,
          }
        })
        this.setState({
          attributesList: attributeKeys,
          isLoadMore: false,
          isAttributesLoading: false,
        })
      })
      .catch((err) => {
        this.setState({
          isAttributesLoading: false,
          errorToaster: true,
          message: err.message,
        })
      })
  }

  getAttributesPimConnector = async ({ offset }) => {
    this.setState({
      isAttributesLoading: true,
    })
    let results = await getAttributesFromPimConnector({
      limit: this.state.pimConnectorPagination.limit,
      offset,
    })
    let trnsformedPayload = setListWithAttributeValuesConnector({
      attributes: results.data.attributes,
      setState: this.setState,
      state: this.state,
      offset: results.offset,
    })
    this.setState({ ...trnsformedPayload })
    this.setState({
      pimConnectorPagination: {
        limit: results.data.limit,
        offset: results.data.offset,
        count: results.data.count,
      },
    })
    this.setState({ pimConnectorOffset: offset })
    this.setState({
      isAttributesLoading: false,
    })
  }

  getValuesForAttribute = async (attributeId) => {
    let hits = []
    await attributesIndex.browseObjects({
      filters: `id:${attributeId}`,
      batch: (batch) => {
        hits = hits.concat(batch)
      },
    })
    return hits
  }

  closeToaster = () => this.setState({ errorToaster: false })

  setListWithAttributeValues = async (attributes) => {
    const { attributesList } = this.state
    const attributesWithValues = []
    Promise.all(
      attributes.map((attribute) => this.getValuesForAttribute(attribute.id))
    ).then((responses) => {
      responses.forEach((response) => {
        if (response && response.length) {
          const attribute = response[0]
          attributesWithValues.push({
            id: attribute.id,
            name: attribute.name,
            value: Array.from(
              new Set(
                response.map((hit) => {
                  if (typeof hit.value == 'boolean') return hit.value.toString()
                  return hit.value
                })
              )
            ),
          })
        }
      })

      this.setState({
        attributesList: attributesList.concat(attributesWithValues),
        isAttributesLoading: false,
      })
    })
  }

  render() {
    const { errorToaster, message, isAttributesLoading } = this.state
    return (
      <StyledAttributesAndSkus>
        {errorToaster && (
          <div className='snack-bar'>
            <Snackbar
              onDismiss={this.onClose}
              label={message}
              dismissable
              kind='alert'
              show
              width='600px'
            />
          </div>
        )}
        {isAttributesLoading && (
          <div className='spinner'>
            <Spinner />
          </div>
        )}
        <div data-testid='render-attribute' className='attributes'>
          {this.renderAttributesListing()}
        </div>
      </StyledAttributesAndSkus>
    )
  }

  renderAttributesListing = () => {
    const { attributesList, isLoadMore, isAttributesLoading, PIMConnector } =
      this.state
    let attributes = []
    attributesList &&
      attributesList.length &&
      attributesList.forEach((attribute) => {
        if (
          (!PIMConnector && AlgilaPerformanceImprovement) ||
          attribute.value.length
        ) {
          attributes = attributes.concat(this.getAttribute(attribute))
        }
      })
    return (
      <div data-testid='load-attributes'>
        {attributes}
        {(isAccountActive({ featureFlag: FEATURE_FLAGS?.Pim_Connector })
          ? this.state.pimConnectorPagination.count >=
            this.state.pimConnectorPagination.limit +
              this.state.pimConnectorPagination.offset
          : !isAttributesLoading && isLoadMore) && (
          <div
            className='load_more'
            onClick={() =>
              isAccountActive({ featureFlag: FEATURE_FLAGS?.Pim_Connector })
                ? this.getAttributesPimConnector({
                    offset: this.state.pimConnectorPagination.offset + 10,
                  })
                : this.getAttributes('')
            }
            data-testid='load-more'
          >
            Load More
          </div>
        )}
      </div>
    )
  }

  getAttribute = (attribute) => {
    const { selectedAttribute } = this.state
    return (
      <div>
        {this.renderRow({ attribute })}
        <StyledValuesContainer
          childCount={attribute.value && attribute.value.length}
        >
          <div
            className={cx('values_container', {
              show: selectedAttribute && selectedAttribute === attribute.name,
            })}
          >
            {attribute.value &&
              attribute.value.length &&
              attribute.value.map((value) => {
                return this.renderRow({
                  attribute: { ...attribute, count: attribute.value.length },
                  value,
                  isChild: true,
                })
              })}
          </div>
        </StyledValuesContainer>
      </div>
    )
  }

  getValuesByType = (values) => {
    let allValues = []
    values?.forEach((value) => {
      if (typeof value !== 'object') {
        allValues.push(value)
      } else {
        if (value?.length > 0) {
          value?.forEach((singleValue) => {
            allValues.push(singleValue)
          })
        }
      }
    })
    return allValues
  }

  setAttributeValues = async ({ attribute }) => {
    const { attributesList = [] } = this.state
    let tempAttributesList = [...attributesList]

    this.setState({
      isAttributesLoading: true,
    })
    const { id, values, error } = await fetchAttributeValues({
      name: attribute.name,
    })
    if (!error) {
      tempAttributesList?.forEach((tempAttribute) => {
        if (tempAttribute.name === attribute.name) {
          tempAttribute.id = id
          tempAttribute.value = this.getValuesByType(values)
        }
      })
      this.setState({
        isAttributesLoading: false,
        attributesList: tempAttributesList,
      })
    } else {
      this.setState({
        isAttributesLoading: false,
        errorToaster: true,
        message: values.length && values[0]?.error,
      })
    }
  }

  checkAttribute = async ({ attribute, isChild, value, targetType }) => {
    if (!isChild && !attribute?.value?.length) {
      await this.setAttributeValues({ attribute })
    }
    await this.props.onCheck({
      attribute,
      value,
      isValue: isChild,
      targetType,
    })
    await this.props.fetchItems({ page: 0, targetType })
  }

  renderIcons = ({ selectedAttribute, attribute, isChild }) => {
    const { PIMConnector } = this.state
    return !isChild ? (
      selectedAttribute && selectedAttribute === attribute.name ? (
        <div
          className='toggle'
          onClick={() => this.setState({ selectedAttribute: null })}
        >
          <UpCapIcon fill='#0D62FF' />
        </div>
      ) : (
        <div
          className='toggle'
          onClick={async () => {
            if (!PIMConnector && AlgilaPerformanceImprovement) {
              this.setAttributeValues({ attribute })
            }
            this.setState({ selectedAttribute: attribute.name })
          }}
        >
          <DownCapIcon fill='#121213' />
        </div>
      )
    ) : null
  }

  renderCheckbox = ({
    attribute,
    isChild,
    value,
    targetType,
    isPartialForOtherType,
    isPartialChecked,
    fetchItems,
    isDisabled,
    isChecked,
  }) => {
    const { PIMConnector } = this.state
    return (
      <div className='row bottom_space'>
        <Checkbox
          className='check-box'
          onClick={async () => {
            if (!PIMConnector && AlgilaPerformanceImprovement) {
              this.checkAttribute({ attribute, isChild, value, targetType })
            } else {
              await this.props.onCheck({
                attribute,
                value,
                isValue: isChild,
                targetType,
              })
              fetchItems({ page: 0, targetType })
            }
          }}
          disabled={isDisabled}
          checked={isDisabled || isChecked}
          isPartial={
            isDisabled && !isChild && isPartialForOtherType
              ? true
              : isChecked && !isChild
              ? isPartialChecked
              : false
          }
          onKeyDown={async (e) => {
            e.keyCode === SPACEBAR_KEY &&
              (await this.props.onCheck({
                attribute,
                value,
                isValue: isChild,
                targetType,
              }))
            fetchItems({ page: 0, targetType })
          }}
          id={value}
          label={isChild ? value : attribute.name}
        />
      </div>
    )
  }

  renderRow = ({ attribute = {}, value = null, isChild = false }) => {
    const { fetchItems, allData } = this.props
    let types = []
    if (allData.selectedPromoTypeRadio === PromotionRadioTypeNames.BUY_GET) {
      types = types.concat(
        getBuyGetTargetTypes({
          buyGetType: BuyGetSpendNames.BUY,
          props: allData,
        })
      )
      types = types.concat(
        getBuyGetTargetTypes({
          buyGetType: BuyGetSpendNames.GET,
          props: allData,
        })
      )
      types = types.concat(
        getBuyGetTargetTypes({
          buyGetType: BuyGetSpendNames.SPEND,
          props: allData,
        })
      )
    }
    const { selectedAttribute, targetType } = this.state

    const isChecked = this.getIsChecked({
      attribute,
      value,
      isChild,
      targetType,
    })
    const isDisabled =
      targetType !== 'targetX'
        ? this.getIsChecked({
            attribute,
            value,
            isChild,
            targetType: targetType,
            checkIsDisable: true,
          })
        : false
    const isPartialForOtherType = isPartial({
      attribute,
      targetTypes: types,
      props: this.props,
    })
    const isPartialChecked = isPartial({
      attribute,
      targetTypes: [targetType],
      props: this.props,
    })

    return (
      <StyledAttribute isChild={isChild}>
        {this.renderIcons({
          selectedAttribute,
          attribute,
          isChild,
        })}
        {this.renderCheckbox({
          attribute,
          isChild,
          value,
          targetType,
          isPartialForOtherType,
          isPartialChecked,
          fetchItems,
          isDisabled,
          isChecked,
        })}
      </StyledAttribute>
    )
  }
}

export default connect(mapStateToPropsAttributes, (dispatch) =>
  bindActionCreators(
    {
      onCheck: ON_ATTRIBUTE_CHECK,
      setAttributeItems: SET_ATTRIBUTE_ITEM_DETAILS,
    },
    dispatch
  )
)(Attributes)
