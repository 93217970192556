import styled from 'styled-components'

export const StyledImport = styled.div`
  position: relative;
  cursor: pointer;
  .show {
    display: flex;
    flex-direction: column;
  }
`
export const StyledOffersFileTransfer = styled.div`
  display: flex;

  && > * {
    margin: 0 10px 0 0;
  }
`
