import React, { Component } from 'react'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { bindActionCreators } from 'redux'
import { Table } from '@teamfabric/copilot-ui'
import { HEADERS } from './headers'
import {
  StyledLoadMore,
  StyledEmptyTable,
  StyledAttributesTableWrapper,
} from './styles'
import {
  getSelectedAttributes,
  getAttributeItemsDetails,
  getIsFetchingItems,
} from 'modules/promo/selectors'
import { GET_ATTRIBUTE_ITEMS } from 'modules/promo/actions'
class AttributesItemTable extends Component {
  state = {
    selectedProduct: null,
    selectedProductVariants: [],
    isLoading: false,
    targetType: 'targetX',
    perPage: 24,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.targetType !== this.props.targetType) {
      this.setState({
        targetType: this.props.targetType,
      })
    }
  }
  render() {
    const { isLoading, targetType } = this.props
    const attributeItemDetails = this.props[`${targetType}AttributeItemDetails`]
    let { hasMore, currentPage, items } = attributeItemDetails || {}
    return (
      <StyledAttributesTableWrapper>
        <Table
          columns={HEADERS(this.props)}
          data={items || []}
          loading={isLoading}
          rowClassName='attribute-row'
          collapseOnRowClick={false}
          handlePagination={(page) => {
            this.props.fetchAttributeItems({
              page: page - 1,
              targetType,
            })
          }}
          render={({ data }) => {
            return isEmpty(data) ? (
              <tbody>
                <tr>
                  <td colSpan={HEADERS(this.props).length + 1} align='center'>
                    <StyledEmptyTable>
                      <p className='table_text'>
                        Select attributes to get SKUs
                      </p>
                    </StyledEmptyTable>
                  </td>
                </tr>
              </tbody>
            ) : null
          }}
        />
        {items && items.length !== 0 && hasMore && (
          <StyledLoadMore
            onClick={() =>
              this.props.fetchAttributeItems({
                page: currentPage + 1,
                targetType,
              })
            }
            data-testid='load_more'
          >
            Load More
          </StyledLoadMore>
        )}
      </StyledAttributesTableWrapper>
    )
  }
}

export const mapStateToPropsItems = (state) => {
  let typeState = {}
  const types = [
    'targetX',
    'targetY',
    'exclusion',
    state.promoReducer.targetType,
  ]
  types.forEach((type) => {
    typeState = {
      ...typeState,
      [`${type}SelectedAttributes`]: getSelectedAttributes(state, type),
      [`${type}AttributeItemDetails`]: getAttributeItemsDetails(state, type),
    }
  })
  return {
    isLoading: getIsFetchingItems(state),
    ...typeState,
  }
}

export default connect(mapStateToPropsItems, (dispatch) =>
  bindActionCreators(
    {
      fetchAttributeItems: GET_ATTRIBUTE_ITEMS,
    },
    dispatch
  )
)(AttributesItemTable)
