import styled from 'styled-components'

export const TileContainer = styled.div`
  width: 260px;
  height: 308px;

  position: relative;

  display: flex;
  flex-direction: column;

  background: #ffffff;
  border: 1px solid #e3e5e9;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
  }
`

export const Image = styled.img`
  width: 100%;
  height: 162px;

  object-fit: cover;
`

export const StatsRow = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Stat = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  margin: 15px 15px 0 15px;
`

export const TextCaption = styled.span`
  font-family: Gilroy-Regular;
  font-size: 12px;
  line-height: 14px;

  color: #737f8f;
`

export const LastUpdated = styled(TextCaption)`
  margin: 20px 15px 0 15px;
`

export const TextMain = styled.span`
  font-family: Gilroy-Medium;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: #121213;
`

export const Status = styled.div`
  width: 103px;
  height: 31px;

  position: absolute;
  top: 8px;
  left: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Gilroy-Regular;
  font-size: 12px;
  line-height: 14px;
  color: #121213;

  background: #ffffff;
  box-shadow: 0px 6px 16px rgba(115, 127, 143, 0.16);
  border-radius: 4px;
`

export const StatusIndicator = styled.div`
  content: '';
  width: 7px;
  height: 7px;
  margin-right: 5px;
  background: #0d62ff;
  border-radius: 4px;
`
