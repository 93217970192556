import React from 'react'
import { connect } from 'react-redux'
import {
  getSectionTitle,
  TYPE_CONSTANTS,
  PROMO_TYPES,
  PromotionRadioTypeNames,
  formattedValue,
} from '../helper'
import { getPromoTiers, getPromo, getAllState } from '../../selectors'
import { checkDataType } from 'lib/utils/helper'
import { StyledPromoTypes } from './styles'
import { StyledPromoTiers, StyledBuyGetInfo } from '../styles'
import BuyGet from './buy-get'
import Field from 'src/components/view/field'

const PromoType = (props) => {
  const {
    isCoupon,
    selectedPromoTypeRadio,
    promoTiers = [],
    fromAdvancedOptions = false,
  } = props
  const { couponApplied, promoApplied } = TYPE_CONSTANTS

  const getSelectedShipping = (shippingOptions) => {
    const selectedOptions = []
    shippingOptions.forEach((option) => {
      selectedOptions.push(option.label)
    })
    return selectedOptions
  }

  const ShippingFields = ({
    selectedShippingPromotionType,
    shippingPromotionTypeValue,
    isShipping,
    isFree,
  }) => {
    return (
      <>
        {isShipping ? (
          <div className='section_row'>
            <Field
              noMargin
              title={`Shipping ${isCoupon ? 'Coupon' : 'Promotion'} type`}
              value={selectedShippingPromotionType.name}
            />
            {!isFree && (
              <Field
                title='Value'
                value={formattedValue(
                  selectedShippingPromotionType.name,
                  shippingPromotionTypeValue
                )}
              />
            )}
          </div>
        ) : (
          ''
        )}
      </>
    )
  }

  const getPromoTypeStatus = ({
    selectedPromotionType,
    selectedShippingPromotionType,
    number,
  }) => {
    return {
      buyLabel:
        selectedPromoTypeRadio === 'Quantity'
          ? 'Minimum quantity of SKUs'
          : 'Minimum cart value',
      isSkus: selectedPromoTypeRadio === 'SKU(s)',
      isShipping: selectedPromotionType === 'Shipping off',
      isFree: selectedShippingPromotionType.name === 'Free',
      showSeperator: number > 0 ? true : false,
    }
  }

  const renderSkus = () => {
    const children = []
    promoTiers.forEach((tier, index) => {
      const { typeWithValues } = promoTiers[index]
      const {
        selectedPromotionType,
        promotionTypeValue,
        selectedShippingPromotionType,
        shippingPromotionTypeValue,
      } = typeWithValues
      const promoType = getPromoTypeStatus({
        selectedShippingPromotionType,
        selectedPromotionType,
        number: index,
      })
      const selectedShippingTypeOptions =
        checkDataType(promoTiers[0].typeWithValues.selectedShippingType) ===
        'Array'
          ? promoTiers[0].typeWithValues.selectedShippingType
          : []
      children.push(
        <StyledPromoTiers showSeperator={promoType.showSeperator}>
          <div className='tier_seperator' />
          <div className='content' data-testid='content-tiers'>
            {!promoType.isSkus && (
              <>
                <div className='section_row'>
                  <div className='tier_title'>
                    {isCoupon ? 'Coupon' : 'Promotion'} tier {index + 1}{' '}
                  </div>
                </div>
                <div className='get_section'>
                  <p className='section_title uppercase'>buy</p>
                  <Field
                    noMargin
                    title={promoType.buyLabel}
                    value={promoTiers[index].buyValue.toString()}
                  />
                </div>
                <p className='section_title uppercase'>get</p>
              </>
            )}
            <div className='get_section'>
              <div
                className={`type_and_value ${
                  fromAdvancedOptions || selectedPromoTypeRadio !== 'SKU(s)'
                    ? 'flex'
                    : ''
                }`}
              >
                <Field
                  noMargin
                  title={`${isCoupon ? 'Coupon' : 'Promotion'} type`}
                  value={selectedPromotionType}
                />
                {!promoType.isShipping && (
                  <Field
                    title={`Value`}
                    value={formattedValue(
                      selectedPromotionType,
                      promotionTypeValue
                    )}
                  />
                )}
              </div>
              {promoType.isShipping && (
                <div
                  className='section_col'
                  data-testid='shipping-multi-select'
                >
                  <Field
                    title={`Shipping type`}
                    value={getSelectedShipping(
                      selectedShippingTypeOptions
                    ).join(', ')}
                  />
                </div>
              )}
            </div>
            <ShippingFields
              selectedShippingPromotionType={selectedShippingPromotionType}
              shippingPromotionTypeValue={shippingPromotionTypeValue}
              isShipping={promoType.isShipping}
              isFree={promoType.isFree}
            />
          </div>
        </StyledPromoTiers>
      )
    })
    return children
  }

  return (
    <div className='content_wrapper_25' data-testid='promo-type'>
      <StyledPromoTypes>
        <div className='section_heading' data-testid='set'>
          {getSectionTitle('PROMO_TYPE_TITLE', isCoupon)}
        </div>
        <Field
          title={isCoupon ? couponApplied : promoApplied}
          value={PROMO_TYPES[selectedPromoTypeRadio]}
        />
        {/* promo tiers */}
        {selectedPromoTypeRadio === PromotionRadioTypeNames.BUY_GET ? (
          <StyledBuyGetInfo>
            <BuyGet {...props} />
          </StyledBuyGetInfo>
        ) : (
          renderSkus()
        )}
      </StyledPromoTypes>
    </div>
  )
}

export default connect((state) => ({
  promoTiers: getPromoTiers(state),
  promo: getPromo(state),
  ...getAllState(state),
}))(PromoType)
