"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isShippingDiscount = exports.discountOnTypeMapping = exports.emptyDiscount = exports.shippingDiscountTypeOptions = exports.skuDiscountTypeOptions = exports.cartDiscountTypeOptions = exports.discountTypeLabelMapping = exports.discountOnLabelMapping = exports.discountOnOptions = void 0;
var constants_1 = require("../../../constants");
var discountOnOptions = function (promoType) { return [
    { id: '0', name: 'SKU', label: 'SKU' },
    {
        id: '1',
        name: promoType === constants_1.PromotionRadioTypeNames.QUANTITY
            ? 'SERVICE_CART'
            : 'SERVICE',
        label: 'Shipping',
    },
    { id: '2', name: 'CART', label: 'Cart' },
]; };
exports.discountOnOptions = discountOnOptions;
exports.discountOnLabelMapping = {
    SKU: 'SKU',
    SERVICE: 'Shipping',
    CART: 'Cart',
    SERVICE_CART: 'Shipping',
};
exports.discountTypeLabelMapping = {
    '%OFF': 'Percentage off',
    AMOUNT_OFF: 'Amount off',
    FIXED: 'Fixed price',
    FREE: 'Free',
};
exports.cartDiscountTypeOptions = [
    {
        id: '0',
        label: 'Percentage off',
        units: '%',
        name: '%OFF',
    },
    {
        id: '1',
        label: 'Amount off',
        units: '$',
        name: 'AMOUNT_OFF',
    },
];
exports.skuDiscountTypeOptions = __spreadArray(__spreadArray([], __read(exports.cartDiscountTypeOptions), false), [
    {
        id: '2',
        label: 'Fixed price',
        units: '$',
        name: 'FIXED',
    },
], false);
exports.shippingDiscountTypeOptions = __spreadArray(__spreadArray([], __read(exports.cartDiscountTypeOptions), false), [
    {
        id: '2',
        label: 'Free',
        units: '%',
        name: 'FREE',
    },
], false);
exports.emptyDiscount = {
    discountType: '',
    discountOn: '',
    discountValue: '',
    discountValueIfDiscountExist: '',
    selectedShippingType: [],
    dynamicDiscount: false,
    shippingApplicableOn: '',
    selectedSortBy: constants_1.ALL,
};
exports.discountOnTypeMapping = {
    SKU: exports.skuDiscountTypeOptions,
    SERVICE: exports.shippingDiscountTypeOptions,
    SERVICE_CART: exports.shippingDiscountTypeOptions,
    CART: exports.cartDiscountTypeOptions,
};
var isShippingDiscount = function (discount) {
    return discount === 'SERVICE' || discount === 'SERVICE_CART';
};
exports.isShippingDiscount = isShippingDiscount;
