import styled from 'styled-components'

export const SkeltetonBox = styled.div`
  display: flex;
  width: 100%;
  .skt_left {
    width: 100%;
    .skt_buttonContainer {
      display: flex;
      align-items: center;
      .skt_btn2 {
        margin-left: 26px;
      }
    }
    .detail_header {
      padding-top: 25px;
      margin-top: 40px;
      background: #ffffff;
      .table_with_buttons {
        width: 836px;
        height: 948px;
        border: 1px solid rgba(115, 127, 143, 0.2);
        padding: 32px;
        border-radius: 4px;
        margin-top: 61px;
        margin-left: 33px;
        .add_price_button_loader {
          display: flex;
          .skt_table_container_detail {
            margin-left: -20px;
          }
          .skt_text {
            margin-left: 24px;
          }
        }
        .skt_text_add_button_2 {
          margin-left: 23px;
        }
      }
      .skt_search_details {
        margin-top: 41px;
        margin-left: 32px;
      }
      .skt_text_add_button {
        margin-top: 2px;
        margin-left: 33px;
      }
    }
    .skt_search {
      margin-top: 24px;
    }
    .skt_text {
      margin-top: 60px;
      margin-bottom: 24px;
    }
    .skt_table_container {
      width: 100%;
      .skt_table {
        width: 100%;
      }
    }
  }
  .skt_right {
    margin-left: 20px;
    width: 30%;
    background-color: #fff;
    padding: 16px;
    align-self: flex-start;
  }

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    .skt_left,
    .skt_right {
      width: 100%;
      margin: 0;
    }
    .skt_left {
      margin-top: 24px;
      .skt_table_container {
        overflow-x: auto;
        .skt_table {
          width: 880px;
        }
      }
    }
  }
`
