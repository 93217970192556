import React from 'react'
import { NoResultsContainer, TilesContainer } from './styles'
import ProductTile from 'components/product-tile'

const ProductTileView = ({ data, hasSearched, currency }) => {
  if (!data || data.length === 0)
    return (
      <NoResultsContainer>
        <span>
          {hasSearched
            ? 'No results found. Try changing your search criteria.'
            : 'No products were found!'}
        </span>
      </NoResultsContainer>
    )
  return (
    <TilesContainer data-testid='products'>
      {data.map(product => (
        <ProductTile
          key={product.sku}
          product={product}
          currency={currency}
          className={`product_tile`}
          itemId={product.itemId}
        />
      ))}
    </TilesContainer>
  )
}

export default ProductTileView
