import API, { API_URL } from 'src/api'

export const getAttributesFromPimConnector = ({ limit, offset }) =>
  API.offersConnector.post(API_URL.ATTRIBUTE_CONNECTOR, {
    limit: limit,
    offset: offset,
  })

export const searchedAttributesFromPimConnector = ({ body }) => {
  return API.offersConnector.post(API_URL.PRODUCT_SEARCH, body)
}

export const getGroupTree = (type) => {
  return API.offersConnector.get(API_URL.GROUP_TREE(type))
}

export const getItems = (data, type) => {
  return API.offersConnector.post(
    API_URL.ITEM_PRODUCT_GROUP({ request: data, type }),
    { group: data?.group }
  )
}

export const getItemIdsBySku = (sku) => {
  return API.offersConnector.post(API_URL.ITEM_IDS_BY_SKU(), sku)
}
