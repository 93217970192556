import { format, parseISO } from 'date-fns'
import { StyledPreview, StyledNoResult, StyledTime } from './styles'
import PlaceholderIcon from 'components/svg/product-placeholder'
import { ellipsis } from 'lib/utils/helper'
import { PROMO_COUPON_LISTING_DATE_FORMAT } from 'lib/constants/constants'
import getSymbolFromCurrency from 'currency-symbol-map'

export const STATUSES = {
  ACTIVE: 'ACTIVE',
  UPCOMING: 'UPCOMING',
  EXPIRED: 'EXPIRED',
}

const getVariantTitle = (variant) => {
    if (variant.variants.length > 0) {
      let title = []
      variant.variants &&
        variant.variants.length &&
        variant.variants.forEach((item) => title.push(item.value))
      return title.filter(Boolean).join('/')
    }
    return variant.title
  },
  renderStatus = (rowDetails) => {
    const { status } = rowDetails
    const isCurrent = status == STATUSES.ACTIVE
    return status ? (
      <div className='status'>
        <div className='status_label' data-testid={`status-${rowDetails._id}`}>
          {isCurrent ? 'Current' : 'Upcoming'}
        </div>
      </div>
    ) : (
      '-'
    )
  },
  renderImage = (content, id) => {
    return content.length > 0 ? (
      <StyledPreview
        url={content[0].source[0].url}
        data-testid={`image-${id}`}
        role='image'
      />
    ) : (
      <div data-testid={`image-${id}`} role='no-image'>
        {' '}
        <PlaceholderIcon />
      </div>
    )
  },
  checkNullValidation = (value) => {
    if (value !== null && value !== '' && value >= 0) {
      return true
    }
    return false
  },
  renderPriceRange = ({ min, max }, currency) => {
    const symbol = currency ? getSymbolFromCurrency(currency) : ''
    if (checkNullValidation(min) && checkNullValidation(max) && max > min)
      return `${symbol}${min} - ${symbol}${max}`
    if (checkNullValidation(min) && min === max) return `${symbol}${min}`
    if (!min && checkNullValidation(max)) return `${symbol}${max}`
    return `-`
  },
  getItemPrice = (rowDetails, type) => {
    if (rowDetails.price && rowDetails.price[type]) {
      return (
        <div>
          {getSymbolFromCurrency(rowDetails.price.currency)}
          {rowDetails.price[type]}
        </div>
      )
    } else {
      return <div>-</div>
    }
  },
  renderPrice = ({ rowDetails, isChild, type, currency = '' }) => {
    if (
      type !== 'sale' ||
      (rowDetails.hasOwnProperty('kind') &&
        rowDetails.kind.name === 'Clearance')
    ) {
      if (!isChild && rowDetails.items && rowDetails.items.length) {
        if (rowDetails[`${type}PriceRange`]) {
          return (
            <div>
              {rowDetails.price
                ? getSymbolFromCurrency(rowDetails.price.currency)
                : ''}
              {renderPriceRange(rowDetails[`${type}PriceRange`], currency)}
            </div>
          )
        } else {
          return <div>-</div>
        }
      } else {
        return getItemPrice(rowDetails, type)
      }
    } else {
      if (!isChild && rowDetails.items && rowDetails.items.length) {
        if (rowDetails[`${type}PriceRange`]) {
          return (
            <div>
              {renderPriceRange(rowDetails[`${type}PriceRange`], currency)}
            </div>
          )
        } else {
          return getItemPrice(rowDetails, type)
        }
      } else {
        return <div>-</div>
      }
    }
  },
  renderTitle = ({ rowDetails, isChild }) => {
    if (isChild) {
      return (
        <div
          data-title={getVariantTitle(rowDetails)}
          data-testid={`title-${rowDetails._id}`}
        >
          {ellipsis(getVariantTitle(rowDetails), 50)}
        </div>
      )
    }
    return (
      <div
        data-title={rowDetails.title}
        data-testid={`title-${rowDetails._id}`}
      >
        {ellipsis(rowDetails.title, 25)}
      </div>
    )
  },
  renderDate = (content) => {
    if (content) {
      const parsedDate = parseISO(content)
      return (
        <>
          <div data-testid={`date-${content}`}>
            {format(parsedDate, PROMO_COUPON_LISTING_DATE_FORMAT)}
          </div>
          <StyledTime className='time'>
            {format(parsedDate, 'hh:mm a')}
          </StyledTime>
        </>
      )
    } else {
      return <>-</>
    }
  }

const renderNoResultWithMessage = (headerLength) => {
  return (
    <tbody>
      <tr>
        <td colSpan={headerLength} data-testid='search-empty-list'>
          <StyledNoResult>
            {
              <div data-testid='empty-list-content'>
                No results found. Try changing your search criteria.
              </div>
            }
          </StyledNoResult>
        </td>
      </tr>
    </tbody>
  )
}
export {
  getVariantTitle,
  renderStatus,
  renderImage,
  renderPrice,
  renderTitle,
  renderDate,
  renderNoResultWithMessage,
  checkNullValidation,
  renderPriceRange,
}
