import React, { Component } from 'react'
import { Broadcast } from 'react-broadcast'
import { findDOMNode } from 'react-dom'
import {
  StyledInputLabel,
  StyledInputContainer,
  StyledInput,
  StyledError
} from './styles'
import PropTypes from 'prop-types'
import cx from 'classnames'

const isFunction = x => typeof x === 'function'
export const INPUT_CHANNEL = 'input'

export default class Input extends Component {
  static propTypes = {
    '*': PropTypes.any, // react attributes
    error: PropTypes.any,
    childrenBeforeInput: PropTypes.any, // react elements to put before the input`
    childrenAfterInput: PropTypes.any,
    inputProps: PropTypes.shape({
      '*': PropTypes.any, // react attributes
      defaultValue: PropTypes.string,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      onChange: PropTypes.func,
      type: PropTypes.string,
      value: PropTypes.string,
      inputLabel: PropTypes.string
    }),
    errorProps: PropTypes.object
  }

  static defaultProps = {
    inputProps: {
      type: 'text'
    }
  }

  constructor (props) {
    super(props)
    this.state = {
      value: '',
      isControlled: typeof this.props.inputProps.value === 'string',
      isFocused: false
    }
  }

  componentDidMount () {
    if (this.state.isControlled === true) {
      return this.setInputValue(this.props.inputProps.value)
    }

    this.setInputValue(this.props.inputProps.defaultValue)
  }

  /* eslint-disable react/no-deprecated */
  componentWillReceiveProps (nextProps) {
    if (nextProps.inputProps.value !== this.props.inputProps.value) {
      this.setInputValue(nextProps.inputProps.value)
    }
  }

  handleRef = instance => {
    if (!instance || this.baseElement) return
    this.baseElement = findDOMNode(instance)
  }

  handleInputRef = inputElement => {
    if (!inputElement) return
    this.inputElement = inputElement
  }

  setInputValue = (value = '') => this.setState({ value })

  handleBlur = event => {
    this.setState({
      isFocused: false
    })

    if (isFunction(this.props.inputProps.onBlur)) {
      this.props.inputProps.onBlur(event)
    }
  }

  handleFocus = event => {
    this.setState({
      isFocused: true
    })

    if (isFunction(this.props.inputProps.onFocus)) {
      this.props.inputProps.onFocus(event)
    }
  }

  handleChange = event => {
    // For "controlled" scenarios, updates to the cached input text should come
    // exclusively via props (cWRP) so it exactly mirrors the current application
    // state, otherwise a re-render will occur before the new text has completed its
    // feedback loop and the cursor position is lost
    if (this.state.isControlled === false) {
      this.setInputValue(event.target.value)
    }

    if (isFunction(this.props.inputProps.onChange)) {
      this.props.inputProps.onChange(event)
    }
  }

  // These are passed to children (icons/clear buttons/etc)
  // via react-broadcast so they can manipulate the input
  getStateAndHelpers () {
    return {
      handleChange: this.handleChange,
      baseElement: this.baseElement,
      inputElement: this.inputElement,
      ...this.state
    }
  }

  render () {
    const {
      inputProps,
      errorProps,
      className: containerClassName,
      childrenBeforeInput,
      childrenAfterInput,
      ...containerPropsRest
    } = this.props

    const {
      className: inputClassName,
      disabled,
      placeholder,
      inputLabel,
      type,
      integerOnly,
      integerWithDecimal,
      ...inputPropsRest
    } = inputProps || {}
    const { error, errorMessage } = errorProps || {}
    return (
      <div className={containerClassName}>
        {inputLabel && <StyledInputLabel> {inputLabel} </StyledInputLabel>}
        <StyledInputContainer
          className={cx({
            container_error: error,
            container_focus: this.state.isFocused,
            container_disabled: disabled
          })}
          {...containerPropsRest}
        >
          <Broadcast channel={INPUT_CHANNEL} value={this.getStateAndHelpers()}>
            <>
              {childrenBeforeInput}
              <label className='input_parent'>
                <StyledInput
                  placeholder={placeholder}
                  disabled={disabled}
                  ref={this.handleInputRef}
                  onBlur={this.handleBlur}
                  onFocus={this.handleFocus}
                  onChange={this.handleChange}
                  className={inputClassName}
                  value={this.state.value}
                  type={type}
                  step={integerWithDecimal ? '.01' : '1'}
                  min={(integerWithDecimal || integerOnly) && '0'}
                  {...inputPropsRest}
                />
              </label>
              {childrenAfterInput}
            </>
          </Broadcast>
        </StyledInputContainer>
        {error && errorMessage && (
          <StyledError {...errorProps}>{errorMessage}</StyledError>
        )}
      </div>
    )
  }
}
