import styled from 'styled-components'

export const StyledWrapper = styled.div`
  display: flex;
  .snack-bar {
    position: absolute;
    margin-left: 200px;
    margin-top: -35px;
  }
`
export const StyledContent = styled.div`
  width: 100%;
  .product_content {
    border: 1px solid rgb(115, 127, 143, 0.2);
    padding: 32px;
    border-radius: 4px;
    background: #ffffff;
    margin-top: 16px;
    .product-details-container {
      display: flex;
      padding-left: 24px;
      margin-bottom: 20px;
      .product-image {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 65px;
        width: 65px;
        margin-right: 24px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          object-fit: contain;
        }
      }

      .product-details {
        display: flex;
        flex-direction: column;
        height: 100%;
        .price-range {
          margin: 0;
          display: flex;
          align-items: center;
          color: ${(props) => props.theme.palette.success.main};
          .price-text {
            color: #737f8f;
            font-size: 13px;
            &:nth-of-type(2) {
              margin-left: 24px;
            }
            .price-value {
              margin-left: 8px;
              color: #121213;
              font-size: 16px;
            }
          }
        }
      }
      .date-range {
        margin-top: 8px;
        ${(props) => props.theme.typography.body.css};
        color: ${(props) => props.theme.palette.black.main};
      }

      .bottom-space {
        padding-bottom: 5px;
      }
    }
  }
  .sku {
    color: ${(props) => props.theme.palette.blue.electric};
    ${(props) => props.theme.typography.small.css};
    margin: 0;
  }
  .title {
    color: ${(props) => props.theme.palette.black.mainUpdated};
    // commenting font size from props because theme dont have font size as is figma design
    //${(props) => props.theme.typography.link.css};
    font-size: 21px;
    margin: 0px;
  }
  .create_price_title {
    margin-left: 24px;
    margin-top: 32px;
    ${(props) => props.theme.typography.link};
  }
  .no_padding_top {
    padding-top: 0px;
  }
`

export const StyledVariants = styled.div`
  border: 1px solid rgb(115, 127, 143, 0.2);
  border-radius: 4px;
  background: #ffffff;
  height: 432px;
  ${(props) => props.isProduct && `margin-top: 56px`};
  overflow: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    margin: 104px 0 44px 0;
    background: ${({ theme }) => theme.palette.grey.brand};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.text.charcoal};
  }

  .sub_title_wrapper {
    color: ${(props) => props.theme.palette.black.main};
    display: flex;
    justify-content: space-between;
    max-width: 90%;
    padding-left: 24px;
  }

  .title {
    padding-top: 10px;
    padding-left: 26px;
    ${(props) => props.theme.typography.h6.css};
    color: ${(props) => props.theme.palette.text.main};
  }

  .sub_title {
    ${(props) => props.theme.typography.body.css};
    color: ${(props) => props.theme.palette.blue.electric};
    font-weight: bold;
  }
  .heading {
    margin-top: 24px;
    height: 48px;
    background: ${(props) => props.theme.palette.text.container};
    color: ${(props) => props.theme.palette.blue.electric};
    display: grid;
    ${(props) => props.theme.typography.link.css};
    font-weight: 500;
    grid-template-columns: 32px 48px 172px 192px 136px 136px 136px 195px 195px 136px;
    .col_1 {
      min-width: 212px;
      text-align: start;
      padding-left: 16px;
    }
    .col_2 {
      min-width: 175px;
      text-align: start;
      padding-left: 16px;
    }
    .col_3 {
      min-width: 128px;
      text-align: start;
      padding-left: 16px;
    }
    .col_4 {
      min-width: 232px;
      text-align: start;
      padding-left: 16px;
    }
    .sku_col {
      width: 100%;
      min-width: 175px;
      text-align: start;
      padding-left: 32px;
    }
    .col_4 {
      min-width: 175px;
      text-align: start;
    }
  }
  .top-margin {
    margi-top: 20px;
  }
  .left-space {
    padding-left: 32px;
  }
  .no-price {
    padding-left: 7px;
    ${(props) => props.theme.typography.h4.css};
  }
  ul {
    cursor: pointer;
    list-style: none;
    padding: 0;
    max-height: 316px;
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 0px;
    color: ${(props) => props.theme.palette.text.main};
    li {
      height: 48px;
      ${(props) => props.theme.typography.body.css};
      background: ${(props) => props.theme.palette.common.white};
      cursor: pointer;
      display: grid;
      align-items: center;
      grid-template-columns: 32px 48px 172px 192px 136px 136px 136px 195px 195px 136px;

      :hover {
        background: ${(props) => props.theme.palette.text.container};
      }
      .heading {
        border: none;
        color: ${(props) => props.theme.palette.blue.electric};
      }
      .col_1 {
        padding-left: 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .col_2 {
        width: 100%;
        padding-left: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .sku_col {
        width: 100%;
        padding-left: 32px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .img {
        padding: 8px 8px;
        height: 36px;
        width: 24px;
      }
      .col_4 {
        min-width: 175px;
        text-align: start;
      }
    }
  }
  .toggle {
    padding: 12px 4px;
  }
  .active {
    width: 51px;
    height: 16px;
    border-radius: 2px;
    background-color: ${(props) => props.theme.palette.success.light};
    .active-label {
      padding-left: 10px;
      ${(props) => props.theme.typography.h6.css};
      color: ${(props) => props.theme.palette.success.main};
    }
  }
`

export const StyledCreatePrice = styled.div`
  margin-left: 24px;
  .row_wrapper {
    display: flex;
    min-height: 32px;
    margin-top: 10px;
    align-items: flex-start;
    .error_message {
      ${(props) => props.theme.typography.small};
      color: ${(props) => props.theme.palette.error.main};
      display: block;
    }
    .value_label {
      margin: 5px 0;
      ${(props) => props.theme.typography.body.css};
      color: ${(props) => props.theme.palette.text.main};
    }
    .price_value {
      margin-right: 24px;
      input {
        background-color: transparent !important;
      }
    }
    .date_input {
      margin-right: 8px;
      height: 30px;
      width: 232px;
      div:nth-child(1) {
        width: 190px;
      }
      div {
        input {
          ${(props) => props.theme.typography.body.css};
          color: ${(props) => props.theme.palette.common.black};
          height: 30px;
          border-radius: 5px;
          border: 1px solid ${(props) => props.theme.palette.text.outline};
          padding-left: 15px;
          width: 160px;
        }
      }
      .styled-calendar-date {
        ${(props) => props.theme.typography.body.css};
        font-family: Gilroy;
      }
      .styled-calendar-icon {
        margin-bottom: 8px;
        margin-top: 8px;
      }
    }

    .dummy_date_input {
      color: ${(props) => props.theme.palette.text.main};
      height: 25px;
      width: 212px;
      margin-right: 16px;
      padding-top: 5px;
    }
    .save_button {
      width: 130px;
      height: 32px;
      align-items: center;
      margin-top: 28px;
    }
  }
  .empty_status {
    width: 128px;
    div {
      width: 96px;
      height: 25px;
      color: ${(props) => props.theme.palette.text.main};
      padding-left: 8px;
      padding-top: 5px;
    }
  }
  .date-error {
    display: flex;
    justify-content: center;
    margin-left: 130px;
  }
  .padding_bottom_30 {
    padding-bottom: 30px;
  }
`
export const StyledPriceContainer = styled.div`
  ${(props) =>
    !props.isVariant &&
    `
    padding: 16px 24px 0px 24px;
  `}

  .footer_links {
    .add_more {
      ${(props) => props.theme.typography.link.css};
      margin-left: -20px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      width: 144px;
      justify-content: center;
      a {
        color: ${(props) => props.theme.palette.common.black};
      }
      svg {
        width: 15px;
        height: 15px;
        fill: #ff0055;
        margin-right: 10px;
      }
    }
    .link_buttons {
      margin: 20px 50px 0 0;
      border-top: 1px solid ${(props) => props.theme.palette.text.separation};
      padding-top: 15px;
      display: flex;
      justify-content: flex-end;
      button {
        border-radius: 5px;
      }
      .buttons {
        width: 100px;
        margin-right: 20px;
      }
    }
  }
`
export const StyledDate = styled.div`
  .date {
    font-size: 13px;
  }
  .time {
    font-size: 12px;
    color: #737f8f;
  }
`
export const StyledPreview = styled.div`
  height: 32px;
  width: 32px;
  background: ${(props) => props.theme.palette.text.container};
  ${(props) => props.url && `background-image: url(${props.url});`}
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`
export const StyledHistory = styled.div`
  .history {
    align-items: center;
    height: 48px;
    width: 100%;
    display: grid;
    border-bottom: solid 1px ${(props) => props.theme.palette.text.separation};
    .history_title {
      ${(props) => props.theme.typography.h5.css};
      color: ${(props) => props.theme.palette.blue.main};
    }
  }
`
export const VariantContainer = styled.div`
  .variants-container {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }
  .show {
    max-height: ${(props) =>
      props.childCount > 0 ? `${props.childCount * 300 + 100}px` : `0px`};
    overflow: hidden;
  }
  .hide {
    border-bottom: solid 1px ${(props) => props.theme.palette.text.separation};
  }
  .variant-separator {
    margin-bottom: 0px;
  }
`

export const StyledStatusLabel = styled.div`
  width: 72px;
  height: 16px;
  border-radius: 2px;
  .status {
    border-radius: 2px;
    background-color: ${(props) =>
      props.isActive
        ? props.theme.palette.success.light
        : props.isUpcoming
        ? props.theme.palette.info.lighter
        : props.theme.palette.text.container};
    .status-label {
      text-align: center;
      ${(props) => props.theme.typography.h6.css};
      color: ${(props) =>
        props.isActive
          ? props.theme.palette.success.main
          : props.isUpcoming
          ? props.theme.palette.info.main
          : props.theme.palette.text.main};
    }
  }
`

export const StyledProductHistory = styled.div`
  .no-price {
    .empty {
      height: 0px;
      background: #FFFFFF;
    }
  }
  .expansion-cell {
    cursor: pointer;
  }
  .sub_title_wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 90%;
    padding-left: 24px;
  }
  .title {
    ${(props) => props.theme.typography.h6.css};
    color: ${(props) => props.theme.palette.text.main};
    padding-top: 16px;
    padding-left: 26px;
  }
  .sub_title {
    ${(props) => props.theme.typography.body.css};
    color: ${(props) => props.theme.palette.text.main};
    font-weight: bold;
  }
  .heading {
    margin-top: 16px;
    height: 48px;
    background: ${(props) => props.theme.palette.text.container};
    color: ${(props) => props.theme.palette.blue.electric};
    display: grid;
    ${(props) => props.theme.typography.link.css};
    font-weight: 500;
    grid-template-columns: 212px 212px 212px 232px 232px 240px 128px;
  }
  .top-margin {
    margin-top: 56px;
  }
  .variant-column {
    width: 220px;
  }
  .variant-price {
    width: 80px;
  }
  .col_1 {
    width: 212px;
    text-align: start;
  }
  .col_2 {
    width: 128px;
    text-align: start;
    padding-left: 16px;
  }
  .col_3 {
    width: 232px;
    text-align: start;
    padding-left: 16px;
  }
  .sku_col {
    width: 100%;
    width: 228px;
    margin-left: 24px;
  }
  .col_4 {
    min-width: 175px;
    text-align: start;
    margin-left: 32px;
  }
  .separator {
    margin-top: 8px;
    border: solid 1px ${(props) => props.theme.palette.text.separation};
  }

  .create-price {
    padding-left: 8px;
    .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
      text-align: left;
    }
  }
  ul {
    list-style: none;
    padding-left: 0;
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 0px;
    color: ${(props) => props.theme.palette.text.main};
    li {
      height: 48px;
      ${(props) => props.theme.typography.body.css};
      background: ${(props) => props.theme.palette.common.white};
      cursor: pointer;
      display: grid;
      align-items: center;
      grid-template-columns: 212px 212px 212px 232px 232px 240px 128px;
      :hover {
        background : ${(props) => props.theme.palette.text.container};
      }
    .heading {
      border: none;
      color: ${(props) => props.theme.palette.blue.electric};
    }
    .col_1 {
      ${(props) => props.theme.typography.body.css};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .col_2 {
      ${(props) => props.theme.typography.body.css};
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .col_3 {
      ${(props) => props.theme.typography.body.css};
      width: 228px;
    }
    .product {
      padding: 14px 16px;
    }
    .img {
      justify-self: center;
      padding: 8px 16px;
    }
    .col_4 {
      ${(props) => props.theme.typography.body.css};
      min-width: 175px;
      text-align: start;
      margin-left: 32px;

      .active {
        margin-top: 8px;
        width: 51px;
        height: 16px;
        border-radius: 2px;
        background-color:${(props) => props.theme.palette.success.light};

        .active-label {
          padding-left: 10px;
          ${(props) => props.theme.typography.h6.css};
          color:${(props) => props.theme.palette.success.main};
        }
      }
    }
  }
`

export const StyledModal = styled.div`
  .header {
    ${(props) => props.theme.typography.h4.css};
    margin-bottom: 10px;
  }
  .info {
    ${(props) => props.theme.typography.body};
    margin-bottom: 25px;
  }
  .button_actions {
    display: flex;
    justify-content: flex-end;
    button {
      margin-right: 25px;
    }
    button:last-child {
      margin-right: 0;
    }
  }
`
