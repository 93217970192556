import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { CSVLink } from 'react-csv'
import axios from 'axios'
import { itemsUpload } from 'src/api/product'
import { Button, ButtonWithIcon, ButtonLoader } from '@teamfabric/copilot-ui'
import { StyledUploadWrapper } from './styles'
import { getBulkUploadCSVHeaders } from 'src/lib/utils/helper'
import RenderError from 'components/error'

export default class UploadFile extends Component {
  state = {
    headersData: [getBulkUploadCSVHeaders()],
    files: [],
    error: false,
    errorMessage: '',
    isLoading: false,
  }
  onDrop = ({ files }) => {
    var file = files[0]
    const reader = new FileReader()
    reader.onload = async (e) => {
      try {
        this.setState({ isLoading: true })
        const reqData = {
          fileName: file.name,
          type: 'price',
        }
        const { data } = await itemsUpload(reqData)
        if (data.errorType === 'Error') {
          this.setState({
            error: true,
            errorMessage: data.errorMessage,
            isLoading: false,
          })
        }
        const url = data && data.url
        if (url) {
          this.setState({ isLoading: true })
          const uploadResponse = await axios.put(url, e.target.result)
          if (uploadResponse.status === 200) {
            this.setState({ isLoading: false })
            this.props.onClose()
          }
        }
      } catch (error) {
        console.log(error)
        this.setState({ isLoading: false })
        if (error && error.response && error.response.data) {
          this.setState({
            error: true,
            errorMessage: error.response.data.message,
          })
        }
      }
    }
    reader.readAsArrayBuffer(file)
  }

  render() {
    const { isLoading } = this.state
    return (
      <StyledUploadWrapper>
        <div className='header' data-testid='modal-header'>
          {/* <UploadIcon /> */}
          {isLoading ? (
            <ButtonLoader
              className='loading'
              theme='dark'
              height='26px'
              width='156px'
            />
          ) : (
            'Import CSV file'
          )}
        </div>
        <div className='dropzone'>
          <Dropzone
            onDrop={(files) => this.setState({ files: files, error: false })}
            maxSize={2000000000}
            accept='text/csv, application/vnd.ms-excel'
          >
            {({ getRootProps, getInputProps, rejectedFiles }) => {
              return (
                <section>
                  <div className='button_wrapper' data-testid='drop-zone'>
                    <input {...getInputProps()} data-testid='file-drop' />
                    {isLoading ? (
                      <ButtonLoader
                        className='loading'
                        theme='dark'
                        height='40px'
                        width='100px'
                      />
                    ) : (
                      <Button
                        {...getRootProps()}
                        color='secondary'
                        size='medium'
                        isPrimary={false}
                        text='Choose file'
                      />
                    )}
                    {rejectedFiles.length > 0 ? (
                      <span className='file_error'>
                        {' '}
                        File choosen is greater than 2MB{' '}
                      </span>
                    ) : this.state.files.length > 0 ? (
                      <span className='file_name'>
                        {' '}
                        {!isLoading && this.state.files[0].name}{' '}
                      </span>
                    ) : (
                      <span>
                        {' '}
                        {isLoading ? (
                          <ButtonLoader
                            className='loading'
                            theme='dark'
                            height='26px'
                            width='156px'
                          />
                        ) : (
                          'No file choosen'
                        )}
                      </span>
                    )}
                  </div>
                </section>
              )
            }}
          </Dropzone>
          {isLoading ? (
            <ButtonLoader
              className='loading'
              theme='dark'
              height='26px'
              width='375px'
            />
          ) : (
            <p className='template-guid'>
              Download a
              <span className='template_here'>
                <CSVLink
                  data={this.state.headersData}
                  filename='price_template'
                >
                  {' '}
                  template here{' '}
                </CSVLink>
              </span>
              to view an example of a CSV file.
            </p>
          )}
          {this.state.error && (
            <RenderError id='error-message' message={this.state.errorMessage} />
          )}
        </div>
        <div className='button-container'>
          {isLoading ? (
            <ButtonLoader
              className='loading'
              theme='dark'
              height='40px'
              width='100px'
            />
          ) : (
            <ButtonWithIcon
              emphasis='high'
              iconPosition='left'
              isPrimary={false}
              onClick={this.props.onClose}
              text='Cancel'
              theme='light'
              data-testid='cancel-button'
            />
          )}
          {isLoading ? (
            <ButtonLoader
              className='loading'
              theme='dark'
              height='40px'
              width='120px'
            />
          ) : (
            <Button
              data-testid='upload-file'
              size='small'
              disabled={!this.state.files.length || this.state.error}
              pending={this.state.files.length > 0 && this.state.isLoading}
              onClick={() => this.onDrop({ files: this.state.files })}
              text='Upload file'
            />
          )}
        </div>
      </StyledUploadWrapper>
    )
  }
}
