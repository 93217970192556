import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import promoReducer from '../modules/promo/reducer'
import exclusionReducer from '../modules/global-exclusions/reducer'
const reducer = combineReducers({
  promoReducer,
  exclusionReducer
})

const store = createStore(
  reducer,
  compose(
    applyMiddleware(thunk),
    process.browser && window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : x => x
  )
)
export default store
