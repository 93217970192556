import get from 'lodash/get'
import set from 'lodash/set'
import cloneDeep from 'lodash/cloneDeep'

const getSelectedCategories = ({ categories }) => {
  if (!categories || (categories && Object.keys(categories).length === 0)) {
    return []
  }
  return getOptedCategories(categories, [])
}

const getOptedCategories = (category, selectedCats) => {
  category.constructor === Object &&
    Object.keys(category).forEach((key) => {
      if (key === 'isChecked' && category[key] && category['isChild']) {
        selectedCats.push({
          id: category._id,
          title: category.path,
        })
      }
      if (category[key] && category[key].constructor === Object) {
        getOptedCategories(category[key], selectedCats)
      }
    })
  return selectedCats
}

const onCheckCategory = ({ id, categoryState, categoriesPath }) => {
  let category = get(categoryState, categoriesPath[id])
  let updatedObj = { ...categoryState }
  let isChecked = category && !category.isChecked && !category.isPartialChecked
  // parent cat
  if (category && category.parentId.length === 0) {
    bottomTraversal(category, updatedObj, isChecked, categoriesPath)
  }

  // middle category
  if (!category?.isChild && category?.parentId?.length > 0) {
    upperTraversal(id, category, updatedObj, isChecked, categoriesPath)
    bottomTraversal(category, updatedObj, isChecked, categoriesPath)
  }

  // child category
  if (category?.isChild) {
    upperTraversal(id, category, updatedObj, isChecked, categoriesPath)
  }
  return updatedObj
}

const constuctPath = (parentHierarchy = [], categoryMap = {}, parent = '') => {
  let catgeoryPath = ''
  for (let i = 0; i <= parentHierarchy.length; i++) {
    if (parentHierarchy[i] === parent) {
      catgeoryPath += `${categoryMap[parentHierarchy[i].trim()] + '.'}`
      break
    } else {
      catgeoryPath += `${categoryMap[parentHierarchy[i].trim()] + '.'}`
    }
  }
  return catgeoryPath.substring(0, catgeoryPath.length - 1)
}

export const createCategoryPath = (categories = [], isCollection) => {
  let path = {}
  const cloneCategories = cloneDeep(categories)
  cloneCategories.forEach((category) => {
    const { title } = category
    const categoryMap = isCollection
      ? category?.collectionMap
      : category?.categoryMap
    category.title.split('/').forEach((parent) => {
      path = {
        ...path,
        [categoryMap[parent]]: constuctPath(
          title.split('/'),
          categoryMap,
          parent
        ),
      }
    })
  })
  return path
}

const onCheckCollection = ({ id, collectionState, collectionsPath }) => {
  let collection = get(collectionState, collectionsPath[id])
  let updatedObj = { ...collectionState }
  let isChecked =
    collection && !collection.isChecked && !collection.isPartialChecked

  // parent cat
  if (collection && collection.parentId.length === 0) {
    bottomTraversal(collection, updatedObj, isChecked, collectionsPath)
  }

  // middle category
  if (!collection?.isChild && collection?.parentId?.length > 0) {
    upperTraversal(id, collection, updatedObj, isChecked, collectionsPath)
    bottomTraversal(collection, updatedObj, isChecked, collectionsPath)
  }

  // child category
  if (collection?.isChild) {
    upperTraversal(id, collection, updatedObj, isChecked, collectionsPath)
  }
  return updatedObj
}

const updateAccessorParent = (accessorParent, obj) => {
  const totalCounts = accessorParent.totalCount
  let countOfSelectedChildren = 0,
    countOfPartiallySelectedChildren = 0
  for (let key in accessorParent) {
    if (
      typeof accessorParent[key] === 'object' &&
      Object.keys(accessorParent[key]).length
    ) {
      if (accessorParent[key]['isChecked'] === true) {
        countOfSelectedChildren++
      }
      if (accessorParent[key]['isPartialChecked'] === true) {
        countOfPartiallySelectedChildren++
      }
    }
  }
  accessorParent['count'] = countOfSelectedChildren
  accessorParent['isChecked'] = countOfSelectedChildren === totalCounts
  accessorParent['isPartialChecked'] = accessorParent['isChecked']
    ? false
    : (countOfSelectedChildren > 0 &&
        countOfSelectedChildren !== totalCounts) ||
      countOfPartiallySelectedChildren > 0
  let path =
    accessorParent['parentId'].length > 0
      ? accessorParent['parentId'] + `.${accessorParent._id}`
      : accessorParent._id
  set(obj, path, accessorParent)
}

/*
      UPPER TRAVERSAL (id, category, obj, ischecked)
        => check the CATEGORY 
          If category id === id 
              update the category with ischecked     

          if accessorParent count === 0 
              update by 1
              REPEAT SAME UPPER TRAVERSAL WITH ACCESSOR PARENT
          else
              update accessor count by 1   
  */

const upperTraversal = (id, category, obj, isChecked, categoriesPath) => {
  if (!category) {
    return
  }
  if (category._id === id) {
    let updatedCat = get(obj, categoriesPath[id])
    updatedCat['isChecked'] = isChecked
    set(obj, categoriesPath[id], updatedCat)
  }
  let parentId = categoriesPath[category.firstParent]
  let accessorParent = get(obj, parentId)
  if (accessorParent) {
    updateAccessorParent(accessorParent, obj)
    upperTraversal(id, accessorParent, obj, category.isChecked, categoriesPath)
  }
}

/*
      BOTTOM  TRAVERSAL (CLICKED CATEGORY)
      if checked 
        => LOOP over the category 
                   bottomTraversal(cat)
                   childCount = 0
                   if node has children
                        childCount +=1
                   node.count = childCount
                   node.isChecked = true
      else
        => LOOP over the category 
                   bottomTraversal(cat)
                   node.count = 0
                   node.isChecked = false
  */

const bottomTraversal = (category, obj, isChecked, categoriesPath) => {
  if (isChecked) {
    let childCount = 0
    category.constructor === Object &&
      Object.keys(category).forEach((key) => {
        if (category[key] && category[key].constructor === Object) {
          childCount += 1
          bottomTraversal(category[key], obj, isChecked, categoriesPath)
        }
      })
    let updatedCat = get(obj, categoriesPath[category._id])
    if (updatedCat) {
      updatedCat['count'] = childCount
      updatedCat['isChecked'] = category.isPartialChecked ? false : true
      updatedCat['isPartialChecked'] = false
    }
    set(obj, categoriesPath[category._id], updatedCat)
    if (childCount === 0) {
      return
    }
  } else {
    getNotChecked({ category, obj, isChecked, categoriesPath })
  }
}

const getNotChecked = ({ category, obj, isChecked, categoriesPath }) => {
  return (
    category.constructor === Object &&
    Object.keys(category).forEach((key) => {
      if (
        (category[key] && category[key].constructor === Object) ||
        category[key].isChild
      ) {
        category['count'] = 0
        category['isChecked'] = false
        category['isPartialChecked'] = false
        category[key]['count'] = 0
        category[key]['isChecked'] = false
        category[key]['isPartialChecked'] = false
        set(obj, category.parentId + `.${category._id}`, category)
        bottomTraversal(category[key], obj, isChecked, categoriesPath)
      }
    })
  )
}

const isObject = (item) => {
  return item && typeof item === 'object' && !Array.isArray(item)
}

const mergeDeep = (target, ...sources) => {
  if (!sources.length) return target
  const source = sources.shift()
  if (isObject(target) && isObject(source)) {
    Object.getOwnPropertyNames(source).forEach((key) => {
      if (isObject(source[key]) && target[key]) {
        mergeDeep(target[key], source[key])
      } else {
        target[key] = source[key]
      }
    })
  }
  return mergeDeep(target, ...sources)
}

export {
  onCheckCategory,
  onCheckCollection,
  getSelectedCategories,
  mergeDeep,
  updateAccessorParent,
  getNotChecked,
}
