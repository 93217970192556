import styled from 'styled-components'

export const StyledInputContainer = styled.div`
  ${props => props.theme.typography.body};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  border-radius: 2px;
  border: solid 1px ${props => props.theme.palette.text.outline};
  background-color: ${props =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.palette.common.white};
  width: 100%;
  &.container_disabled {
    background: ${props => props.theme.palette.text.container};
  }
  * {
    box-sizing: border-box;
  }

  &.container_error {
    border-color: rgba(255, 0, 0, 0.25);
  }
  &.container_error.container_focus {
    border-color: ${props => props.theme.palette.error.main};
  }
  &.container_focus {
    border-color: ${props => props.theme.palette.text.enable};
  }

  > label.input_parent {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    flex: 0 1 auto;
    padding: 0 16px;
  }
`

export const StyledInput = styled.input`
  border: none;
  outline: none;
  height: 100%;
  flex: 0 1 auto;
  padding-left: 0;
  padding-top: 0;
  width: 100%;
  ${props => props.theme.typography.body};
  background-color: ${props => props.theme.palette.common.white};
  color: ${props =>
    props.value
      ? props.theme.palette.text.charcoal
      : props.theme.palette.text.enable};
  :focus {
    color: ${props => props.theme.palette.text.main};
  }
  :disabled {
    cursor: not-allowed;
    background: ${props => props.theme.palette.text.container};
  }
`

export const StyledError = styled.div`
  ${props => props.theme.typography.h5};
  color: ${props => props.theme.palette.error.main};
  margin-left: 1px;
  position: relative;
`
export const StyledInputLabel = styled.p`
  ${props => props.theme.typography.label.css};
  color: ${props => props.theme.palette.text.label};
  margin: 6px 0;
`
