import styled from 'styled-components'

export const StyledWrapper = styled.div`
  display: flex;
  .snack-bar {
    position: absolute;
    margin-left: 200px;
    margin-top: -35px;
  }
`
export const StyledContent = styled.div`
  width: 100%;
  .separator {
    margin-top: 8px;
    border: solid 1px ${(props) => props.theme.palette.text.separation};
  }
  .title {
    color: ${(props) => props.theme.palette.black.main};
    // commenting font size from props because theme dont have font size as is figma design
    //${(props) => props.theme.typography.link.css};
    font-size: 21px;
    margin: 0px;
  }
  .product_content {
    border: 1px solid rgb(115, 127, 143, 0.2);
    padding: 32px;
    border-radius: 4px;
    background: #ffffff;
    .product-info {
      display: flex;
      .price-range {
        margin: 0;
        display: flex;
        align-items: center;
        color: ${(props) => props.theme.palette.success.main};
        .price-text {
          color: #737f8f;
          font-size: 13px;
          &:nth-of-type(2) {
            margin-left: 24px;
          }
          .price-value {
            margin-left: 8px;
            color: #121213;
            font-size: 16px;
          }
        }
      }
    }
    .sku {
      color: ${(props) => props.theme.palette.text.main};
      ${(props) => props.theme.typography.small}
      padding-left: 24px;
    }
    .title {
      color: ${(props) => props.theme.palette.text.main};
      ${(props) => props.theme.typography.h4.css};
      padding-left: 24px;
      margin: 0;
    }
    .variant_title {
      color: ${(props) => props.theme.palette.blue.electric};
      ${(props) => props.theme.typography.h6.css};
      padding-left: 24px;
      margin-top: 10px;
    }
    .price-range {
      margin-top: 10px;
      ${(props) => props.theme.typography.link.css};
      color: ${(props) => props.theme.palette.success.main};
      margin: 0;
      padding-left: 24px;
    }
    .create-price {
      .create_price_title {
        margin-left: 24px;
        margin-top: 32px;
        font-size: 18px;
        font-weight: bold;
      }
      padding-left: 8px;
      margin-bottom: 50px;
      ${(props) => props.isError && `height: 80px`};
      .react-datepicker__time-container
        .react-datepicker__time
        .react-datepicker__time-box {
        text-align: left;
      }
    }
  }
`
export const StyledDate = styled.div`
  .date {
    font-size: 13px;
  }
  .time {
    font-size: 12px;
    color: #737f8f;
  }
`
export const StyledVariants = styled.div`
  border: 1px solid rgb(115,127,143,0.2);
  padding: 32px;
  border-radius: 4px;
  background: #FFFFFF;
  margin-top: 48px;
  .sub_title_wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 90%;
    padding-left: 24px;
    button {
      height: 40px;
      padding: 10px;
      border-radius: 5px;
    }
  }
  .title {
    ${(props) => props.theme.typography.h6.css};
    color: ${(props) => props.theme.palette.text.main};
    padding-top: 10px;
    padding-left: 24px;
  }
  .sub_title {
    ${(props) => props.theme.typography.h6.css};
    color: ${(props) => props.theme.palette.text.main};
  }
  .heading {
    margin-top: 16px;
    height: 48px;
    background: ${(props) => props.theme.palette.text.container};
    color: ${(props) => props.theme.palette.blue.electric};
    display: grid;
    grid-template-columns: 212px 212px 212px 232px 232px 240px 128px;
    ${(props) => props.theme.typography.link.css};
    font-weight: 500;
  }
  .top-margin {
    margin-top: 20px;
  }
  .col_1 {
    text-align: start;
    margin-left: 16px;
    font-weight: 500;
  }
  .col_2 {
    width: 100%;
    text-align: start;
    margin-left: 16px;
    
  }
  .col_3 {
    text-align: start;
    margin-left: 16px;
  }
  .col_4 {
    ${(props) => props.theme.typography.link.css};
    text-align: start;
    margin-left: 16px;
  }
  .no-price {
    padding-top: 8px;
    // padding-left: 200px;
    ${(props) => props.theme.typography.h4.css};
    .empty {
      background: #FFFFFF;
      height: 180px;
    }
    background: #FFFFFF;
  }
  ul {
    list-style: none;
    padding-left: 0;
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 0px;
    li {
      height: 48px;
      ${(props) => props.theme.typography.body.css};
      background: ${(props) => props.theme.palette.common.white};
      cursor: pointer;
      display: grid;
      align-items: center;
      grid-template-columns: 212px 212px 212px 232px 232px 240px 128px;
      :hover {
        background : ${(props) => props.theme.palette.text.container};
      }
    .heading {
      border: none;
      color: ${(props) => props.theme.palette.text.label};
    }
    .col_1 {
      ${(props) => props.theme.typography.body.css};
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-left: 16px;
    }
    .col_2 {
      ${(props) => props.theme.typography.body.css};
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .col_3 {
      ${(props) => props.theme.typography.body.css};
      width: 228px;
      margin-left: 24px;
    }
    .product {
      padding: 14px 16px;
    }
    .img {
      justify-self: center;
      padding: 8px 16px;
    }
    .col_4 {
      ${(props) => props.theme.typography.body.css};
      text-align: start;
      margin-left: 16px;

      .active {
        margin-top: 8px;
        width: 51px;
        height: 16px;
        border-radius: 2px;
        background-color:${(props) => props.theme.palette.success.light};

        .active-label {
          padding-left: 10px;
          ${(props) => props.theme.typography.h6};
          color:${(props) => props.theme.palette.success.main};
        }
      }
    }
  }
`
export const StyledImages = styled.div`
  display: flex;
  padding-left: 24px;
  width: 56px;
  margin: 0 25px 0 0;
  img {
    width: auto;
    height: 56px;
  }
`
export const StyledStatusLabel = styled.div`
  width: 72px;
  height: 16px;
  border-radius: 2px;
  .status {
    border-radius: 2px;
    background-color: ${(props) =>
      props.isActive
        ? props.theme.palette.success.light
        : props.isUpcoming
        ? props.theme.palette.info.lighter
        : props.theme.palette.text.container};
    .status-label {
      text-align: center;
      ${(props) => props.theme.typography.h6};
      color: ${(props) =>
        props.isActive
          ? props.theme.palette.success.main
          : props.isUpcoming
          ? props.theme.palette.info.main
          : props.theme.palette.text.main};
    }
  }
`
