import { StyledDate } from './styles'
import { format, parseISO } from 'date-fns'
import {
  renderProductAndVariantData,
  calculateStatus,
} from '../product/headers'
const renderStatus = (rowDetails) => {
    if (rowDetails) {
      return (
        <div className='status'>
          <div
            className='status-label'
            data-testid={`price-status-${rowDetails._id}`}
          >
            {calculateStatus(rowDetails)}
          </div>
        </div>
      )
    } else {
      return <div> -</div>
    }
  },
  renderPrice = ({ rowDetails, isChild, type }) => {
    return renderProductAndVariantData({
      rowDetails,
      isChild,
      type,
      render: true,
    })
  },
  renderDate = (content) => {
    if (content) {
      const parsedDate = parseISO(content)
      return (
        <StyledDate>
          <div>{format(parsedDate, 'MMM d Y')}</div>
          <div className='time'>{format(parsedDate, 'hh:mm a')}</div>
        </StyledDate>
      )
    }
  }

const HEADERS = [
  {
    title: 'Cost',
    isSortable: false,
    accessor: '',
    render: (details) => {
      return renderPrice({ rowDetails: { ...details }, type: 'cost' })
    },
  },
  {
    title: 'Base Price',
    isSortable: false,
    accessor: ' ',
    render: (details) => {
      return renderPrice({ rowDetails: { ...details }, type: 'base' })
    },
  },
  {
    title: 'Clearance price',
    isSortable: false,
    accessor: '',
    render: (details) => {
      return renderPrice({ rowDetails: { ...details }, type: 'sale' })
    },
  },
  {
    title: 'Start date & Time',
    isSortable: false,
    accessor: 'startDate',
    render: (details) => {
      return renderDate(details.startDate)
    },
  },
  {
    title: 'End date & Time',
    isSortable: false,
    accessor: 'endDate',
    render: (details) => {
      return renderDate(details.endDate)
    },
  },
  {
    title: 'Status',
    isSortable: false,
    accessor: '',
    render: (details) => {
      return renderStatus({ ...details })
    },
  },
]

export { HEADERS }
