const GLOBAL_HEADER_LINKS = [
  {
    href: '/overview',
    icon: 'overview',
    text: 'Overview',
  },
  {
    href: '/pim',
    icon: 'pim',
    text: 'PIM',
  },
  {
    href: '/xpm',
    icon: 'xpm',
    text: 'XPM',
  },
  {
    active: true,
    href: '/offers',
    icon: 'offers',
    text: 'OFFERS',
  },
  {
    href: '/oms',
    icon: 'oms',
    text: 'OMS',
  },
]

const PAGELINKS = [
  {
    id: 1,
    label: 'Pricing',
    url: '/offers/price/product-list',
    active: true,
  },
  {
    id: 2,
    label: 'Promotions',
    url: '/offers/promo-list',
    active: false,
  },
  {
    id: 3,
    label: 'Coupons',
    url: '',
    active: false,
    children: [
      {
        active: false,
        id: 5,
        label: 'All Coupons',
        url: '/offers/coupon-list',
      },
      {
        id: 6,
        label: 'Redemption',
        url: '/offers/coupon/redemption',
        active: false,
      },
    ],
  },
  {
    id: 4,
    label: 'Settings',
    url: '/offers/settings/price-list',
    active: false,
  },
]
export { GLOBAL_HEADER_LINKS, PAGELINKS }

export const featureFlagDomainMapping = {
  dev02: 'dev',
  stg02: 'stage',
  sandbox: 'sandbox',
  prod01: 'prod',
}

export const featureFlagEnvMapping = {
  dev02: 'development',
  stg02: 'development',
  sandbox: 'production',
  prod01: 'production',
}

export const allDetailedFeatures = {
  pim_connector: true,
  customer_segments: false,
  global_exclusions: true,
  'buy-get': true,
  'spend-get': true,
  promotion_limit: true,
  algolia_performance_improvement: true,
  'buy-get-shipping-cart-value': true,
  enhancement_in_bulk_price_import: false,
  pagination_on_product_price: true,
  price_list_pagination: true,
  disable_cart_shipping: false,
  rbac_v2: true,
  elimination_of_extra_field: true,
  cheapest_expensive_item: true,
  coupon_bulk_import: true,
  alternate_hierarchy: true,
  offers_v3_api_migration: false,
}

export const featuresWithNewProductSelection = {
  ...allDetailedFeatures,
  new_product_selection: true,
}
