import { createActionPrefix } from 'redux-nano'

const Action = createActionPrefix('PROMO')

export const RESET_PROMO_USAGE = Action('RESET_PROMO_USAGE')
export const SET_PROMOTION = Action('SET_PROMOTION')
export const RESET_EXCLUSION = Action('RESET_EXCLUSION')
export const SET_EXCLUSION_FORM_VALUES = Action('SET_EXCLUSION_FORM_VALUES')
export const SET_EXCLUSION_ALL_FORM_VALUES = Action(
  'SET_EXCLUSION_ALL_FORM_VALUES'
)
